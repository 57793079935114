import React from 'react'
// import ReactDOM from 'react-dom';
import { listDataVersions } from '../graphql/queries.js'
import { updateDataVersion } from '../graphql/mutations.js'
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'

import { Button } from "reactstrap";


  



class ActivateNewDataVersionBtn extends React.Component{
    onButtonClick = () => {
        // this.createNewDataVersion()
        // this.updateDataVersion()
        // this.updateQueryState()
        this.getDataVersion()
    }

    // this works perfectly do not replace
    // createNewDataVersion = async(newVersion) => {
    //     const values = {
    //         version: newVersion,
    //         status: 'New',
    //     }
        
    //     console.log('Submitted Values', values)
    //     const newDVResponse = await API.graphql(graphqlOperation(createDataVersion, {input: values}))
    //     console.log(newDVResponse)
    // }
    deactivateDataVersion = async(currentDataVersion) => {
        const values = {
            version: currentDataVersion,
            status: 'Archived'
        }
        await API.graphql({ query: updateDataVersion, variables: {input: values}});
    }

    activateDataVersion = async(NewDataVersion) => {
        const values = {
            version: NewDataVersion,
            status: 'Active'
        }
        await API.graphql({ query: updateDataVersion, variables: {input: values}});
    }

    // updateDataVersion = async() => {
    //     let DataVer = await API.graphql(graphqlOperation(listDataVersions))
    //     let dataVers = DataVer.data.listDataVersions.items
    //     console.log(dataVers)
    //     let DataVersion = [...dataVers.filter( dataVers => dataVers.status === 'Active' )]
    //     console.log(DataVersion[0].version)
    //     const newVersion = DataVersion[0].version + 1
    //     // this.deactivateDataVersion(DataVersion[0].id) // unsure if needed
    //     this.createNewDataVersion(newVersion)
    // }

    getDataVersion = async() => {
        let DataVer = await API.graphql(graphqlOperation(listDataVersions))
        let dataVers = DataVer.data.listDataVersions.items
        let NewData = [...dataVers.filter( dataVers => dataVers.status === 'New' )]
        let ActiveData = [...dataVers.filter( dataVers => dataVers.status === 'Active' )]
        console.log({ActiveData})
        this.deactivateDataVersion(ActiveData[0].version)
        console.log(NewData[0].version)
        this.activateDataVersion(NewData[0].version)
    }
    
    render() {
        return(
            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>ActivateNewDataVersion</Button>
        )
    }
    

}

// export default RunRequestBtn;





// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,
//         currentQueryName: state.selectedQueryName,
//         currentQueryCurrent: state.selectedQueryStayCurrent,
//         currentQueryMarkets: state.selectedQueryMarkets,
//         currentQueryConsumers: state.selectedQueryConsumers,
//         currentQueryStatus: state.selectedQueryStatus
//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }

// const mapDispatchToProps = dispatch => {
    
//     return {
//         onUpdateReq: (event) => {
//             dispatch({type: 'UPDATESELECTEDQUERYSTATUS', 
//                 currentQueryStatus: 'Active', 
//             })
//         }
        
        
//     }
// }

export default (ActivateNewDataVersionBtn);