import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createRegion } from '../graphql/mutations'
import { listOrganizations, regionsByParentRegion, regionsByStatus  } from '../graphql/queries'
import classnames from "classnames";
// import App from '../../App'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    FormText,
    Button,
    ListGroup,
    ListGroupItem,
    Badge
  } from "reactstrap";


class CustomRegionsMaker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            SelectedType: '',
            Username: '',
            organizations: [],
            SelectedType: 'SingleScore',
            orgsSelect: [],
            selectedOrg: {},
            role: '',
            email: '',
            filteredRegions: [],
            selectedRegions: [],
            regionParent: '',
            regionslist: [],
            regionalSelections: [],
            filteredRegionsData: [],
            regionColName: ''
        }
        this.getActiveStatesAndSubRegions()
        this.getOrganizations()
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.filteredRegionsData !== this.state.filteredRegionsData){
            this.displayFilteredRegionsLists()
        } 
        if(prevState.selectedRegions !== this.state.selectedRegions){
            this.displayBadges()
        } 
    }
    

    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            // this.getActiveStatesAndSubRegions()
            
        }
    };

    handleRegionNameChange = event => {
        this.setState({regionName: event.target.value})
        // console.log(event.target.value)
    }

    handleRegionColNameChange = event => {
        this.setState({regionColName: event.target.value})
        // console.log(event.target.value)
    }

    trimArray = (arr) => {
        let array = arr.map(function (el) {
        return el.trim();
        });
        return(array)
    }

    getOrganizations = async() => {
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100,  }});
        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }
        this.setState({organizations: orgItems})
        orgItems = [{id: '0', name:'Select Org'}, ...orgItems]
        this.setState({orgsSelect: orgItems.map((org, index) => (
                <option key={org.id ? org.id : index}>
                    {org.name}
                </option>
            ))
        });
    }

    getRegionsByTypeAndState = async(state, type) => {
        const filterstr = {
            type: {
                eq: type 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr}})
        let notDone = true
        let regionArray = []
        let regionitems = regionsResponse.data.regionsByParentRegion.items
        regionArray = regionArray.concat(regionitems)
        while(notDone){
            if(regionsResponse.data.regionsByParentRegion.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr, nextToken: regionsResponse.data.regionsByParentRegion.nextToken}})
                regionitems = regionsResponse.data.regionsByParentRegion.items
                regionArray = regionArray.concat(regionitems)
        }}
        regionArray = regionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        return(regionArray)
    }

    getActiveStatesAndSubRegions = async() =>{
        const filterstr = {
            type: {
                eq: 'State' 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr}})
        let notDone = true
        let parentRegionArray = []
        let regionitems = regionsResponse.data.regionsByStatus.items
        parentRegionArray = parentRegionArray.concat(regionitems)
        while(notDone){
            
            if(regionsResponse.data.regionsByStatus.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr, nextToken: regionsResponse.data.regionsByStatus.nextToken}})
                regionitems = regionsResponse.data.regionsByStatus.items
                // console.log({regionitems})
                parentRegionArray = parentRegionArray.concat(regionitems)
                // console.log(countysArray)
        }}
        parentRegionArray = parentRegionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        const regiontypes = ['County' ,'Zip' ,'Custom' ]
        let regionArray = parentRegionArray.map( (region, index) => {
            regiontypes.map((type, index) => {
                this.getRegionsByTypeAndState(region['regionName'], type).then(function(results){ region[type] = (results)})
            })
            return(region)
        })

        this.setState({displayparentRegionsSelect: regionArray.map((region, index) => <>
            <option key={index}>
                {region['regionName']}
            </option>
        </>)})
        this.setState({ regions: regionArray})
    }

    displayRegionSelect = (regions, score) =>{
        const regionalSelections = this.state.regionalSelections
        return( regions.map((item, index) => (
            <FormGroup check key={index}>
                <Input type="checkbox" onClick={e => this.toggleRegionSelect(item, score)} value={ this.state.regionalSelections[item['regionCol']].includes(item['regionName'])} />
                <Label check>
                    {item['name']}
                </Label>
            </FormGroup>
            ))
        )
    }

    toggleRegionSelect = (item) => {
        const regioncol = item['regionCol']
        let regionalSelections = this.state.regionalSelections
        if(regionalSelections.includes[item['regionName']]){
            const index = regionalSelections.indexOf(item['regionName'])
            regionalSelections.splice(index, 1);
        } else{
            regionalSelections.push(item['regionName'])
        }
        this.displayFilterSelect()
        this.setState({ regionalSelections: regionalSelections})
    }



    searchRegions = (event) => {
        let value = event.target.value
        const selectedRegionData = this.state.regions.filter((region) => region['regionName'] = (this.state.regionParent))
        console.log({selectedRegionData})
        const tmpParent = this.state.regionParent
        const tmpRegionType = this.state.regionType
        console.log({tmpParent})
        console.log({tmpRegionType})
        let regionsbytype = selectedRegionData[0][tmpRegionType]
        console.log({regionsbytype})
        const regionslist = regionsbytype.filter((region) => region['regionName'].toLowerCase().startsWith(event.target.value.toLowerCase()))
        console.log({regionslist})
        // limit to 20 Items in the list
        this.setState({filteredRegionsData: regionslist})
    }

    displayFilteredRegionsLists = () => {   
        this.setState({filteredRegions: this.state.filteredRegionsData.map((region, index) => <>
            <ListGroupItem key={index}>
                <Row>
                    <Col>
                        {/* <Button onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>Add Region</Button> */}
                        { this.displayRegionSelectButton(region) }
                    </Col>
                    <Col>
                        <h3>{region['regionName']}</h3>
                    </Col>
                </Row>
            </ListGroupItem>
        </>)})
    }

    displayRegionSelectButton = (region) => {
        const isSelected = this.isRegionSelected(region)
        let btn = ''
        if(isSelected){
            btn = <Button color='danger' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region)}>Remove</Button>
        } else {
            btn = <Button color='primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region)}>Add</Button>
        }
        return(btn)
    }

    displayBadges = () => {
        const selectedRegions = this.state.selectedRegions
        let badges = selectedRegions.map((region, index) => <>
            <Badge key={index} color='primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region)}>
                <Row>
                    <Col>
                        {region['regionName']}
                    </Col>
                    <Col>
                        <i className="fas fa-window-close"></i>
                    </Col>
                </Row>
            </Badge>                
        
        </>)
        this.setState({ selectedRegionsBadges: badges })
    }

    isRegionSelected = (region ) => {
        let isIncluded = false
        this.state.selectedRegions.filter(function(fregion){
            if( fregion['id'] == region['id'] ){
                isIncluded = true
            }
        })
        return(isIncluded)
    }

    toggleFilteredRegionToSelectedRegions = (region) => {
        let selectedRegions = this.state.selectedRegions
        if( this.isRegionSelected(region)){
            selectedRegions = selectedRegions.filter(function(fregion){
                if( fregion['id'] != region['id'] ){
                    return(fregion)
                }
            })
        } else {
            selectedRegions.push(region)
        }
        this.setState({ selectedRegions: selectedRegions})
        this.displayBadges()
        this.displayFilteredRegionsLists()
    }

    SelectRegionType = (event) => {
        console.log(event.target.value)
        this.setState({regionType: event.target.value})
    }

    SelectRegionParent = (event) => {
        this.setState({regionParent: event.target.value})
        console.log(event.target.value)
    }

    displayParentRegionSelect = () => {
        this.setState({displayparentRegionsSelect: this.state.regions.map((region, index) => <>
            <option key={index}>
                {region['regionName']}
            </option>
        </>)})
    }

    handleSumbit = async(state) => {
        console.log({state})
        let regionids = this.state.selectedRegions.map((region, index) => {
            return(region['id'])
        })
        const values = {
            regionName: this.state.regionName,
            regionCol: this.state.regionColName,
            subRegions: regionids,
            parentRegion: this.state.regionParent,
            parentType: 'State',
            type: 'Custom',
        }
        console.log('Submitted Values', values)
        const response = await API.graphql(graphqlOperation(createRegion, {input: values}))
        console.log({response})
    }

    render  () {
        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("PrimaryCollapse")}
                aria-expanded={this.state.openedCollapses.includes("PrimaryCollapse")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">New Custom Region</CardTitle>
                        </Col>
                        <Col className='col-2' >
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("PrimaryCollapse")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("PrimaryCollapse")}>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Col sm={10}>
                                            <Input
                                            id="regionName"
                                            name="regionName"
                                            placeholder="regionName"
                                            value={this.state.regionName} 
                                            onChange={this.handleRegionNameChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Col sm={10}>
                                            <Input
                                            id="regionCol"
                                            name="regionCol"
                                            placeholder="regionCol"
                                            value={this.state.regionColName} 
                                            onChange={this.handleRegionColNameChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-12'>
                                    <h3> Select Regions</h3>
                                    <Row>
                                        <Col className='col-8 d-inline-block'>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Input id="regionTypeSelect" onChange={e => this.SelectRegionParent(e)}  name="regionTypeSelect" type="select">
                                                            <option key='none'>
                                                                Select Parent Region
                                                            </option>
                                                            {this.state.displayparentRegionsSelect}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Input id="regionTypeSelect" onChange={e => this.SelectRegionType(e)}   name="regionTypeSelect" type="select">
                                                            <option key='none'>
                                                                Select Region Type
                                                            </option>
                                                            <option>
                                                                County
                                                            </option>
                                                            <option>
                                                                Zip
                                                            </option>
                                                            <option>
                                                                Custom
                                                            </option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Input
                                                        id="searchRegions"
                                                        name="search"
                                                        placeholder="Region Search"
                                                        type="search"
                                                        onChange={ e => this.searchRegions(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                <h3>Selected Regions</h3>
                                                <div>
                                                    { this.state.selectedRegionsBadges}
                                                </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='col-4 h-50 d-inline-block overflow-auto'>
                                            {this.state.filteredRegions}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Button onClick={e => this.handleSumbit(this.state)} className="btn btn-success">
                                Submit
                            </Button>
                        </Form>

                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default CustomRegionsMaker