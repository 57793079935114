import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { listUsers, listOrganizations  } from '../graphql/queries'
import { createDeceasedReport,deceasedBatchProcessor } from '../graphql/mutations'
import { Storage } from 'aws-amplify'

import { 
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap";

class DeceasedQueryMaker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            adminSelectOrgInput: <></>,
            adminSelectedOrg: '',
            adminSelectOrgUserInput: <></>,
            adminSelectedOrgUser: '',
            authUsername: '',
            authUserData: {},
            authUserOrgData: {},
            authUserGroups: [],
            userQueries: [],
            allusers: [],
            allOrgs: [],
            PID:"",
            submitPressed:false,
            firstName:"",
            lastName:"",
            batchSubmitPressed:false,
            batchSubmitPayload:{},
            orgUserFailure:false,
            orgBatchUserFailure:false
        }
        this.GetUserInfo()
    }

    getUsers = async() => {
      // console.log('GetUsers')
      const filterstr = {
          or: [{status: { ne: 'Deleted'} },
              {status: { ne: 'Archived'} }
          ]
      }
      let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr,  }});
      let userItems = usersData.data.listUsers.items;
      let pdata = usersData.data.listUsers
      while(pdata.nextToken != null ){
          let nextToken = pdata.nextToken;
          let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr, nextToken }});
          pdata = {}
          pdata = usersData.data.listUsers
          userItems = userItems.concat(pdata.items)
      }

      let loggedInUserData = []
          let nonCurrentUsers = userItems.filter((user, index) => {
              if(user.username != this.state.authUsername){
                  return(user)
              } else {
                  console.log({user})
                  loggedInUserData.push(user)
                  this.setState({adminSelectedOrgUser: user})
                  this.setState({authUserData: user})

              }
          })
          nonCurrentUsers.sort((a, b) => (a.username < b.username) ? 1 : -1)
          nonCurrentUsers.map((user) => {
              loggedInUserData.push(user)
          })
          this.setState({allusers: loggedInUserData})
    }

    getOrgs = async() => {
      // console.log('GetOrgs')
      const filterstr = {status: { ne: 'Archived'} }
      let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr,  }});
      let orgItems = orgData.data.listOrganizations.items;
      let pdata = orgData.data.listOrganizations
      while(pdata.nextToken != null ){
          let nextToken = pdata.nextToken;
          let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr, nextToken }});
          pdata = {}
          pdata = orgData.data.listOrganizations
          orgItems = orgItems.concat(pdata.items)
      }
      let allOrgs = []
      const otherOrgs = orgItems.filter((org, index) => {
          if(org['name'] == this.state.authUserData['org']){
              allOrgs.push(org)
              this.setState({adminSelectedOrg: org})
              this.setState({authUserOrgData: org})
          } else{
              return(org)
          }
      })
      otherOrgs.sort((a, b) => (a.name < b.name) ? 1 : -1)
      otherOrgs.map((org) => {
          allOrgs.push(org)
      })
      // console.log({allOrgs})
      this.setState({allOrgs: allOrgs})
    }

    displayUserSelect = () => {
      // console.log('displayUserSelect')
      let filteredUsers = []
      filteredUsers = this.state.allusers.filter((user, index) => {
          if(this.state.adminSelectedOrg['name'] == user['org'] ){
              return(user)
          }
      })

      this.setState({adminSelectedOrgUser: filteredUsers[0] })

      // console.log({filteredUsers})
      this.setState({adminSelectOrgUserInput: filteredUsers.map((user, index) => <>
          <option key={user.id } value={JSON.stringify(user)}>
              {user.username}
          </option>
      </>)})
    }



    displayOrgSelect = () => {
        // console.log('displayOrgSelect')
        this.setState({adminSelectOrgInput: this.state.allOrgs.map((org, index) => <>
            <option key={org.name} value={JSON.stringify(org)}>
                {org.name}
            </option>
        </>)})
        this.displayUserSelect()

    }

    GetUserInfo = async() => {
        const token = await Auth.currentAuthenticatedUser()
        this.setState({authUsername: token['username'] })
        this.setState({authUserGroups: token['signInUserSession']['accessToken']['payload']['cognito:groups']}) 

        if( token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Admins')){
            this.getUsers()
        }

    }
    
    componentDidUpdate = (prevProps, prevState) => {
      // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
      if(prevState.userQueries !== this.state.userQueries){
          this.displayQueryBlocks()
          this.updateUserQueries()
      } 

      if(prevState.allusers !== this.state.allusers){
          this.getOrgs()
      } 

      if(prevState.allOrgs !== this.state.allOrgs){
          this.displayOrgSelect()
      } 

      if( prevState.adminSelectedOrg !== this.state.adminSelectedOrg){
          console.log(this.state.adminSelectedOrg)
          this.displayUserSelect()
      }

      // if(prevState.adminQueryOrgOptions !== this.state.adminQueryOrgOptions){

      // } 
      
      // if(prevState.adminQueryOrgOptions !== this.state.adminQueryOrgOptions){

      // } 
      

      if(prevState.adminSelectedOrgUser !== this.state.adminSelectedOrgUser){
          this.displayQueryBlocks()

      }
      if(prevState.adminSelectOrgUserInput !== this.state.adminSelectOrgUserInput){
          this.displayQueryBlocks()
      }




      
      // props updates here

      if(prevProps.reportsearchinput !== this.props.reportsearchinput){
          console.log(this.props.reportsearchinput)
          // this.filterUserPermittedReports()
          this.displayQueryBlocks()

      }
  }
    updateAdminSelectedOrgFilter = (event) => {
      // console.log(JSON.parse(event.target.value))
      this.setState({adminSelectedOrg: JSON.parse(event.target.value)})
    }
    updateAdminSelectedOrgUserFilter = (event) => {
      console.log(event.target.value)
      this.setState({adminSelectedOrgUser: JSON.parse(event.target.value)})
    }

    displayQueryBlocks = () => {
        // console.log(this.state.userQueries)
        if( this.state.authUserGroups.includes('Admins')){
            // console.log("User Is Admin")
            this.setState({adminQueryOrgOptions: <>
                <Row>
                    <Col className='col-4 p-3'>
                        <Label for="orgSelect" className='adminOrgLabel'>
                            Organization
                        </Label>
                        <Input
                            id="orgSelect"
                            name="select"
                            type="select"
                            className='adminReportOrgSelectFilters'
                            onChange={e => this.updateAdminSelectedOrgFilter(e) }
                            >
                            {this.state.adminSelectOrgInput}
                        </Input>

                    </Col>
                    <Col className='col-4 p-3'>
                        <Label for="orgUserSelect" className='adminOrgLabel'>
                            User
                        </Label>
                        <Input
                            id="orgUserSelect"
                            name="select"
                            type="select"
                            className='adminReportOrgSelectFilters'
                            onChange={e => this.updateAdminSelectedOrgUserFilter(e) }
                            >
                            {this.state.adminSelectOrgUserInput}
                        </Input>
                    </Col>
                </Row>
            </>})
        } else {
            // console.log("User Is NOT Admin")
            this.setState({adminQueryOrgOptions: <></>})
        }
    }

    handleSumbit = async(state) => {
        this.setState({ submitPressed:false })
        this.setState({orgUserFailure:false})
        let inputs = {}
        inputs["pid"] = this.state.PID
        inputs["fname"] = this.state.firstName
        inputs["lname"] = this.state.lastName
        if(typeof this.state.adminSelectedOrg.name === "undefined" 
        || typeof this.state.adminSelectedOrgUser.username === "undefined"
        || typeof this.state.PID === "undefined"
        || this.state.PID === "") {
            this.setState({orgUserFailure:true})
            setTimeout(() => {
                this.setState({ orgUserFailure: false });
              }, 2000); // fade off after 2 seconds (2000 milliseconds)
            return
        }
        inputs["org"] = this.state.adminSelectedOrg.name
        inputs["user"] = this.state.adminSelectedOrgUser.username
        console.log(inputs)
        const newDeceasedEntry = await API.graphql(graphqlOperation(createDeceasedReport,{input:inputs}));
        console.log(newDeceasedEntry)
        this.setState({
            PID: "",
            firstName: "",
            lastName: "",
            submitPressed:true
        })
        setTimeout(() => {
            this.setState({ submitPressed: false });
          }, 2000); // fade off after 2 seconds (2000 milliseconds)
        
    }

    handleFileSubmit = async(state) => {
        this.setState({ batchSubmitPressed:false })
        this.setState({orgBatchUserFailure:false})
        if(typeof this.state.batchSubmitPayload["user"] ==="undefined" 
        || typeof this.state.batchSubmitPayload["org"] === "undefined" 
        || typeof this.state.batchSubmitPayload["filename"] === "undefined"
        || this.state.batchSubmitPayload["filename"] === "")
        {
            this.setState({ orgBatchUserFailure: true });
            setTimeout(() => {
                this.setState({ orgBatchUserFailure: false });
              }, 2000); // fade off after 2 seconds (2000 milliseconds)
            return
        }
        console.log(this.state.batchSubmitPayload["user"])
        console.log(this.state.batchSubmitPayload["org"])
        const batchSendResults = await API.graphql(graphqlOperation(deceasedBatchProcessor,{input:JSON.stringify(this.state.batchSubmitPayload)}));
        console.log(batchSendResults)
        const updatedObject = { ...this.state.batchSubmitPayload }
        updatedObject["filename"] = ""
        this.setState({ batchSubmitPressed: true,
                        batchSubmitPayload:updatedObject
                     });
        setTimeout(() => {
            this.setState({ batchSubmitPressed: false });
          }, 2000); // fade off after 2 seconds (2000 milliseconds)
    }

    updateFormPIDInput = ( event ) => {
        console.log(event.target.value)
        this.setState({PID: event.target.value})
    }

    updateFormFNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({firstName: event.target.value})
    }

    updateFormLNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({lastName: event.target.value})
    }

    handleFileChange = async(event) => {
        const file = event.target.files[0];
        const result = await Storage.put(file.name, file);
        console.log('Uploaded file:', result);
        this.state.batchSubmitPayload["filename"] = file.name
        this.state.batchSubmitPayload["org"] = this.state.adminSelectedOrg.name
        this.state.batchSubmitPayload["user"] = this.state.adminSelectedOrgUser.username
      }

    render () {
      return( <>
      <Card>
        <Row>
        {this.state.submitPressed && <UncontrolledAlert color="success">Deceased entry submitted.</UncontrolledAlert>}
        {this.state.orgUserFailure && <UncontrolledAlert color="danger">
            Entry failed! Check whether org name and user name are selected.
            Also check if a PID was entered.</UncontrolledAlert>}
        </Row>
        <Row>
            <Col className='col-10'>
                <CardHeader>
                <CardTitle className="h2 mb-0">Known deceased PIDs form</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="PID" sm={2}>
                        PID
                      </Label>
                      <Col sm={10}>
                        <Input
                        id="PID"
                        name="PID"
                        placeholder="123456789"
                        value={this.state.PID}
                        onChange={e=>this.updateFormPIDInput( e )}
                        />
                      </Col>
                        <Label for="FirstName" sm={2}>
                            First name
                        </Label>
                      <Col sm={10}>
                        <Input
                        id="FirstName"
                        name="FirstName"
                        placeholder="John"
                        value={this.state.firstName}
                        onChange={e=>this.updateFormFNameInput( e )}
                        />
                      </Col>
                      <Label for="LastName" sm={2}>
                        Last name
                      </Label>
                      <Col sm={10}>
                        <Input
                        id="LastName"
                        name="LastName"
                        placeholder="Doe"
                        value={this.state.lastName}
                        onChange={e=>this.updateFormLNameInput( e )}
                        />
                      </Col>
                      <Container fluid className='ReportArea'>
                        { this.state.adminQueryOrgOptions }
                      </Container>
                    </FormGroup>
                        <Button onClick={e => this.handleSumbit(this.state)} className="btn">
                            Submit
                        </Button>
                  </Form>
                </CardBody>
            </Col>
        </Row>
        </Card>
        <Card>
            <Row>
                {this.state.batchSubmitPressed && <UncontrolledAlert color="success">Deceased batch entry submitted.</UncontrolledAlert>}
                {this.state.orgBatchUserFailure && <UncontrolledAlert color="danger">
                    Entry failed! Check whether org name and user name are selected,.
                    Also check whether you've attached a file.
                    </UncontrolledAlert>}
            </Row>
        <CardHeader>
            <CardTitle className="h2 mb-0">Batch entry for known deceased PIDs</CardTitle>
        </CardHeader>
        <CardBody>
            <Form>
                <Container fluid className='ReportArea'>
                        { this.state.adminQueryOrgOptions }
                </Container>
                <Row>
                    <input type="file" onChange={this.handleFileChange}/>
                </Row>
                <Button onClick={e => this.handleFileSubmit(this.state)} className="btn">
                        Submit
                </Button>
            </Form>
        </CardBody>
        </Card>
        </>)
    }
  };
export default DeceasedQueryMaker