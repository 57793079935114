import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { connect } from 'react-redux'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createProductQuery } from '../graphql/mutations'
import { listProducts, listOrganizations, usersByOrg, regionsByParentRegion, regionsByStatus  } from '../graphql/queries'
import classnames from "classnames";
import { 
    UncontrolledAccordion,
    AccordionItem,
    AccordionBody,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button,
    ListGroupItem,
    Badge,
    ListGroup
  } from "reactstrap";


class ContractQueryMaker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selfuser: {},
            openedCollapses: [],
            openOrgCollapse: '',
            selectedUsers: [],
            products: [],
            singleScores: [],
            singleScoresArr: [],
            regionalScores: [],
            columnGroups: [],
            columnGroupArr: [],
            ColumnSelect: [],
            ColumnSelectArr: [],
            ActiveStates: [],
            allRegions: [],
            SelectedParentRegionsCounties: [],
            SelectedParentRegionsZips: [],
            SelectedParentRegionsCustoms: [],
            selectedRegions: {},
            regionalSelections: ['Consumers'],
            regionParent: { Consumers: {}, },
            regionType: { Consumers: "", },
            displayparentRegionsSelect: [],
            regions: [],
            allRegionMenues: [],
            seachText: { Consumers: "", },
            filteredRegions: { Consumers: "", },
            filteredRegionsData: [],
            selectedRegionsBadges: { Consumers: [], },
            allColumnSelectMenus: [],
            ColumnSelectData: [],
            columnSelectValues: {},
            queryName: '',
            queryDescription: '',
            PrimaryQuerySwitches: {},
            targetOrgName: '',
            filterSelect: '',
            filterSelectDropdown: [],
            organizations: [],
            userItems: [],
            selectedTargetOrg: '',
            displayOrgSelect: '',
            displayTargetOrgUserSelections: '',
            targetOrgUsers: [],
            selfOrgUsers: [],
            orgOptions: [],
            targetOrg: {},
            selfOrgId: '',
            CustomQueryArr: [],
            customQueryselectArr: [],
            SQLWrapperArr: [],
            CustomQuerydisplay: <></>,
            sqlwrappers: <></>,
            selectedRegionTypeForm: <></>,
            selectedRegionFilterInput: <></>,
            subregionType: 'County',
            primarySelectedContractOrg: {},
            nonPrimarySelectedContractOrgs: []

        }



        this.getProducts()
        this.getActiveStatesAndSubRegions()
        // 



    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev props', prevProps.primarySelectedContractOrg); // Before update
        // console.log('New prevProps', this.props.primarySelectedContractOrg); // After update 
        if(prevState.selectedTargetOrg !== this.state.selectedTargetOrg){
            this.displayOrgNameSelect()
            this.handleQueryUpdates(this.state)
        } 
        if(prevState.PrimaryQuerySwitches !== this.state.PrimaryQuerySwitches){
            this.displayScoreOptions()
            this.handleQueryUpdates(this.state)
            // this.displayOrgNameSelect()
            // this.displaySelfOrgUsers()
        } 
        if(prevState.selectedRegionTypeForm !== this.state.selectedRegionTypeForm){
            this.displayAllRegionMenus()
            this.handleQueryUpdates(this.state)
        }
        if(prevState.selectedRegionFilterInput !== this.state.selectedRegionFilterInput){
            this.displayAllRegionMenus()
            this.handleQueryUpdates(this.state)
        }

        if(prevState.filteredRegionsData !== this.state.filteredRegionsData){
            this.displayAllRegionMenus()
            this.handleQueryUpdates(this.state)
        }

        if(prevState.primarySelectedContractOrg !== this.state.primarySelectedContractOrg){
            // this.displayPrimaryOrgUsers()
            // this.loadUsers(this.state.primarySelectedContractOrg)
            this.loadAllOrgUsers()

        }

        if(prevState.nonPrimarySelectedContractOrgs !== this.state.nonPrimarySelectedContractOrgs){
            // this.displayNonPrimaryOrgs()
            // this.loadUsersFromOrgs(this.state.nonPrimarySelectedContractOrgs)
            this.loadAllOrgUsers()
        }

        if(prevState.openOrgCollapse !== this.state.openOrgCollapse){
            this.displayPrimaryOrgUsers()
            this.displayNonPrimaryOrgs()
        }


        if(prevState.selectedUsers !== this.state.selectedUsers){
            this.displayPrimaryOrgUsers()
            this.displayNonPrimaryOrgs()
            this.handleQueryUpdates(this.state)
        }


        if(prevState.SQLWrapperArr !== this.state.SQLWrapperArr){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.filterSelect !== this.state.filterSelect){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.singleScoresArr !== this.state.singleScoresArr){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.columnSelectValues !== this.state.columnSelectValues){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.columnGroupArr !== this.state.columnGroupArr){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.customQueryselectArr !== this.state.customQueryselectArr){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.selectedRegions !== this.state.selectedRegions){
            this.handleQueryUpdates(this.state)
        }

        // if(prevState.PrimaryQuerySwitches !== this.state.PrimaryQuerySwitches){
        //     this.handleQueryUpdates(this.state)
        // }


        if(prevState.queryName !== this.state.queryName){
            this.handleQueryUpdates(this.state)
        }
        if(prevState.queryDescription !== this.state.queryDescription){
            this.handleQueryUpdates(this.state)
        }
        

        // props goes here

        if(prevProps.primarySelectedContractOrg !== this.state.primarySelectedContractOrg){
            console.log(this.props.primarySelectedContractOrg)
            this.setState({primarySelectedContractOrg: this.props.primarySelectedContractOrg})
            this.loadUsers(this.props.primarySelectedContractOrg)

        }

        if(prevProps.nonPrimarySelectedContractOrgs !== this.state.nonPrimarySelectedContractOrgs){
            console.log(this.props.nonPrimarySelectedContractOrgs)
            this.setState({nonPrimarySelectedContractOrgs: this.props.nonPrimarySelectedContractOrgs})
            this.loadUsersFromOrgs(this.props.nonPrimarySelectedContractOrgs)



        }

        
        
        // console.log({prevProps})
        // console.log({prevState})
    }

    boolToString(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            // this.getActiveStatesAndSubRegions()
            
        }
        this.displayAllRegionMenus()
    };

    getProducts = async() => {
        let productData = await API.graphql({ query: listProducts, variables: { limit: 100,  }});
        let productItems = productData.data.listProducts.items;
        let pdata = productData.data.listProducts
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProducts, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = productData.data.listProducts
            productItems = productItems.concat(pdata.items)
        }
        // console.log({productItems})
        this.setState({products: productItems})
        this.displayScoreOptions()
    }

    displayScoreOptions = () =>{

        if(this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')){
            let CustomQuery = [...this.state.products.filter( productItems => productItems.type === "CustomQuery" )]
            // console.log({ColumnSelect})

            const CustomQuerylist = CustomQuery.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleCustomQuerySelect(item)} value={ this.state.CustomQueryArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({CustomQuerydisplay: <>
                <h3>Custom Query</h3>
                {CustomQuerylist}
            </>});
        


            this.setState({singleScores: <></>})
            this.setState({regionalScores: <></>})
            this.setState({columnGroups: <></>})
            this.setState({columnSelections: <></>})
            
            let SQLWrapper = [...this.state.products.filter( productItems => productItems.type === "SQLWrapper" )]
            // console.log({ColumnSelect})

            const SQLWrapperlist = SQLWrapper.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSQLWrapperSelect(item)} checked={ this.state.SQLWrapperArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({sqlwrappers: <>
                <h3>SQL Wrappers</h3>
                {SQLWrapperlist}
            </>});

        } else{
            const singleScore = [...this.state.products.filter( productItems => productItems.type === "SingleScore" )]
            const scorelist = singleScore.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSingleScore(item)} value={ this.state.singleScoresArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
            ))
            // console.log({singleScore})
            this.setState({singleScores: <> <h3>Single Scores</h3>
                {scorelist}
            </>});
            let regionalScore = [...this.state.products.filter( productItems => productItems.type === "RegionalScore" )]
            // console.log({regionalScore})
            const regionalScorelist = regionalScore.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleRegionalScore(item)} value={ this.state.regionalSelections.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))

            this.setState({regionalScores: <><h3>Regional Scores</h3>
                {regionalScorelist}
            </>});
            let ColumnGroup = [...this.state.products.filter( productItems => productItems.type === "ColumnGroup" )]
            // console.log({ColumnGroup})
            const ColumnGrouplist = ColumnGroup.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleColumnGroups(item)} value={ this.state.columnGroupArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))
            this.setState({columnGroups: <>
                <h3>Column Groups</h3>
                {ColumnGrouplist}
            </>});
            let ColumnSelect = [...this.state.products.filter( productItems => productItems.type === "ColumnSelect" )]
            // console.log({ColumnSelect})

            const ColumnSelectlist = ColumnSelect.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleColumnSelect(item)} value={ this.state.ColumnSelectArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({columnSelections: <>
                <h3>Column Selections</h3>
                {ColumnSelectlist}
            </>});

            let SQLWrapper = [...this.state.products.filter( productItems => productItems.type === "SQLWrapper" )]
            // console.log({ColumnSelect})

            const SQLWrapperlist = SQLWrapper.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSQLWrapperSelect(item)} value={ this.state.SQLWrapperArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({sqlwrappers: <>
                <h3>SQL Wrappers</h3>
                {SQLWrapperlist}
            </>});
        
        this.setState({CustomQuerydisplay: <></>})

        }
    }

    toggleSQLWrapperSelect = (item) => {
        let selectedArr = this.state.SQLWrapperArr
        console.log({selectedArr})
        console.log({item})
        if(this.state.SQLWrapperArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        console.log({selectedArr})
        this.setState({SQLWrapperArr: selectedArr})
        this.displayScoreOptions()
        this.handleQueryUpdates(this.state)

    }



    toggleCustomQuerySelect = (item) => {
        let selectedArr = this.state.customQueryselectArr
        console.log({selectedArr})
        console.log({item})
        if(this.state.customQueryselectArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        console.log({selectedArr})
        this.setState({customQueryselectArr: selectedArr})
        this.displayScoreOptions()
        this.handleQueryUpdates(this.state)

    }

    checkBoxIfConsumerRegionSelected = (region) => {
        let selectedQueryRegions = this.state.selectedQueryRegions;
        if (selectedQueryRegions.includes(region)) {
            return(true)
        } else {
            return(false)
        }
    }

    SetSelectedParentRegion = (state) => {
        // this.setState({ selectedParentRegion: state})
        this.setState({ rSelected: state.regionCol })
        
        console.log('selected ', state)
        this.displayCountySubregionsFromSelectedRegion(state['counties'])
        this.displayZipSubregionsFromSelectedRegion(state['zips'])
        this.displayCustomSubregionsFromSelectedRegion(state['customs'])
        this.displayActiveRegions()
    }

    displayRegionActivationCheckbox = (status) => {
        let returnval = false
        if(status === 'Active'){
            returnval = true
        } else{
            returnval = false
        }
        return(returnval)
    }

    toggleColumnSelect = (item) => {
        let selectedArr = this.state.ColumnSelectArr
        let selectData = this.state.ColumnSelectData

        if(this.state.ColumnSelectArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })

            selectData = selectData.filter(function(value, index, arr){ 
                return value['name'] != item['name'];
            })


        } else{
            selectedArr[selectedArr.length] = item['name']
            selectData[selectData.length] = item
        }

        console.log(selectData)
        this.setState({ColumnSelectData: selectData})

        this.setState({ColumnSelectArr: selectedArr})
        this.displayColumnSelectMenues(selectData)
        this.displayScoreOptions()
        this.handleQueryUpdates(this.state)

    }

    toggleColumnSelectValues = (value, item) => {
        // columnSelectValues[item]
        console.log({item})
        console.log({value})
        let columnSelectVals = this.state.columnSelectValues
        console.log({columnSelectVals})

        if(this.state.columnSelectValues.hasOwnProperty(item) ){
            if(columnSelectVals[item].includes(value)){
                columnSelectVals[item] = columnSelectVals[item].filter(function(value, index, arr){ 
                    return value != item;
                })
            } else{
                columnSelectVals[item].push(value)
            }

        } else{
            columnSelectVals[item] = [value]
        }

        console.log(columnSelectVals)
        this.setState({columnSelectValues: columnSelectVals})
        this.handleQueryUpdates(this.state)

    }

    displayColumnSelectMenues = (colSelectData) => {
        // console.log({colSelectData})
        this.setState({allColumnSelectMenus: colSelectData.map((columnSelect, index) => {
            console.log({columnSelect})
            const metadata = JSON.parse(columnSelect['metadata'])
            console.log(metadata['tableCols'])
            let tableCols = metadata['tableCols'].filter(function(value, index, arr){ 
                return value != 'match_id';
            })
            let ColSelectCheckboxes = tableCols.map((col) => <>
                <Col key={col} className='col-12'>
                    <FormGroup check >
                        <Input type="checkbox" onClick={e => this.toggleColumnSelectValues(col, columnSelect['name'])} value={ this.state.ColumnSelectArr.includes(col)} />
                        <Label check>
                            {col}
                        </Label>
                    </FormGroup>
                </Col>
            </>)
            return(<>
                <div key={columnSelect['name']}>
                    <h3>{columnSelect['name']}</h3>
                    <Row>
                        {ColSelectCheckboxes}
                    </Row>
                </div>
            </>)
        })})
    }

    toggleColumnGroups = (item) => {
        let selectedArr = this.state.columnGroupArr
        if(this.state.columnGroupArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        this.setState({columnGroupArr: selectedArr})
        this.displayScoreOptions()
        this.handleQueryUpdates(this.state)

    }

    toggleRegionalScore = (item) => {
        console.log({item})
        let regionalSelect = this.state.regionalSelections
        const tmpregionparent = this.state.regionParent
        const regionArray = this.state.regions
        const regionType = this.state.regionType
        const regionSelections = this.state.selectedRegions
        if(regionalSelect.includes(item['name']) ){
            regionalSelect.splice(regionalSelect.indexOf(item['name']))
            if(regionSelections.hasOwnProperty(item['name'])){
                delete regionSelections[item['name']]
                this.setState({ selectedRegions: regionSelections})
            } 
        } else {
            regionalSelect.push(item['name'])
        }
        this.setState({regionalSelections: regionalSelect})
        if(tmpregionparent.hasOwnProperty(item['name'])){
            delete tmpregionparent[item['name']]
        } else{
            tmpregionparent[item['name']] = regionArray[0]
        }
        this.setState({ regionParent: tmpregionparent})
        if(regionType.hasOwnProperty(item['name'])){
            delete regionType[item['name']]
        } else{
            regionType[item['name']] = 'County'
        }
        this.setState({ regionType: regionType})
        this.displayFilterSelect()
        this.displayScoreOptions()
        this.displayAllRegionMenus()
        this.handleQueryUpdates(this.state)

    }

    toggleSingleScore = (item) => {
        let selectedArr = this.state.singleScoresArr
        if(this.state.singleScoresArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        this.setState({singleScoresArr: selectedArr})
        this.displayScoreOptions()
        this.displayFilterSelect()
        this.handleQueryUpdates(this.state)

    }

    getRegionsByTypeAndState = async(state, type) => {
        const filterstr = {
            type: {
                eq: type 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr}})
        let notDone = true
        let regionArray = []
        let regionitems = regionsResponse.data.regionsByParentRegion.items
        regionArray = regionArray.concat(regionitems)
        while(notDone){
            
            if(regionsResponse.data.regionsByParentRegion.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr, nextToken: regionsResponse.data.regionsByParentRegion.nextToken}})
                regionitems = regionsResponse.data.regionsByParentRegion.items
                regionArray = regionArray.concat(regionitems)
        }}
        regionArray = regionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        // console.log(regionArray)
        return(regionArray)
    }

    getActiveStatesAndSubRegions = async() =>{
        const filterstr = {
            type: {
                eq: 'State' 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr}})
        // console.log(regionsResponse)
        let notDone = true
        let parentRegionArray = []
        let regionitems = regionsResponse.data.regionsByStatus.items
        parentRegionArray = parentRegionArray.concat(regionitems)
        while(notDone){
            
            if(regionsResponse.data.regionsByStatus.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr, nextToken: regionsResponse.data.regionsByStatus.nextToken}})
                regionitems = regionsResponse.data.regionsByStatus.items
                // console.log({regionitems})
                parentRegionArray = parentRegionArray.concat(regionitems)
                // console.log(countysArray)
        }}
        parentRegionArray = parentRegionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        this.setState({ ActiveStates: parentRegionArray})

        const regiontypes = ['County' ,'Zip' ,'Custom' ]

        let regionArray = parentRegionArray.map( (region, index) => {
            
            regiontypes.map((type, index) => {
                this.getRegionsByTypeAndState(region['regionName'], type).then(function(results){ region[type] = (results)})
            })
            return(region)
        })
        // console.log({regionArray})
        this.setState({ regions: regionArray})
        this.displayParentRegionSelect()
        const tmpregionparent = this.state.regionParent
        tmpregionparent['Consumers'] = regionArray[0]
        this.setState({ regionParent: tmpregionparent})
    }

    displayRegionSelect = (regions, score) =>{
        const regionalSelections = this.state.regionalSelections
        return( regions.map((item, index) => (
            <FormGroup check key={index}>
                <Input type="checkbox" onClick={e => this.toggleRegionSelect(item, score)} value={ this.state.regionalSelections[item['regionCol']].includes(item['regionName'])} />
                <Label check>
                    {item['name']}
                </Label>
            </FormGroup>
            ))
        )
    }

    toggleRegionSelect = (item, score) => {
        const regioncol = item['regionCol']
        let regionalSelections = this.state.regionalSelections
        if(regionalSelections.includes(score)){
            if(regionalSelections[score].includes[item['regionName']]){
                const index = regionalSelections[score].indexOf(item['regionName'])
                regionalSelections.splice(index, 1);
            } else{
                regionalSelections[score].push(item['regionName'])
            }
        }
        this.displayFilterSelect()
        // console.log({regionalSelections})
        this.setState({ regionalSelections: regionalSelections})
        this.handleQueryUpdates(this.state)

    }

    displayAllRegionMenus = () => {
        // console.log(this.state)
        this.setState({allRegionMenues:
            this.state.regionalSelections.map((Item, index) =><>
            <Row key={index}>
                <Col className='col-12'>
                    <h3> Select Regions For {Item} </h3>
                    <Row>
                        <Col className='d-inline-block'>
                            <Row>
                                <Col className='col-8'>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="regionTypeSelect">
                                                Region Type
                                                </Label>
                                                <Input id="regionTypeSelect" onChange={e => this.SelectRegionType(e, Item)}   name="regionTypeSelect" type="select">
                                                    <option>
                                                        Select Region Type
                                                    </option>
                                                    <option>
                                                        State
                                                    </option>
                                                    <option>
                                                        County
                                                    </option>
                                                    <option>
                                                        Zip
                                                    </option>
                                                    {/* <option>
                                                        Custom
                                                    </option> */}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            {this.state.selectedRegionTypeForm}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3>Selected Regions</h3>
                                            <div>
                                                { this.state.selectedRegionsBadges[Item]}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>
                                        <Col>
                                            {this.state.selectedRegionFilterInput}
                                        </Col>
                                    </Row>
                                    <Row>
                                                        
                                        <Col className='h-50 d-inline-block overflow-auto'>
                                            {this.state.filteredRegions[Item]}
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>

                </Col>
            </Row>
            <br/>
            </>)
        })
        this.displayFilterSelect()

    }

    searchRegions = (event, item) => {
        let value = event.target.value
        // console.log({value})
        const tmpParent = this.state.regionParent
        const tmpRegionType = this.state.regionType
        let regionsbytype = tmpParent[item][tmpRegionType[item]]
        console.log({regionsbytype})
        let filteredRegions = this.state.filteredRegions
        const regionslist = regionsbytype.filter((region) => region['regionName'].toLowerCase().startsWith(event.target.value.toLowerCase()))
        // limit to 20 Items in the list
        this.setState({filteredRegionsData: regionslist})

        if(value != ""){
            this.displayFilteredRegionsLists(item, regionslist)
        }else{
            this.displayFilteredRegionsLists(item, [])
        }
        
    }

    displayFilteredRegionsLists = (item, regionsList) => {
        let filteredRegions = this.state.filteredRegions
        // let regionslist = this.state.filteredRegionsData
        filteredRegions[item] = regionsList.map((region, index) => <>
            <ListGroupItem key={index}>
                { this.displayRegionSelectButton(region, item) }
            </ListGroupItem>
        </>)
    
        this.setState({filteredRegions: filteredRegions})
        this.displayAllRegionMenus()
    }

    displayRegionSelectButton = (region, item) => {
        const isSelected = this.isRegionSelected(region, item)
        let btn = ''
        if(isSelected){
            btn = <div className='btn btn-md btn-danger' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>{region.regionName}</div>
        } else {
            btn = <div className='btn btn-md btn-primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>{region.regionName}</div>
        }
        return(btn)
    }

    displayBadges = (item) => {
        const selectedRegions = this.state.selectedRegions
        let selectedRegionsBadges = this.state.selectedRegionsBadges
        let badges = selectedRegions[item].map((region, index) => <>
            <Badge key={index} color='primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>
                <Row>
                    <Col>
                        {region['regionName']}
                    </Col>
                    <Col>
                        <i className="fas fa-window-close"></i>
                    </Col>
                </Row>
            </Badge>                
        
        </>)
        selectedRegionsBadges[item] = badges
        this.setState({ selectedRegionsBadges: selectedRegionsBadges })
        this.displayAllRegionMenus()
    }

    isRegionSelected = (region, item ) => {
        let isIncluded = false
        if(this.state.selectedRegions.hasOwnProperty(item)){
            this.state.selectedRegions[item].filter(function(fregion){
                if( fregion['id'] == region['id'] ){
                    isIncluded = true
                }
            })
        }
        return(isIncluded)
    }

    toggleFilteredRegionToSelectedRegions = (inputRegion, item) => {
        let selectedRegions = this.state.selectedRegions
        let region = inputRegion
        if(region['type'] =='State'){
            console.log("this is a state")
            region.subtype = this.state.subregionType
        }
        if( this.isRegionSelected(region, item)){
            selectedRegions[item] = selectedRegions[item].filter(function(fregion){
                if( fregion['id'] != region['id'] ){
                    return(region)
                }
            })
        } else {
            if(selectedRegions.hasOwnProperty(item)){

                // if(region['type'] =='State'){
                //     // console.log("this is a state")
                //     region.subtype = this.state.subregionType
                // }
                // console.log({region})
                // // console.log(this.state.subregionType)
                // // console.log(region.type)


                selectedRegions[item].push(region)
            } else{
                selectedRegions[item] = [region]
            }
        }
        // console.log(selectedRegions)
        this.setState({ selectedRegions: selectedRegions})
        this.displayBadges(item)
        this.displayFilteredRegionsLists(item, this.state.filteredRegionsData)
        this.handleQueryUpdates(this.state)

    }

    SelectRegionType = (event, Item) => {
        console.log(event.target.value)
        console.log({Item})
        let tmpType = this.state.regionType
        tmpType[Item] = event.target.value
        this.setState({regionType: tmpType})
        // console.log(this.state.ActiveStates)


        


        if(event.target.value == 'County' | event.target.value == 'Zip' | event.target.value == 'Custom'){
            this.setState({ selectedRegionTypeForm: <>
                <Col>
                    <FormGroup>
                        <Label for="regionTypeSelect">
                        Parent Region
                        </Label>
                        <Input id="regionTypeSelect" onChange={e => this.SelectRegionParent(e, Item)}  name="regionTypeSelect" type="select">
                            {this.state.displayparentRegionsSelect}
                        </Input>
                    </FormGroup>
                </Col>
            </>})

            this.setState({ selectedRegionFilterInput: <>
                <FormGroup>
                    <Label for="searchRegions">
                    Search
                    </Label>
                    <Input
                    id="searchRegions"
                    name="search"
                    placeholder="Region Search"
                    type="search"
                    onChange={ e => this.searchRegions(e, Item)}
                    />
                </FormGroup>            
            </>})
        } else if(event.target.value == 'State'){
            this.setState({filteredRegionsData: this.state.ActiveStates})

            this.displayFilteredRegionsLists(Item, this.state.ActiveStates)

            this.setState({ selectedRegionTypeForm: <>
                <FormGroup>
                    <Label for="subregionTypeSelect">
                    Sub-Region Type
                    </Label>
                    <Input id="subregionTypeSelect" onChange={e => this.SelectSubRegionType(e, Item)}   name="subregionTypeSelect" type="select">
                        <option>
                            County
                        </option>
                        <option>
                            Zip
                        </option>
                    </Input>
                </FormGroup>
            </>})
            this.setState({ selectedRegionFilterInput: <>

            </>})
        } else {
            this.setState({ selectedRegionTypeForm: <></>})
            this.setState({ selectedRegionFilterInput: <></>})
        }
    }


    SelectSubRegionType = (event, Item) => {
        console.log(event.target.value)
        console.log({Item})
        let tmpType = this.state.regionType
        tmpType[Item] = event.target.value
        this.setState({subregionType: tmpType})
        // console.log(this.state)

    }

    SelectRegionParent = (event, Item) => {
        console.log(event.target.value)
        // console.log({Item})
        let tmpParent = this.state.regionParent
        tmpParent[Item] = event.target.value
        this.setState({regionParent: tmpParent})
    }

    displayParentRegionSelect = () => {
        const regions = this.state.regions
        this.setState({displayparentRegionsSelect: regions.map((region, index) => <>
            <option key={index}>
                {region['regionName']}
            </option>
        </>)})
        this.displayAllRegionMenus()
    }

    updateQueryName = (event) => {
        console.log(event.target.value)
        this.setState({queryName: event.target.value})
    }

    updateTargetOrgName = (event) => {
        console.log(event.target.value)
        this.setState({targetOrgName: event.target.value})
    }


    updateQueryDescription = (event) => {
        console.log(event.target.value)
        this.setState({queryDescription: event.target.value})
    }

    togglePrimaryQuerySwitches = (event, action) => {
        // console.log(event.target.value)
        // console.log({action})
        let querySwitches = this.state.PrimaryQuerySwitches
        if(querySwitches.hasOwnProperty(action)){
            delete querySwitches[action] 
        } else{
            querySwitches[action] = true
        }
        // console.log({querySwitches})
        this.setState({PrimaryQuerySwitches: querySwitches})
        // this.displayOrgNameSelect()
        // this.displaySelfOrgUsers()
        this.displayScoreOptions()

        this.handleQueryUpdates(this.state)

    }


    setOpenCollapse = (collapseName) => {
        // console.log({collapseName})
        if(collapseName == this.state.openOrgCollapse){
            this.setState({openOrgCollapse: ''})
        } else {
            this.setState({openOrgCollapse: collapseName})
        }

        // console.log(this.state.selectedUsers)

    }

    loadUsers = (org) => {
        let users = org.users.map((user) => {
            return(user.username)
        })

        // console.log(users)
        users = this.state.selectedUsers.concat(users)
        // console.log(users)

        // this.setState({selectedUsers: users })
        return(users)
    }

    loadAllOrgUsers = () => {
        let pusers = this.loadUsers(this.state.primarySelectedContractOrg)
        let npusers = this.loadUsersFromOrgs(this.state.nonPrimarySelectedContractOrgs)

        let selectedUsers = pusers.concat(npusers).flat(1)
        // console.log(selectedUsers)
        this.setState({selectedUsers: selectedUsers })

    }

    loadUsersFromOrgs = (orgs) => {
        // console.log({orgs})
        let users = orgs.map((org) => {
            let usersarrs = org.users.map((user) => {
                return(user.username)
            })
            return(usersarrs.flat(1))
        })
        // console.log(users)

        users = this.state.selectedUsers.concat(users).flat(1)

        // this.setState({selectedUsers: users.flat(1) })
        return(users)

    }

    toggleUsernameSelect = (username) => {
        // console.log({username})
        let users = this.state.selectedUsers
        // console.log(this.state.selectedUsers)
        if (users.includes(username)) {
            const index = users.indexOf(username)
            users.splice(index, 1);
            this.setState({
                selectedUsers: users
            });
        } else {
            this.setState({
                selectedUsers: [ ...this.state.selectedUsers, username]
            })
        }

        this.displayPrimaryOrgUsers()
        this.displayNonPrimaryOrgs()

    }

    isUsernameCheckboxChecked = (username) => {
        if(this.state.selectedUsers.includes(username)){
            return(true)
        } else {
            return(false)
        }
    }

    displayPrimaryOrgUsers = async() => {
        let users = this.state.primarySelectedContractOrg.users.map((user) => <>
        <ListGroupItem onClick={ e => this.toggleUsernameSelect(user.username)}>
            <Row className='p-0 g-0'>
                <Col className='col-10'>            
                    {user.username}
                </Col>
                <Col className='col-1 p-0 g-0'>
                    <Input class="form-check-input mt-0" type="checkbox" checked={ this.isUsernameCheckboxChecked(user.username) }  />
                </Col>
            </Row>
        </ListGroupItem>
        </>)

        this.setState({displayPrimaryOrg: <>
            <ListGroupItem>
                <Row className='p-0 g-0'>
                    <Col className='col-10'>
                        <h3>{this.state.primarySelectedContractOrg.name}</h3>
                    </Col>
                    <Col className='col-2 p-0 g-0'>
                        <Button className='btn-sm contractUsersEditButton' color='info' onClick={e => this.setOpenCollapse(this.state.primarySelectedContractOrg.name)}>
                            {/* <i className="fa-solid fa-pen-to-square" /> */}
                            <i className="fa-solid fa-user-pen"></i>
                        </Button>
                    </Col>
                </Row>

                <Collapse isOpen={ this.state.primarySelectedContractOrg.name == this.state.openOrgCollapse}>
                    <hr />
                    {users}
                </Collapse>
            </ListGroupItem>
        </>})

    }

    displayNonPrimaryOrgs = () => {
        this.setState({displayNonPrimaryOrg: this.state.nonPrimarySelectedContractOrgs.map((org) => {
            let users = org.users.map((user) => <>
            <ListGroupItem  onClick={ e => this.toggleUsernameSelect(user.username)}>
                <Row className='p-0 g-0'>
                    <Col className='col-10'>            
                        {user.username}
                    </Col>
                    <Col className='col-1 p-0 g-0'>
                        <Input class="form-check-input mt-0" type="checkbox" checked={ this.isUsernameCheckboxChecked(user.username) } />
                    </Col>
                </Row>
            </ListGroupItem>
            </>)
            return(<>
                <ListGroupItem>
                    <Row className='p-0 g-0'>
                        <Col className='col-10'>
                            <h3>{org.name}</h3>
                        </Col>
                        <Col className='col-2 p-0 g-0'>
                            <Button className='btn-sm contractUsersEditButton' onClick={e => this.setOpenCollapse(org.name)}>
                                {/* <i className="fa-solid fa-pen-to-square" /> */}
                                <i className="fa-solid fa-user-pen"></i>
                            </Button>
                        </Col>
                    </Row>

                    <Collapse isOpen={ org.name == this.state.openOrgCollapse}>
                        <hr />
                        {users}
                    </Collapse>
                </ListGroupItem>
            </>)}) 
        })
    }




    handleQueryUpdates = async(state) => {
        // console.log({state})
        let inputs = {}
        // inputs['status'] = 'Active'
        inputs['name'] = state.queryName
        inputs['description'] = state.queryDescription

        const querySwitches = state.PrimaryQuerySwitches
        // console.log({querySwitches})

        
        let preDeployInfo = {}
        // preDeployInfo['SourceOrg'] = this.state.primarySelectedContractOrg
        // preDeployInfo['CustomerOrgs'] =  this.state.nonPrimarySelectedContractOrgs 
        preDeployInfo['selectedUsers'] = this.state.selectedUsers
        inputs['preDeployInfo'] = preDeployInfo



        let products = {}
        products['queryOptions'] = querySwitches
        products['RegionalScore'] = state.selectedRegions

        if(this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')){
            products['CustomQuery'] = state.customQueryselectArr
            products['SQLWrapper'] = state.SQLWrapperArr
        } else{
            products['ColumnSelect'] = state.columnSelectValues
            products['ColumnGroup'] = state.columnGroupArr
            products['SingleScore'] = state.singleScoresArr
            products['FilterScore'] = state.filterSelect
            products['SQLWrapper'] = state.SQLWrapperArr

        }
        // console.log({metadata})
        // inputs['metadata'] = JSON.stringify(metadata)
        // console.log({products})
        inputs['products'] = JSON.stringify(products)

        console.log({inputs})

        this.props.updateContractQueryInputs(JSON.stringify(inputs))

        // from here this data gets pushed to props and is picked up by the constracts component
        // that will be stored in the contacts modal until the contract is submitted and saved
        // order of opersation will have to be figured out from there


        // also any queries that have destination queries will need updated to support arrays of queries
        // that seems to be the way this is working torwards anyway





        // const newProductQuery = await API.graphql(graphqlOperation(createProductQuery, {input: inputs}));
        // console.log({newProductQuery})

        // window.location.reload();
    }

    displayFilterSelect = () => {
        const singleScores = this.state.singleScoresArr
        const regionalSelections = this.state.regionalSelections
        const selectedRegions = this.state.selectedRegions
        let allScores = singleScores
        let regionScores = []
        regionalSelections.map((select, index) => {
            if(select != 'Consumers'){
                if(selectedRegions.hasOwnProperty(select)){
                    selectedRegions[select].map((item, index) => {
                        regionScores.push(item['regionCol'])
                    })
                }
            }
        })
        allScores = singleScores.concat(regionScores).map(function(value){ 
            return value.toLowerCase();
        })
        // console.log({allScores})
        // allScores = ['Select Filter', ...allScores]

        if(allScores.length > 0){
            this.setState({filterSelectDropdown: 
                allScores.map((score, index) => <>
                    <option key={index}>
                        {score}
                    </option>
                </>)
            })
        } else {
            this.setState({filterSelectDropdown: []})
        }
    }
    selectFilter = (event) => {
        console.log(event.target.value)
        this.setState({ filterSelect: event.target.value })
    }




    render  () {


        return( 
            <Form>
                <Row>
                    <Col className='col-6'>
                        <FormGroup>
                            <Input
                            id='QueryName'
                            value={this.state.queryName}
                            onChange={ e => this.updateQueryName(e)}
                            placeholder='Query Name'
                            />
                        </FormGroup>
                    </Col>
                    <Col className='col-6'>
                        <Input id="filterSelect" value={this.state.filterSelect} onChange={e => this.selectFilter(e)}  name="filterSelect" type="select">
                            <option key='none'>
                                Select Score Filter
                            </option>
                            {this.state.filterSelectDropdown}
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup >
                            <Input
                                id="QueryDescription"
                                name="text"
                                type="textarea"
                                placeholder='Query Description'
                                onChange={e => this.updateQueryDescription(e)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Supplier Org: </h3> 

                        <ListGroup>
                            {this.state.displayPrimaryOrg}
                        </ListGroup>

                        
                    </Col>
                    <Col>
                        <h3>Customer Orgs </h3>

                        <ListGroup>
                            {this.state.displayNonPrimaryOrg}
                        </ListGroup>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className='col-4'>
                        <h3>Query Options</h3>
                        <Row>
                            <Col className='col-12'>
                                <FormGroup check>
                                    <Input type="checkbox" onChange={ e => this.togglePrimaryQuerySwitches(e, 'GroupByRegion')} 
                                    value={ this.state.PrimaryQuerySwitches.hasOwnProperty('GroupByRegion')} />
                                    <Label check>
                                        GroupByRegion
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col className='col-12'>
                                <FormGroup check>
                                    <Input type="checkbox" onChange={ e => this.togglePrimaryQuerySwitches(e, 'UseCustomQuery')} 
                                    value={ this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')} />
                                    <Label check>
                                        Custom Query
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <br />
                        {this.state.sqlwrappers}
                    </Col>
                    <Col className='col-4'>
                    {this.state.singleScores}
                    <br />
                    {this.state.regionalScores}

                    </Col>
                    <Col className='col-4'>
                        {this.state.columnGroups}
                        <br />
                        {this.state.columnSelections}
                        <br />
                        {this.state.CustomQuerydisplay}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        {this.state.displayOrgSelect}
                    </Col>
                    <Col>
                        {this.state.displayTargetOrgUserSelections}
                    </Col>
                    <Col>
                        {this.state.displaySelfOrgUserSelections}
                    </Col>

                </Row>
                <br />
                {this.state.allColumnSelectMenus}
                <br />
                {this.state.allRegionMenues}
                {/* <Button onClick={e => this.handleSumbit(this.state)} className="btn">
                    Submit
                </Button> */}
            </Form>
        )
    }
};

const mapStateToProps = state => {
    // console.log({state})
    return {
        primarySelectedContractOrg: state.primarySelectedContractOrg,
        nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateContractQueryInputs: (event) => {
            console.log('UPDATECONTRACTQUERYINPUTSTOCONTRACT to props event: ', event)
            dispatch({type: 'UPDATECONTRACTQUERYINPUTSTOCONTRACT', 
            contractQueryInputs: event,
            })
        }
    }
}

// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));
export default connect( mapStateToProps, mapDispatchToProps )(ContractQueryMaker) 
