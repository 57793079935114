import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createOrganization } from '../graphql/mutations'
// import App from '../../App'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    FormText,
    Button
  } from "reactstrap";


class NewOrgForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            SelectedType: '',
            OrgName: '',
            tableName: '',
            SelectedType: 'SingleScore',
            tableCols: '',
            productCols: '',
            sqlString: '',
            dbName: '',
        }
        
    }

    setInput(key, value) {
        this.setState([key], value)
    }




    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
            
        }
    };

    handleOrgNameChange = event => {
        this.setState({OrgName: event.target.value})
    }

    trimArray = (arr) => {
        let array = arr.map(function (el) {
        return el.trim();
        });
        return(array)
    }

    handleNewOrgSumbit = async(state) => {
        console.log(state)

        const token = await Auth.currentAuthenticatedUser()
        let inputs = {
            name: state.OrgName,
            status: 'Active'
        }
        this.setState({OrgName: ''})

        const newOrg = await API.graphql(graphqlOperation(createOrganization, {input: inputs}));
        console.log({newOrg})
        window.location.reload();

    }



    render  () {


        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("ComputeTemplate")}
                aria-expanded={this.state.openedCollapses.includes("ComputeTemplate")}>
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">New Org</CardTitle>
                        </Col>
                        <Col className='col-2' >
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("ComputeTemplate")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("ComputeTemplate")}>
                    <CardBody>
                        <Form>
                            <FormGroup row>
                                <Label for="OrgName" sm={2}>
                                Org Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                    id="OrgName"
                                    name="OrgName"
                                    placeholder="Org Name"
                                    value={this.state.OrgName} 
                                    onChange={this.handleOrgNameChange}
                                    />
                                </Col>
                            </FormGroup>
                            <Button onClick={e => this.handleNewOrgSumbit(this.state)} className="btn">
                                Submit
                            </Button>
                        </Form>
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default NewOrgForm