const initialState = {
    selectedProductQuery: {},
    selectedProductQueryHistory: {},
    selectedOrg: {},
    reportsearchinput: '',
    primarySelectedContractOrg: {},
    nonPrimarySelectedContractOrgs: [],
    contractQueryInputs: ''

}
const reducer = ( state = initialState , action) => {

    // console.log({state})
    if(action.type === 'UPDATESSELECTEDPRODUCTQUERYDATA'){
        console.log('updating store with UPDATESSELECTEDPRODUCTQUERYDATA: ',  action.selectedProductQuery)
        // let tmpstate = state
        // state.selectedProductQuery = action.selectedProductQuery
        // console.log(state)
        return{
            selectedProductQuery: action.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }
    }

    if(action.type === 'UPDATESSELECTEDPRODUCTQUERYHISTORYDATA'){
        console.log('updating store with UPDATESSELECTEDPRODUCTQUERYHISTORYDATA')
        state.selectedProductQueryHistory = action
        // console.log(state)
        return{
            selectedProductQuery: state.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }    }

    if(action.type === 'UPDATESSELECTEDORGANIZATION'){
        console.log('updating store with UPDATESSELECTEDORGANIZATION')
        state.selectedOrg = action
        // console.log(state)
        return{
            selectedProductQuery: state.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }
    }



    if(action.type === 'UPDATEREPORTSEARCHINPUT'){
        console.log('updating store with UPDATEREPORTSEARCHINPUT: ', action.reportsearchinput)
        // console.log(state)
        // let tmpstate = state
        state.reportsearchinput = action.reportsearchinput
        // console.log(state)
        // return(state)

        return{
            selectedProductQuery: state.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }
        
        
    }

    if(action.type === 'UPDATESELECTEDCONTRACTORGANIZATIONS'){
        console.log('updating store with UPDATESELECTEDCONTRACTORGANIZATIONS: ', action)
        // console.log(state)
        // let tmpstate = state
        state.primarySelectedContractOrg = action.primarySelectedContractOrg
        state.nonPrimarySelectedContractOrgs = action.nonPrimarySelectedContractOrgs

        // console.log(state)
        // return(state)

        return{
            selectedProductQuery: state.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }
        
        
    }


    if(action.type === 'UPDATECONTRACTQUERYINPUTSTOCONTRACT'){
        console.log('updating store with UPDATECONTRACTQUERYINPUTSTOCONTRACT: ', action)
        // console.log(state)
        // let tmpstate = state
        state.contractQueryInputs = action.contractQueryInputs

        // console.log(state)
        // return(state)

        return{
            selectedProductQuery: state.selectedProductQuery,
            selectedProductQueryHistory: state.selectedProductQueryHistory,
            selectedOrg: state.selectedOrg,
            reportsearchinput: state.reportsearchinput,
            primarySelectedContractOrg: state.primarySelectedContractOrg,
            nonPrimarySelectedContractOrgs: state.nonPrimarySelectedContractOrgs,
            contractQueryInputs: state.contractQueryInputs
        }
        
        
    }






    
    

    return state;
}

export default reducer;