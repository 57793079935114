import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createProductQuery } from '../graphql/mutations'
import { listProducts, listOrganizations, listUsers, regionsByParentRegion, regionsByStatus  } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button,
    ListGroupItem,
    Badge
  } from "reactstrap";


class ProductQueryMaker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selfuser: {},
            openedCollapses: [],
            products: [],
            singleScores: [],
            singleScoresArr: [],
            regionalScores: [],
            columnGroups: [],
            columnGroupArr: [],
            ColumnSelect: [],
            ColumnSelectArr: [],
            ActiveStates: [],
            allRegions: [],
            SelectedParentRegionsCounties: [],
            SelectedParentRegionsZips: [],
            SelectedParentRegionsCustoms: [],
            selectedRegions: {},
            regionalSelections: ['Consumers'],
            regionParent: { Consumers: {}, },
            regionType: { Consumers: "", },
            displayparentRegionsSelect: [],
            regions: [],
            allRegionMenues: [],
            seachText: { Consumers: "", },
            filteredRegions: { Consumers: "", },
            filteredRegionsData: [],
            selectedRegionsBadges: { Consumers: [], },
            allColumnSelectMenus: [],
            ColumnSelectData: [],
            columnSelectValues: {},
            queryName: '',
            queryDescription: '',
            PrimaryQuerySwitches: {},
            targetOrgName: '',
            filterSelect: '',
            filterSelectDropdown: [],
            organizations: [],
            userItems: [],
            selectedTargetOrg: '',
            displayOrgSelect: '',
            displayTargetOrgUserSelections: '',
            targetOrgUsers: [],
            selfOrgUsers: [],
            orgOptions: [],
            targetOrg: {},
            selfOrgId: '',
            CustomQueryArr: [],
            customQueryselectArr: [],
            SQLWrapperArr: [],
            CustomQuerydisplay: <></>,
            sqlwrappers: <></>,
            selectedRegionTypeForm: <></>,
            selectedRegionFilterInput: <></>,
            subregionType: 'County',
        }



        this.getProducts()
        this.getActiveStatesAndSubRegions()
        this.getOrganizations()
        // 



    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        // console.log('New state', this.state.SecondaryTypeOpen); // After update 
        if(prevState.selectedTargetOrg !== this.state.selectedTargetOrg){
            this.displayOrgNameSelect()
        } 
        if(prevState.PrimaryQuerySwitches !== this.state.PrimaryQuerySwitches){
            this.displayScoreOptions()
            this.displayOrgNameSelect()
            this.displaySelfOrgUsers()
        } 
        if(prevState.selectedRegionTypeForm !== this.state.selectedRegionTypeForm){
            this.displayAllRegionMenus()
        }
        if(prevState.selectedRegionFilterInput !== this.state.selectedRegionFilterInput){
            this.displayAllRegionMenus()
        }

        if(prevState.filteredRegionsData !== this.state.filteredRegionsData){
            this.displayAllRegionMenus()
        }

        
        
        // console.log({prevProps})
        // console.log({prevState})
    }

    boolToString(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            // this.getActiveStatesAndSubRegions()
            
        }
        this.displayAllRegionMenus()
    };

    getProducts = async() => {
        let productData = await API.graphql({ query: listProducts, variables: { limit: 100,  }});
        let productItems = productData.data.listProducts.items;
        let pdata = productData.data.listProducts
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProducts, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = productData.data.listProducts
            productItems = productItems.concat(pdata.items)
        }
        // console.log({productItems})
        this.setState({products: productItems})
        this.displayScoreOptions()
    }

    displayScoreOptions = () =>{

        if(this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')){
            let CustomQuery = [...this.state.products.filter( productItems => productItems.type === "CustomQuery" )]
            // console.log({ColumnSelect})

            const CustomQuerylist = CustomQuery.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleCustomQuerySelect(item)} value={ this.state.CustomQueryArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({CustomQuerydisplay: <>
                <h3>Custom Query</h3>
                {CustomQuerylist}
            </>});
        


            this.setState({singleScores: <></>})
            this.setState({regionalScores: <></>})
            this.setState({columnGroups: <></>})
            this.setState({columnSelections: <></>})
            
            let SQLWrapper = [...this.state.products.filter( productItems => productItems.type === "SQLWrapper" )]
            // console.log({ColumnSelect})

            const SQLWrapperlist = SQLWrapper.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSQLWrapperSelect(item)} checked={ this.state.SQLWrapperArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({sqlwrappers: <>
                <h3>SQL Wrappers</h3>
                {SQLWrapperlist}
            </>});

        } else{
            const singleScore = [...this.state.products.filter( productItems => productItems.type === "SingleScore" )]
            const scorelist = singleScore.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSingleScore(item)} value={ this.state.singleScoresArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
            ))
            // console.log({singleScore})
            this.setState({singleScores: <> <h3>Single Scores</h3>
                {scorelist}
            </>});
            let regionalScore = [...this.state.products.filter( productItems => productItems.type === "RegionalScore" )]
            // console.log({regionalScore})
            const regionalScorelist = regionalScore.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleRegionalScore(item)} value={ this.state.regionalSelections.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))

            this.setState({regionalScores: <><h3>Regional Scores</h3>
                {regionalScorelist}
            </>});
            let ColumnGroup = [...this.state.products.filter( productItems => productItems.type === "ColumnGroup" )]
            // console.log({ColumnGroup})
            const ColumnGrouplist = ColumnGroup.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleColumnGroups(item)} value={ this.state.columnGroupArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))
            this.setState({columnGroups: <>
                <h3>Column Groups</h3>
                {ColumnGrouplist}
            </>});
            let ColumnSelect = [...this.state.products.filter( productItems => productItems.type === "ColumnSelect" )]
            // console.log({ColumnSelect})

            const ColumnSelectlist = ColumnSelect.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleColumnSelect(item)} value={ this.state.ColumnSelectArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({columnSelections: <>
                <h3>Column Selections</h3>
                {ColumnSelectlist}
            </>});

            let SQLWrapper = [...this.state.products.filter( productItems => productItems.type === "SQLWrapper" )]
            // console.log({ColumnSelect})

            const SQLWrapperlist = SQLWrapper.map((item, index) => (
                <Col className='col-12' key={index}>
                    <FormGroup check>
                        <Input type="checkbox" onClick={e => this.toggleSQLWrapperSelect(item)} value={ this.state.SQLWrapperArr.includes(item['name'])} />
                        <Label check>
                            {item['name']}
                        </Label>
                    </FormGroup>
                </Col>
                ))


            this.setState({sqlwrappers: <>
                <h3>SQL Wrappers</h3>
                {SQLWrapperlist}
            </>});
        
        this.setState({CustomQuerydisplay: <></>})

        }
    }

    toggleSQLWrapperSelect = (item) => {
        let selectedArr = this.state.SQLWrapperArr
        console.log({selectedArr})
        console.log({item})
        if(this.state.SQLWrapperArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        console.log({selectedArr})
        this.setState({SQLWrapperArr: selectedArr})
        this.displayScoreOptions()
    }



    toggleCustomQuerySelect = (item) => {
        let selectedArr = this.state.customQueryselectArr
        console.log({selectedArr})
        console.log({item})
        if(this.state.customQueryselectArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        console.log({selectedArr})
        this.setState({customQueryselectArr: selectedArr})
        this.displayScoreOptions()
    }

    checkBoxIfConsumerRegionSelected = (region) => {
        let selectedQueryRegions = this.state.selectedQueryRegions;
        if (selectedQueryRegions.includes(region)) {
            return(true)
        } else {
            return(false)
        }
    }

    SetSelectedParentRegion = (state) => {
        // this.setState({ selectedParentRegion: state})
        this.setState({ rSelected: state.regionCol })
        
        console.log('selected ', state)
        this.displayCountySubregionsFromSelectedRegion(state['counties'])
        this.displayZipSubregionsFromSelectedRegion(state['zips'])
        this.displayCustomSubregionsFromSelectedRegion(state['customs'])
        this.displayActiveRegions()
    }

    displayRegionActivationCheckbox = (status) => {
        let returnval = false
        if(status === 'Active'){
            returnval = true
        } else{
            returnval = false
        }
        return(returnval)
    }

    toggleColumnSelect = (item) => {
        let selectedArr = this.state.ColumnSelectArr
        let selectData = this.state.ColumnSelectData

        if(this.state.ColumnSelectArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })

            selectData = selectData.filter(function(value, index, arr){ 
                return value['name'] != item['name'];
            })


        } else{
            selectedArr[selectedArr.length] = item['name']
            selectData[selectData.length] = item
        }

        console.log(selectData)
        this.setState({ColumnSelectData: selectData})

        this.setState({ColumnSelectArr: selectedArr})
        this.displayColumnSelectMenues(selectData)
        this.displayScoreOptions()
    }

    toggleColumnSelectValues = (value, item) => {
        // columnSelectValues[item]
        console.log({item})
        console.log({value})
        let columnSelectVals = this.state.columnSelectValues
        console.log({columnSelectVals})

        if(this.state.columnSelectValues.hasOwnProperty(item) ){
            if(columnSelectVals[item].includes(value)){
                columnSelectVals[item] = columnSelectVals[item].filter(function(value, index, arr){ 
                    return value != item;
                })
            } else{
                columnSelectVals[item].push(value)
            }

        } else{
            columnSelectVals[item] = [value]
        }

        console.log(columnSelectVals)
        this.setState({columnSelectValues: columnSelectVals})

    }

    displayColumnSelectMenues = (colSelectData) => {
        // console.log({colSelectData})
        this.setState({allColumnSelectMenus: colSelectData.map((columnSelect, index) => {
            console.log({columnSelect})
            const metadata = JSON.parse(columnSelect['metadata'])
            console.log(metadata['tableCols'])
            let tableCols = metadata['tableCols'].filter(function(value, index, arr){ 
                return value != 'match_id';
            })
            let ColSelectCheckboxes = tableCols.map((col) => <>
                <Col key={col} className='col-12'>
                    <FormGroup check >
                        <Input type="checkbox" onClick={e => this.toggleColumnSelectValues(col, columnSelect['name'])} value={ this.state.ColumnSelectArr.includes(col)} />
                        <Label check>
                            {col}
                        </Label>
                    </FormGroup>
                </Col>
            </>)
            return(<>
                <div key={columnSelect['name']}>
                    <h3>{columnSelect['name']}</h3>
                    <Row>
                        {ColSelectCheckboxes}
                    </Row>
                </div>
            </>)
        })})
    }

    toggleColumnGroups = (item) => {
        let selectedArr = this.state.columnGroupArr
        if(this.state.columnGroupArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        this.setState({columnGroupArr: selectedArr})
        this.displayScoreOptions()
    }

    toggleRegionalScore = (item) => {
        console.log({item})
        let regionalSelect = this.state.regionalSelections
        const tmpregionparent = this.state.regionParent
        const regionArray = this.state.regions
        const regionType = this.state.regionType
        const regionSelections = this.state.selectedRegions
        if(regionalSelect.includes(item['name']) ){
            regionalSelect.splice(regionalSelect.indexOf(item['name']))
            if(regionSelections.hasOwnProperty(item['name'])){
                delete regionSelections[item['name']]
                this.setState({ selectedRegions: regionSelections})
            } 
        } else {
            regionalSelect.push(item['name'])
        }
        this.setState({regionalSelections: regionalSelect})
        if(tmpregionparent.hasOwnProperty(item['name'])){
            delete tmpregionparent[item['name']]
        } else{
            tmpregionparent[item['name']] = regionArray[0]
        }
        this.setState({ regionParent: tmpregionparent})
        if(regionType.hasOwnProperty(item['name'])){
            delete regionType[item['name']]
        } else{
            regionType[item['name']] = 'County'
        }
        this.setState({ regionType: regionType})
        this.displayFilterSelect()
        this.displayScoreOptions()
        this.displayAllRegionMenus()
    }

    toggleSingleScore = (item) => {
        let selectedArr = this.state.singleScoresArr
        if(this.state.singleScoresArr.includes(item['name']) ){
            selectedArr = selectedArr.filter(function(value, index, arr){ 
                return value != item['name'];
            })
        } else{
            selectedArr[selectedArr.length] = item['name']
        }
        this.setState({singleScoresArr: selectedArr})
        this.displayScoreOptions()
        this.displayFilterSelect()
    }

    getRegionsByTypeAndState = async(state, type) => {
        const filterstr = {
            type: {
                eq: type 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr}})
        let notDone = true
        let regionArray = []
        let regionitems = regionsResponse.data.regionsByParentRegion.items
        regionArray = regionArray.concat(regionitems)
        // console.log(regionArray)

        while(notDone){
            
            if(regionsResponse.data.regionsByParentRegion.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByParentRegion, variables: { parentRegion: state, filter: filterstr, nextToken: regionsResponse.data.regionsByParentRegion.nextToken}})
                regionitems = regionsResponse.data.regionsByParentRegion.items
                // console.log(regionitems)

                regionArray = regionArray.concat(regionitems)
                // console.log(regionArray)

                // console.log(countysArray)
        }}
        regionArray = regionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        // console.log(regionArray)
        return(regionArray)
    }

    getActiveStatesAndSubRegions = async() =>{
        const filterstr = {
            type: {
                eq: 'State' 
            }
        }
        let regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr}})
        // console.log(regionsResponse)
        let notDone = true
        let parentRegionArray = []
        let regionitems = regionsResponse.data.regionsByStatus.items
        parentRegionArray = parentRegionArray.concat(regionitems)
        while(notDone){
            
            if(regionsResponse.data.regionsByStatus.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByStatus, variables: {status: 'Active', filter: filterstr, nextToken: regionsResponse.data.regionsByStatus.nextToken}})
                regionitems = regionsResponse.data.regionsByStatus.items
                // console.log({regionitems})
                parentRegionArray = parentRegionArray.concat(regionitems)
                // console.log(countysArray)
        }}
        parentRegionArray = parentRegionArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        this.setState({ ActiveStates: parentRegionArray})

        const regiontypes = ['County' ,'Zip' ,'Custom' ]

        let regionArray = parentRegionArray.map( (region, index) => {
            
            regiontypes.map((type, index) => {
                this.getRegionsByTypeAndState(region['regionName'], type).then(function(results){ region[type] = (results)})
            })
            return(region)
        })
        // console.log({regionArray})
        this.setState({ regions: regionArray})
        this.displayParentRegionSelect()
        const tmpregionparent = this.state.regionParent
        tmpregionparent['Consumers'] = regionArray[0]
        this.setState({ regionParent: tmpregionparent})
    }

    displayRegionSelect = (regions, score) =>{
        const regionalSelections = this.state.regionalSelections
        return( regions.map((item, index) => (
            <FormGroup check key={index}>
                <Input type="checkbox" onClick={e => this.toggleRegionSelect(item, score)} value={ this.state.regionalSelections[item['regionCol']].includes(item['regionName'])} />
                <Label check>
                    {item['name']}
                </Label>
            </FormGroup>
            ))
        )
    }

    toggleRegionSelect = (item, score) => {
        const regioncol = item['regionCol']
        let regionalSelections = this.state.regionalSelections
        if(regionalSelections.includes(score)){
            if(regionalSelections[score].includes[item['regionName']]){
                const index = regionalSelections[score].indexOf(item['regionName'])
                regionalSelections.splice(index, 1);
            } else{
                regionalSelections[score].push(item['regionName'])
            }
        }
        this.displayFilterSelect()
        // console.log({regionalSelections})
        this.setState({ regionalSelections: regionalSelections})
    }

    displayAllRegionMenus = () => {
        // console.log(this.state)
        this.setState({allRegionMenues:
            this.state.regionalSelections.map((Item, index) =><>
            <Row key={index}>
                <Col className='col-12'>
                    <h3> Select Regions For {Item} </h3>
                    <Row>
                        <Col className='d-inline-block'>
                            <Row>
                                <Col className='col-8'>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="regionTypeSelect">
                                                Region Type
                                                </Label>
                                                <Input id="regionTypeSelect" onChange={e => this.SelectRegionType(e, Item)}   name="regionTypeSelect" type="select">
                                                    <option>
                                                        Select Region Type
                                                    </option>
                                                    <option>
                                                        State
                                                    </option>
                                                    <option>
                                                        County
                                                    </option>
                                                    <option>
                                                        Zip
                                                    </option>
                                                    {/* <option>
                                                        Custom
                                                    </option> */}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            {/* <FormGroup>
                                                <Label for="regionTypeSelect">
                                                Parent Region
                                                </Label>
                                                <Input id="regionTypeSelect" onChange={e => this.SelectRegionParent(e, Item)}  name="regionTypeSelect" type="select">
                                                    {this.state.displayparentRegionsSelect}
                                                </Input>
                                            </FormGroup> */}
                                            {this.state.selectedRegionTypeForm}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3>Selected Regions</h3>
                                            <div>
                                                { this.state.selectedRegionsBadges[Item]}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>
                                        <Col>
                                            {/* <FormGroup>
                                                <Label for="searchRegions">
                                                Search
                                                </Label>
                                                <Input
                                                id="searchRegions"
                                                name="search"
                                                placeholder="Region Search"
                                                type="search"
                                                onChange={ e => this.searchRegions(e, Item)}
                                                />
                                            </FormGroup> */}
                                            {this.state.selectedRegionFilterInput}
                                        </Col>
                                    </Row>
                                    <Row>
                                                        
                                        <Col className='h-50 d-inline-block overflow-auto'>
                                            {this.state.filteredRegions[Item]}
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>

                </Col>
            </Row>
            <br/>
            </>)
        })
        this.displayFilterSelect()

    }

    searchRegions = (event, item) => {
        let value = event.target.value
        // console.log({value})
        const tmpParent = this.state.regionParent
        const tmpRegionType = this.state.regionType
        let regionsbytype = tmpParent[item][tmpRegionType[item]]
        console.log({regionsbytype})
        let filteredRegions = this.state.filteredRegions
        const regionslist = regionsbytype.filter((region) => region['regionName'].toLowerCase().startsWith(event.target.value.toLowerCase()))
        // limit to 20 Items in the list
        this.setState({filteredRegionsData: regionslist})

        if(value != ""){
            this.displayFilteredRegionsLists(item, regionslist)
        }else{
            this.displayFilteredRegionsLists(item, [])
        }
        
    }

    displayFilteredRegionsLists = (item, regionsList) => {
        let filteredRegions = this.state.filteredRegions
        // let regionslist = this.state.filteredRegionsData
        filteredRegions[item] = regionsList.map((region, index) => <>
            <ListGroupItem key={index}>
                { this.displayRegionSelectButton(region, item) }
            </ListGroupItem>
        </>)
    
        this.setState({filteredRegions: filteredRegions})
        this.displayAllRegionMenus()
    }

    displayRegionSelectButton = (region, item) => {
        const isSelected = this.isRegionSelected(region, item)
        let btn = ''
        if(isSelected){
            btn = <div className='btn btn-md btn-danger' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>{region.regionName}</div>
        } else {
            btn = <div className='btn btn-md btn-primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>{region.regionName}</div>
        }
        return(btn)
    }

    displayBadges = (item) => {
        const selectedRegions = this.state.selectedRegions
        let selectedRegionsBadges = this.state.selectedRegionsBadges
        let badges = selectedRegions[item].map((region, index) => <>
            <Badge key={index} color='primary' onClick={ e => this.toggleFilteredRegionToSelectedRegions(region, item)}>
                <Row>
                    <Col>
                        {region['regionName']}
                    </Col>
                    <Col>
                        <i className="fas fa-window-close"></i>
                    </Col>
                </Row>
            </Badge>                
        
        </>)
        selectedRegionsBadges[item] = badges
        this.setState({ selectedRegionsBadges: selectedRegionsBadges })
        this.displayAllRegionMenus()
    }

    isRegionSelected = (region, item ) => {
        let isIncluded = false
        if(this.state.selectedRegions.hasOwnProperty(item)){
            this.state.selectedRegions[item].filter(function(fregion){
                if( fregion['id'] == region['id'] ){
                    isIncluded = true
                }
            })
        }
        return(isIncluded)
    }

    toggleFilteredRegionToSelectedRegions = (inputRegion, item) => {
        let selectedRegions = this.state.selectedRegions
        let region = inputRegion
        if(region['type'] =='State'){
            console.log("this is a state")
            region.subtype = this.state.subregionType
        }
        if( this.isRegionSelected(region, item)){
            selectedRegions[item] = selectedRegions[item].filter(function(fregion){
                if( fregion['id'] != region['id'] ){
                    return(region)
                }
            })
        } else {
            if(selectedRegions.hasOwnProperty(item)){

                // if(region['type'] =='State'){
                //     // console.log("this is a state")
                //     region.subtype = this.state.subregionType
                // }
                // console.log({region})
                // // console.log(this.state.subregionType)
                // // console.log(region.type)


                selectedRegions[item].push(region)
            } else{
                selectedRegions[item] = [region]
            }
        }
        // console.log(selectedRegions)
        this.setState({ selectedRegions: selectedRegions})
        this.displayBadges(item)
        this.displayFilteredRegionsLists(item, this.state.filteredRegionsData)
    }

    SelectRegionType = (event, Item) => {
        console.log(event.target.value)
        console.log({Item})
        let tmpType = this.state.regionType
        tmpType[Item] = event.target.value
        this.setState({regionType: tmpType})
        // console.log(this.state.ActiveStates)


        


        if(event.target.value == 'County' | event.target.value == 'Zip' | event.target.value == 'Custom'){
            this.setState({ selectedRegionTypeForm: <>
                <Col>
                    <FormGroup>
                        <Label for="regionTypeSelect">
                        Parent Region
                        </Label>
                        <Input id="regionTypeSelect" onChange={e => this.SelectRegionParent(e, Item)}  name="regionTypeSelect" type="select">
                            {this.state.displayparentRegionsSelect}
                        </Input>
                    </FormGroup>
                </Col>
            </>})

            this.setState({ selectedRegionFilterInput: <>
                <FormGroup>
                    <Label for="searchRegions">
                    Search
                    </Label>
                    <Input
                    id="searchRegions"
                    name="search"
                    placeholder="Region Search"
                    type="search"
                    onChange={ e => this.searchRegions(e, Item)}
                    />
                </FormGroup>            
            </>})
        } else if(event.target.value == 'State'){
            this.setState({filteredRegionsData: this.state.ActiveStates})

            this.displayFilteredRegionsLists(Item, this.state.ActiveStates)

            this.setState({ selectedRegionTypeForm: <>
                <FormGroup>
                    <Label for="subregionTypeSelect">
                    Sub-Region Type
                    </Label>
                    <Input id="subregionTypeSelect" onChange={e => this.SelectSubRegionType(e, Item)}   name="subregionTypeSelect" type="select">
                        <option>
                            County
                        </option>
                        <option>
                            Zip
                        </option>
                    </Input>
                </FormGroup>
            </>})
            this.setState({ selectedRegionFilterInput: <>

            </>})
        } else {
            this.setState({ selectedRegionTypeForm: <></>})
            this.setState({ selectedRegionFilterInput: <></>})
        }
    }


    SelectSubRegionType = (event, Item) => {
        console.log(event.target.value)
        console.log({Item})
        let tmpType = this.state.regionType
        tmpType[Item] = event.target.value
        this.setState({subregionType: tmpType})
        // console.log(this.state)

    }

    SelectRegionParent = (event, Item) => {
        console.log(event.target.value)
        // console.log({Item})
        let tmpParent = this.state.regionParent
        tmpParent[Item] = event.target.value
        this.setState({regionParent: tmpParent})
    }

    displayParentRegionSelect = () => {
        const regions = this.state.regions
        this.setState({displayparentRegionsSelect: regions.map((region, index) => <>
            <option key={index}>
                {region['regionName']}
            </option>
        </>)})
        this.displayAllRegionMenus()
    }

    updateQueryName = (event) => {
        console.log(event.target.value)
        this.setState({queryName: event.target.value})
    }

    updateTargetOrgName = (event) => {
        console.log(event.target.value)
        this.setState({targetOrgName: event.target.value})
    }


    updateQueryDescription = (event) => {
        console.log(event.target.value)
        this.setState({queryDescription: event.target.value})
    }

    togglePrimaryQuerySwitches = (event, action) => {
        // console.log(event.target.value)
        // console.log({action})
        let querySwitches = this.state.PrimaryQuerySwitches
        if(querySwitches.hasOwnProperty(action)){
            delete querySwitches[action] 
        } else{
            querySwitches[action] = true
        }
        // console.log({querySwitches})
        this.setState({PrimaryQuerySwitches: querySwitches})
        this.displayOrgNameSelect()
        this.displaySelfOrgUsers()
        this.displayScoreOptions()


    }

    getOrganizations = async() => {
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100,  }});
        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }

        // console.log({orgItems})
        this.setState({organizations: orgItems})
        this.getUsers()

    }

    getOrgNameByID = (orgid) => {
        const org = this.state.organizations.filter((org, index) => {
            return org['name'] = orgid
        })
        // console.log({org})
        if(org.length > 0){
            if(org[0].hasOwnProperty('name')){
                return(org['name'])
            } else{
                return ''
            }
        } else{
            return ''
        }
        
        // return( org[0])
    }

    getOrgIdByName = (orgname) => {
        // console.log({orgname})
        // console.log(this.state.organizations)
        const org = this.state.organizations.filter((org, index) => {
            if(org['name'] == orgname){
                return org
            }
            
        })
        // console.log({org})
        if(org.length > 0){
            if(org[0].hasOwnProperty('name')){
                return(org[0])
            } else{
                return ''
            }
        } else{
            return ''
        }
    }

    getUsers = async() => {
        let usersData = await API.graphql({ query: listUsers, variables: { limit: 100,  }});
        let userItems = usersData.data.listUsers.items;
        let pdata = usersData.data.listUsers
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = usersData.data.listUsers
            userItems = userItems.concat(pdata.items)
        }
        // console.log({userItems})
        this.setState({userstable: userItems})

        const token = await Auth.currentAuthenticatedUser()
        const selfuser = userItems.filter((user, index) => {
            if(user.username == token.username){
                return(user)
            }
        })
        // console.log({selfuser})
        this.setState({selfuser: selfuser[0]})
        this.setState({selfOrgUsers: [selfuser[0]['username']]})

        let nonSelfOrgs = this.state.organizations.map((org, index) => {
            if(org['name'] != selfuser[0]['org']){
                return(org)
            } else {
                return({})
            }
        })
        // console.log({nonSelfOrgs})
        // console.log()

        this.setState({orgOptions: nonSelfOrgs.map((org, index) =>  {
        if(org.hasOwnProperty('name')){
        return(<>
            <option>
                {org.name}
            </option>    
        </>)}})})
    }

    displayOrgNameSelect = () => {
        let querySwitches = this.state.PrimaryQuerySwitches
        if( querySwitches.hasOwnProperty('DeployToOrg')){
            this.setState({displayOrgSelect: <>
                <Input id="orgSelect" value={this.state.selectedTargetOrg} onChange={e => this.selectTargetOrganization(e)}  name="orgSelect" type="select">
                    <option key='-1'>
                        Select Target Org
                    </option>
                    {this.state.orgOptions}
                </Input>
                </>})
        } else{
            this.setState({displayOrgSelect: <></>})
        }
    }

    selectTargetOrganization = (event) => {
        // console.log(event.target.value)
        this.setState({ selectedTargetOrg: event.target.value })
        let org = this.getOrgIdByName(event.target.value)
        // console.log({orgid})
        this.setState({targetOrg: org })
        this.displayTargetOrgUsers(org['name'])
    }

    displayTargetOrgUsers = (orgid) => {
        let fusers = this.state.userstable.filter((user, index) => {
            if(user.org == orgid){
                return user
            }
        })
        const userSelect = fusers.map((user, index) => <>
        <Col className='sm-1' key={index}>
            <Input type="checkbox" value={this.state.targetOrgUsers.includes(user.username)} onChange={e => this.handleTargetUserSelect(e, user)}/>
            {' '}
            <Label check>{user.username}</Label>
        </Col>
        </>)

        this.setState({ displayTargetOrgUserSelections: <>
            <FormGroup>
                <Label for="targetOrgUsers">
                    Target Org Users
                </Label>

                {userSelect}
            </FormGroup>
        </>})
    }

    handleTargetUserSelect = (event, user) => {
        // console.log(event.target.value)
        // console.log({user})
        let tou = this.state.targetOrgUsers
        // console.log({tou})
        if(tou.includes(user.uername)){
            tou = tou.filter((tuser, index) => {
                if(tuser != user.username){
                    return(tuser)
                }
            })
        } else {
            tou.push(user.username)
        }
        // console.log({tou})
        this.setState({targetOrgUsers: tou})
    }


    handleSelfUserSelect = (event, user) => {
        // console.log(event.target.value)
        // console.log({user})
        let tou = this.state.selfOrgUsers
        // console.log({tou})
        if(tou.includes(user.username)){
            tou = tou.filter((tuser, index) => {
                if(tuser != user.username){
                    return(tuser)
                }
            })
        } else {
            tou.push(user.username)
        }
        // console.log({tou})
        this.setState({selfOrgUsers: tou})
    }

    displaySelfOrgUsers = () => {
        let querySwitches = this.state.PrimaryQuerySwitches
        const selfuser = this.state.selfuser
        // console.log({selfuser})
        if( querySwitches.hasOwnProperty('AddOrgUsers')){
            let fusers = this.state.userstable.filter((user, index) => {
                if(user.org == selfuser['org']){
                    return user
                }
            })
            fusers = fusers.filter((user, index) => {
                if(user['username'] != this.state.selfuser['username']){
                    return(user)
                }
            })
            const userSelect = fusers.map((user, index) => <>
            <Col className='sm-1' key={index}>
                <Input type="checkbox" value={this.state.selfOrgUsers.includes(user.username)} onChange={e => this.handleSelfUserSelect(e, user)}/>
                {' '}
                <Label check>{user.username}</Label>
            </Col>
            </>)
            this.setState({ displaySelfOrgUserSelections: <>
                <FormGroup>
                    <Label for="selfOrgUsers">
                        Self Org Users
                    </Label>
                    {userSelect}
                </FormGroup>
            </>})
        } else {
            this.setState({ displaySelfOrgUserSelections: <>
            </>})
        }
    }

    handleSumbit = async(state) => {
        // console.log({state})
        let inputs = {}
        inputs['status'] = 'Active'
        inputs['name'] = state.queryName
        inputs['description'] = state.queryDescription

        const querySwitches = state.PrimaryQuerySwitches
        console.log({querySwitches})
        // deal with current
        if(querySwitches.hasOwnProperty('Current')){
            inputs['current'] = true
            delete querySwitches['Current']
        } else {
            inputs['current'] = false
        }
        console.log({querySwitches})
        inputs['owners']  = state.selfOrgUsers

        let metadata = {}
        if(querySwitches.hasOwnProperty('DeployToOrg')){
            metadata['targetOrganization']  = state.targetOrg['name']
            metadata['targetUsers'] = state.targetOrgUsers
            delete querySwitches['DeployToOrg']
            metadata['deployedToTarget'] = false
        } else{
            metadata['deployedToTarget'] = true
        }
        if(querySwitches.hasOwnProperty('AddOrgUsers')){
            delete querySwitches['AddOrgUsers']
            metadata['AddOrgUsers'] = true
            metadata['selfOrgUsers'] = state.selfOrgUsers
        }

        let products = {}
        products['queryOptions'] = querySwitches
        products['RegionalScore'] = state.selectedRegions

        if(this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')){
            products['CustomQuery'] = state.customQueryselectArr
        } else{
            products['ColumnSelect'] = state.columnSelectValues
            products['ColumnGroup'] = state.columnGroupArr
            products['SingleScore'] = state.singleScoresArr
            products['FilterScore'] = state.filterSelect
            products['SQLWrapper'] = state.SQLWrapperArr

        }
        console.log({metadata})
        inputs['metadata'] = JSON.stringify(metadata)
        console.log({products})
        inputs['products'] = JSON.stringify(products)

        console.log({inputs})

        const newProductQuery = await API.graphql(graphqlOperation(createProductQuery, {input: inputs}));
        console.log({newProductQuery})

        window.location.reload();
    }

    displayFilterSelect = () => {
        const singleScores = this.state.singleScoresArr
        const regionalSelections = this.state.regionalSelections
        const selectedRegions = this.state.selectedRegions
        let allScores = singleScores
        let regionScores = []
        regionalSelections.map((select, index) => {
            if(select != 'Consumers'){
                if(selectedRegions.hasOwnProperty(select)){
                    selectedRegions[select].map((item, index) => {
                        regionScores.push(item['regionCol'])
                    })
                }
            }
        })
        allScores = singleScores.concat(regionScores).map(function(value){ 
            return value.toLowerCase();
        })
        // console.log({allScores})
        // allScores = ['Select Filter', ...allScores]

        if(allScores.length > 0){
            this.setState({filterSelectDropdown: 
                allScores.map((score, index) => <>
                    <option key={index}>
                        {score}
                    </option>
                </>)
            })
        } else {
            this.setState({filterSelectDropdown: []})
        }
    }
    selectFilter = (event) => {
        console.log(event.target.value)
        this.setState({ filterSelect: event.target.value })
    }




    render  () {


        return( 
            <Card>
                <CardHeader>
                    <CardTitle className="h2 mb-0">Make Product Query</CardTitle>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col className='col-6'>
                                <FormGroup>
                                    <Input
                                    id='QueryName'
                                    value={this.state.queryName}
                                    onChange={ e => this.updateQueryName(e)}
                                    placeholder='Query Name'
                                    />
                                </FormGroup>
                            </Col>
                            <Col className='col-6'>
                                <Input id="filterSelect" value={this.state.filterSelect} onChange={e => this.selectFilter(e)}  name="filterSelect" type="select">
                                    <option key='none'>
                                        Select Score Filter
                                    </option>
                                    {this.state.filterSelectDropdown}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup >
                                    <Input
                                        id="QueryDescription"
                                        name="text"
                                        type="textarea"
                                        placeholder='Query Description'
                                        onChange={e => this.updateQueryDescription(e)}
                                    />
                                </FormGroup>`
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-4'>
                                <h3>Query Options</h3>
                                <Row>
                                    {/* <Col className='col-12'>
                                        <FormGroup check>
                                            <Input type="checkbox" 
                                                onChange={ e => this.togglePrimaryQuerySwitches(e, 'AddOrgUsers')} 
                                                value={ this.state.PrimaryQuerySwitches.hasOwnProperty('AddOrgUsers')}
                                                />
                                            <Label check>
                                                AddOrgUsers
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col className='col-12'>
                                        <FormGroup check>
                                            <Input type="checkbox" 
                                            onChange={ e => this.togglePrimaryQuerySwitches(e, 'DeployToOrg')} 
                                            value={ this.state.PrimaryQuerySwitches.hasOwnProperty('DeployToOrg')}
                                            />
                                            <Label check>
                                                DeployToOrg
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col className='col-12'>
                                        <FormGroup check>
                                            <Input type="checkbox" onChange={ e => this.togglePrimaryQuerySwitches(e, 'Current')} 
                                            value={ this.state.PrimaryQuerySwitches.hasOwnProperty('Current')} />
                                            <Label check>
                                                Current
                                            </Label>
                                        </FormGroup>
                                    </Col> */}
                                    <Col className='col-12'>
                                        <FormGroup check>
                                            <Input type="checkbox" onChange={ e => this.togglePrimaryQuerySwitches(e, 'GroupByRegion')} 
                                            value={ this.state.PrimaryQuerySwitches.hasOwnProperty('GroupByRegion')} />
                                            <Label check>
                                                GroupByRegion
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col className='col-12'>
                                        <FormGroup check>
                                            <Input type="checkbox" onChange={ e => this.togglePrimaryQuerySwitches(e, 'UseCustomQuery')} 
                                            value={ this.state.PrimaryQuerySwitches.hasOwnProperty('UseCustomQuery')} />
                                            <Label check>
                                                Custom Query
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                                {this.state.sqlwrappers}
                            </Col>
                            <Col className='col-4'>
                            {this.state.singleScores}
                            <br />
                            {this.state.regionalScores}

                            </Col>
                            <Col className='col-4'>
                                {this.state.columnGroups}
                                <br />
                                {this.state.columnSelections}
                                <br />
                                {this.state.CustomQuerydisplay}
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                {this.state.displayOrgSelect}
                            </Col>
                            <Col>
                                {this.state.displayTargetOrgUserSelections}
                            </Col>
                            <Col>{this.state.displaySelfOrgUserSelections}</Col>

                        </Row>
                        <br />
                        {this.state.allColumnSelectMenus}
                        <br />
                        {this.state.allRegionMenues}
                        <Button onClick={e => this.handleSumbit(this.state)} className="btn">
                            Submit
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default ProductQueryMaker