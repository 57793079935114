import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createProduct } from '../graphql/mutations'
import { listProducts } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button, 
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";


class NewProductForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            products: [],
            modals: [],
            selectedProductData: {},
            displaySelectedProductData: <></>,
            displayNameInput: <></>,
            displayRankInput: <></>,
            displayTypeInput: <></>,
            displaySQLInput: <></>,
            displayDatabaseNameInput: <></>,
            displayDatabaseNameInput: <></>,
            displayProductColsInput: <></>,
            displaySubmitBtn: <></>,
            productEditInputs: [],
            SelectedProductName: '',
            SelectedProductRank: '',
            SelectedProductType: '',
            SelectedProductDatabase: '',
            SelectedProductTable: '',
            SelectedProductTableCols: '',
            SelectedProductProductCols: '',
            SelectedProductSQL: '',
            prevState: '',
        }
        this.getProducts()
        this.displayProductData()
    }

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        // console.log('New state', this.state.SecondaryTypeOpen); // After update 
        console.log("componentDidUpdate")
        console.log(this.state.productEditInputs)
        console.log( prevState.productEditInputs )
        if(prevState.selectedProductData !== this.state.selectedProductData){
            // this.displayProductData()
        } 
        if(prevState.SelectedProductName !== this.state.SelectedProductName){
            this.displayNameInput()
        } 
        if(prevState.SelectedProductRank !== this.state.SelectedProductRank){
            this.displayRankInput()
        } 
        if(prevState.productEditInputs !== this.state.productEditInputs){
            // this.displayProductData()
        } 
        if(prevState.SelectedProductType !== this.state.SelectedProductType){
            this.displayProductData()
        } 
        if(prevState.SelectedProductSQL !== this.state.SelectedProductSQL){
            this.displaySQLInput()
        } 
        if(prevState.SelectedProductDatabase !== this.state.SelectedProductDatabase){
            this.displayDatabaseNameInput()
        } 
        if(prevState.SelectedProductTable !== this.state.SelectedProductTable){
            this.displayTableNameInput()
        } 
        if(prevState.SelectedProductProductCols !== this.state.SelectedProductProductCols){
            this.displayProductColsInput()
        } 
    }

    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
        }
    };

    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
        this.setState({productEditInputs: []})
    }

    getProducts = async() => {
        let productData = await API.graphql({ query: listProducts, variables: { limit: 100,  }});
        let productItems = productData.data.listProducts.items;
        let pdata = productData.data.listProducts
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProducts, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = productData.data.listProducts
            productItems = productItems.concat(pdata.items)
        }
        // console.log({productItems})
        this.setState({products: productItems})
    }

    updateFormNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductName: event.target.value})
    }

    updateFormRankInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductRank: event.target.value})
    }

    updateFormTypeInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductType: event.target.value})
    }

    updateFormSQLInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductSQL: event.target.value})
    }

    
    updateFormDatabaseNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductDatabase: event.target.value})
    }

    updateFormProductColsInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductProductCols: event.target.value})
    }
    updateFormTableNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductTable: event.target.value})
    }


    displayNameInput = () => {
            this.setState({displayNameInput: <>
                <tr>
                    <td> <h3>Name: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductName}
                            onChange={e => this.updateFormNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
    }

    displayRankInput = () => {
            this.setState({displayRankInput: <>
                <tr>
                    <td><h3>Rank: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductRank}
                            onChange={e => this.updateFormRankInput( e )}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
        
    }
    
    displayTypeInput = () => {
            this.setState({displayTypeInput: <>
                <tr>
                    <td> <h3>Type: </h3></td>
                    <td>
                    <FormGroup>
                                <Input
                                id="SelectType"
                                placeholder="Select Type"
                                name="typeSelect"
                                type="select"
                                value={this.state.SelectedProductType}
                                disabled
                                >
                                    <option>
                                        SingleScore
                                    </option>
                                    <option>
                                        RegionalScore
                                    </option>
                                    <option>
                                        ColumnGroup
                                    </option>
                                    <option>
                                        ColumnSelect
                                    </option>
                                    <option>
                                        CustomQuery
                                    </option>
                                    <option>
                                        SQLWrapper
                                    </option>
                                    <option>
                                        DataFrameOperation
                                    </option>
                                </Input>
                        </FormGroup>
                    </td>
                </tr>
            </>})
    }

    displaySQLInput = () => {
            this.setState({displaySQLInput: <>
                <tr>
                    <td><h3>SQL: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="sqlString"
                                name="sqlString"
                                type="textarea"
                                value={this.state.SelectedProductSQL} 
                                onChange={this.updateFormSQLInput}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
    }

    displayDatabaseNameInput = () => {
            this.setState({displayDatabaseNameInput: <>
                <tr>
                    <td> <h3>Database: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductDatabase}
                            onChange={e => this.updateFormDatabaseNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
        
    }
    displayTableNameInput = () => {
            this.setState({displayTableNameInput: <>
                <tr>
                    <td> <h3>Table: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductTable}
                            onChange={e => this.updateFormTableNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
        
    }

    displayProductColsInput = () => {
            this.setState({displayProductColsInput: <>
                <tr>
                    <td><h3>Table Columns: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="productColNamesString"
                                name="productColNames"
                                type="textarea"
                                value={this.state.SelectedProductProductCols} 
                                onChange={this.updateFormProductColsInput}
                            />
                        </FormGroup>
                    </td>
                </tr>
            </>})
        
    }

    displayProductData = () => {

        if(this.state.SelectedProductType == "ColumnGroup"){
            this.setState({displaySQLInput: <></>})
            this.displayDatabaseNameInput()
            this.displayTableNameInput()
            this.displayProductColsInput()
        } else if(this.state.SelectedProductType == "SingleScore"){
            this.setState({displaySQLInput: <></>})
            this.displayDatabaseNameInput()
            this.displayTableNameInput()
            this.displayProductColsInput()
        } else if(this.state.SelectedProductType == "RegionalScore"){
            this.setState({displaySQLInput: <></>})
            this.displayDatabaseNameInput()
            this.displayTableNameInput()
            this.displayProductColsInput()

        } else if(this.state.SelectedProductType == "ColumnSelect"){
            this.setState({displaySQLInput: <></>})
            this.displayDatabaseNameInput()
            this.displayTableNameInput()
            this.displayProductColsInput()

        }  else if( this.state.SelectedProductType == "SQLWrapper"){
            this.setState({displayDatabaseNameInput: <></>})
            this.setState({displayTableNameInput: <></>})
            this.setState({displayProductColsInput: <></>})
            this.displaySQLInput()

        } else if( this.state.SelectedProductType == "CustomQuery"){
            this.setState({displayDatabaseNameInput: <></>})
            this.setState({displayTableNameInput: <></>})
            this.setState({displayProductColsInput: <></>})
            this.displaySQLInput()
        } else {
            this.setState({displayDatabaseNameInput: <></>})
            this.setState({displayTableNameInput: <></>})
            this.setState({displayProductColsInput: <></>})
            this.setState({displaySQLInput: <></>})
        }

    }



    handleSumbit = async(state) => {

        console.log(state)
        // console.log(state.selectedProductData.id)
        // console.log(state.productEditInputs)

        let inputs = {}
        inputs['name'] = state.SelectedProductName
        inputs['type'] = state.SelectedProductType
        inputs['rank'] = state.products.length

        let metadata = {}

        if(this.state.SelectedProductType == "ColumnGroup"){
            metadata['database'] = state.SelectedProductDatabase
            metadata['table'] = state.SelectedProductTable
            metadata['colnames'] = state.SelectedProductProductCols
        } else if(this.state.SelectedProductType == "SingleScore"){
            metadata['database'] = state.SelectedProductDatabase
            metadata['table'] = state.SelectedProductTable
            metadata['colnames'] = state.SelectedProductProductCols
        } else if(this.state.SelectedProductType == "RegionalScore"){
            metadata['database'] = state.SelectedProductDatabase
            metadata['table'] = state.SelectedProductTable
            metadata['colnames'] = state.SelectedProductProductCols
        } else if( this.state.SelectedProductType == "SQLWrapper"){
            metadata['sql'] = state.SelectedProductSQL
        } else if( this.state.SelectedProductType == "CustomQuery"){
            metadata['sql'] = state.SelectedProductSQL
        } 

        console.log({inputs})
        console.log({metadata})

        inputs['metadata'] = JSON.stringify(metadata)

        const newProduct = await API.graphql(graphqlOperation(createProduct, {input: inputs}));
        console.log({newProduct})

    }

    render  () {


        return( <>

            <Row>
                <Col className='col-10'>
                    <CardTitle className="h2 mb-0">New Product Form</CardTitle>
                </Col>
            </Row>
            <br />
            <Form>
                <Table className='table table-sm'>
                    <tbody>
                        <tr>
                            <td> <h3>Name: </h3></td>
                            <td>
                                <FormGroup>
                                    <Input
                                    id='productName'
                                    value={this.state.SelectedProductName}
                                    onChange={e => this.updateFormNameInput( e )}
                                    />
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td> <h3>Type: </h3></td>
                            <td>
                            <FormGroup>
                                        <Input
                                            id="SelectType"
                                            placeholder="Select Type"
                                            name="typeSelect"
                                            type="select"
                                            onChange={ e => this.updateFormTypeInput(e)}
                                            value={this.state.SelectedProductType}
                                        >
                                            <option>
                                                Select Type
                                            </option>
                                            <option>
                                                SingleScore
                                            </option>
                                            <option>
                                                RegionalScore
                                            </option>
                                            <option>
                                                ColumnGroup
                                            </option>
                                            <option>
                                                ColumnSelect
                                            </option>
                                            <option>
                                                CustomQuery
                                            </option>
                                            <option>
                                                SQLWrapper
                                            </option>
                                            {/* <option>
                                                DataFrameOperation
                                            </option> */}
                                        </Input>
                                </FormGroup>
                            </td>
                        </tr>
                        {this.state.displaySQLInput}
                        {this.state.displayDatabaseNameInput}
                        {this.state.displayTableNameInput}
                        {this.state.displayProductColsInput}
                    </tbody>
                </Table>
                
                <Button onClick={e => this.handleSumbit(this.state)} className="btn">
                    Submit
                </Button>
            </Form>
        </>
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default NewProductForm