import React from 'react'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Table, 
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";

import { createRegindex, updateRegindex, deleteRegindex } from '../graphql/mutations'
// import {  } from '../graphql/subscriptions'
import { getRegindex, listRegindices } from '../graphql/queries'
import classnames from "classnames";

import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'


class Registry extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            listkeytabledata: <></>,
            selectedkeyname: '',
            selectedvalue: '',
            modals: [],
            selectedRegItem: {}

        }
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
        this.getIndexValues()
    }

    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        // this.getActiveRegions()
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
                
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            
            
        }
    };

    editRegItemModal = (regItem) => {
        console.log({regItem})
        this.setState({selectedRegItem: regItem})
        this.setState({selectedvalue: regItem['value']})
        this.toggleModals('EditIndexQuery')
    }

    getIndexValues = async() => {
        let indexData = await API.graphql({ query: listRegindices, variables: { limit: 100  }});
        let indexItems = indexData.data.listRegindices.items;
        let pdata = indexData.data.listRegindices
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let indexData = await API.graphql({ query: listRegindices, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = indexData.data.listRegindices
            indexItems = indexItems.concat(indexData.data.listRegindices.items)
        }
        // console.log(indexItems)
        // this.setState({Indexs: indexItems})


        this.setState({ listkeytabledata: indexItems.map( (item, index) => <> 
            <tr>
                <td>
                    { item['key'] }
                </td>
                <td>
                    { item['value'] }
                </td>
                <td>
                    <Button className='btn-sm' onClick={ e => this.editRegItemModal(item)} ><i className="fa-solid fa-pen-to-square"/></Button>
                </td>
            </tr>
        </>) })
    }

    handleNewSumbit = async(data) => {    
        const token = await Auth.currentAuthenticatedUser()
        let inputs = {
            key: data['selectedkeyname'],
            value: data['selectedvalue']
        }
        this.setState({OrgName: ''})
        const newRegKey = await API.graphql(graphqlOperation(createRegindex, {input: inputs}));
        console.log({newRegKey})
        window.location.reload();
    }

    handleEditSumbit = async(data) => {
        console.log(data)
        const token = await Auth.currentAuthenticatedUser()
        let inputs = {
            key: data['selectedRegItem']['key'],
            value: data['selectedvalue']
        }
        this.setState({OrgName: ''})
        const newRegKey = await API.graphql(graphqlOperation(updateRegindex, {input: inputs}));
        console.log({newRegKey})
        window.location.reload();
    }

    handlekeynamechange = ( e ) => {
        console.log(e.target.value)
        this.setState({selectedkeyname: e.target.value})
    }

    handlevaluechange = (e) => {
        console.log(e.target.value)
        this.setState({selectedvalue: e.target.value})
    }

    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
    }

    deleteRegindex = async(reg) => {
        console.log(reg['key'])
        const inputs = {
            key: reg['key']
        }
        const response = await API.graphql(graphqlOperation(deleteRegindex, {input: inputs}));
        console.log(response)
        window.location.reload();
    }

    render  () {
        return( <>
            <Modal backdrop="static" isOpen={this.state.modals.includes('EditIndexQuery')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('EditIndexQuery')}>×</button>}>Edit Product Query</ModalHeader>
                <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input
                                    id="key"
                                    name="key"
                                    placeholder="Key"
                                    value={this.state.selectedRegItem['key']} 
                                    />
                                </Col>
                                <Col sm={7}>
                                    <Input
                                    id="value"
                                    name="value"
                                    placeholder="Value"
                                    value={this.state.selectedvalue} 
                                    onChange={this.handlevaluechange}
                                    type="textarea"
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ e => this.handleEditSumbit(this.state) }>Submit</Button>
                    <Button color="danger" onDoubleClick={ e => this.deleteRegindex(this.state.selectedRegItem) }>Delete</Button>
                    <Button color="secondary" onClick={ e => this.toggleModals('EditIndexQuery') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('NewIndexQuery')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('NewIndexQuery')}>×</button>}>Edit Product Query</ModalHeader>
                <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Input
                                    id="key"
                                    name="key"
                                    placeholder="Key"
                                    value={this.state.selectedkeyname} 
                                    onChange={this.handlekeynamechange}
                                    />
                                </Col>
                                <Col sm={7}>
                                    <Input
                                    id="value"
                                    name="value"
                                    placeholder="Value"
                                    value={this.state.selectedvalue} 
                                    onChange={this.handlevaluechange}
                                    type="textarea"
                                    />
                                </Col>
                            </FormGroup>
                            <Button onClick={e => this.handleNewSumbit(this.state)} className="btn">
                                Submit
                            </Button>
                        </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('NewIndexQuery') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("reg")}
                aria-expanded={this.state.openedCollapses.includes("reg")}>
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Site Registry</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("reg")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("reg")}>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Key </th>
                                    <th>Value </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listkeytabledata}
                            </tbody>
                        </Table>
                        <br />
                        <Button className='btn-sm btn-default' onClick={ e => this.toggleModals('NewIndexQuery')}>New</Button>
                    </CardBody>
                </Collapse>
            </Card>
        </>)
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default Registry;