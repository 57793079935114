import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { listProducts } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button
  } from "reactstrap";


class ListProducts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            products: []
        }
        this.getProducts()

    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
    }


    


    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
        }
    };

    getProducts = async() => {
        let productData = await API.graphql({ query: listProducts, variables: { limit: 100,  }});
        let productItems = productData.data.listProducts.items;
        let pdata = productData.data.listProducts
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProducts, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = productData.data.listProducts
            productItems = productItems.concat(pdata.items)
        }

        console.log({productItems})
        this.setState({products: productItems})


        this.setState({productsTable: productItems.map((ct, index) => (
                    <tr key={ct.id ? ct.id : index}
                        aria-selected={this.state.reqNavPills === index}
                        className={classnames("table-hover mb-sm-3 mb-md-0", {
                            active: this.state.reqNavPills === index
                        })}
                        key={index}
                        role="tab"
                        >
                        <td>{ct.name}</td>
                        <td>{ct.type}</td>

                    </tr>
            ))
        });
    }








    render  () {


        return( <>

            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("ComputeTemplate")}
                aria-expanded={this.state.openedCollapses.includes("ComputeTemplate")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">List Products</CardTitle>
                        </Col>
                        <Col className='col-2' >
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("ComputeTemplate")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("ComputeTemplate")}>
                    <CardBody>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.productsTable}

                            </tbody>
                        </Table>

                    </CardBody>
                </Collapse>
            </Card>
            </>
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default ListProducts