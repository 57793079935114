import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
// import { ListProductQuery } from '../productQuery'
import { Reports } from '../reports'
import { UserVNav } from '../navs'
import { Maintenance } from './index'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { getRegindex } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button
  } from "reactstrap";


class Home extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            page: '',
            MaintenanceMode: false

        }

    }
    componentDidMount = () => {
        this.getMaintenanceMode()


    }

    getMaintenanceMode = async() => {
        const dlData = await API.graphql({ query: getRegindex, variables: { key: 'maintenanceMode' }});
        // console.log(dlData['data']['getRegindex']['value'] == 'True' )
        this.setState({ MaintenanceMode: dlData['data']['getRegindex']['value'] == 'True' })
        this.getUserGroups()

    }


    getUserGroups = async() => {
        const token = await Auth.currentAuthenticatedUser()
        if(token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Users')){
            if(this.state.MaintenanceMode ){
                if(token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Admins')){
                    this.setState({ page:  <>
                        {/* <UserVNav /> */}
                        <div className='vbox'>
                            <Reports />
                        </div>
                    </>})
                } else {
                    this.setState({ page:  <>
                        <div className='g-0'>
                            <Maintenance />
                        </div>
                    </>})    
                }
                
            } else {
                this.setState({ page:  <>
                    <div className='g-0 d-none d-xs-none d-sm-none d-md-block '>
                        {/* <UserVNav /> */}
                        <div className='vbox'>
                            <Reports />
                        </div>
                    </div>
                    <div className=' d-lg-none d-xl-none d-lg-none d-md-none'>
                        {/* <UserVNav /> */}
                        <Reports />
                    </div>
                </>})
            }
        } else {
            this.setState({ page:  <>
                <h3>Not Authorized</h3>
            </>})
        }
    }
    
    render  () {
        return(<>
            {this.state.page}
        </>)
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default Home