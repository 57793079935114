/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        regionName
        type
        parentRegion
        parentType
        lat
        lng
        population
        status
        regionCol
        subRegions
        owners
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const regionsByType = /* GraphQL */ `
  query RegionsByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelregionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        regionName
        type
        parentRegion
        parentType
        lat
        lng
        population
        status
        regionCol
        subRegions
        owners
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const regionsByParentRegion = /* GraphQL */ `
  query RegionsByParentRegion(
    $parentRegion: String!
    $sortDirection: ModelSortDirection
    $filter: ModelregionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionsByParentRegion(
      parentRegion: $parentRegion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        regionName
        type
        parentRegion
        parentType
        lat
        lng
        population
        status
        regionCol
        subRegions
        owners
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const regionsByParentRegionandType = /* GraphQL */ `
  query RegionsByParentRegionandType(
    $parentRegion: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelregionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionsByParentRegionandType(
      parentRegion: $parentRegion
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        regionName
        type
        parentRegion
        parentType
        lat
        lng
        population
        status
        regionCol
        subRegions
        owners
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const regionsByStatus = /* GraphQL */ `
  query RegionsByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelregionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        regionName
        type
        parentRegion
        parentType
        lat
        lng
        population
        status
        regionCol
        subRegions
        owners
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        rank
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDataVersion = /* GraphQL */ `
  query GetDataVersion($version: ID!) {
    getDataVersion(version: $version) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listDataVersions = /* GraphQL */ `
  query ListDataVersions(
    $version: ID
    $filter: ModelDataVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataVersions(
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dataVersionByStatus = /* GraphQL */ `
  query DataVersionByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModeldataVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataVersionByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegindex = /* GraphQL */ `
  query GetRegindex($key: ID!) {
    getRegindex(key: $key) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const listRegindices = /* GraphQL */ `
  query ListRegindices(
    $key: ID
    $filter: ModelRegindexFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegindices(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $id: ID
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        key
        owners
        status
        metadata
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fileByStatus = /* GraphQL */ `
  query FileByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        owners
        status
        metadata
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($name: ID!) {
    getOrganization(name: $name) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $name: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orgByStatus = /* GraphQL */ `
  query OrgByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelorganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($username: ID!) {
    getUser(username: $username) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $username: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByOrg = /* GraphQL */ `
  query UsersByOrg(
    $org: ID!
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOrg(
      org: $org
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByStatus = /* GraphQL */ `
  query UsersByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductQuery = /* GraphQL */ `
  query GetProductQuery($id: ID!) {
    getProductQuery(id: $id) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const listProductQueries = /* GraphQL */ `
  query ListProductQueries(
    $filter: ModelProductQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductQueries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryByStatus = /* GraphQL */ `
  query ProductQueryByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryByOrg = /* GraphQL */ `
  query ProductQueryByOrg(
    $org: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryByOrg(
      org: $org
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductQueryHistory = /* GraphQL */ `
  query GetProductQueryHistory($id: ID!) {
    getProductQueryHistory(id: $id) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const listProductQueryHistories = /* GraphQL */ `
  query ListProductQueryHistories(
    $filter: ModelProductQueryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductQueryHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryHistorysByproductQuery = /* GraphQL */ `
  query ProductQueryHistorysByproductQuery(
    $productQueryid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryHistorysByproductQuery(
      productQueryid: $productQueryid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryHistorysByStatus = /* GraphQL */ `
  query ProductQueryHistorysByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryHistorysByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryHistorysByDataVersion = /* GraphQL */ `
  query ProductQueryHistorysByDataVersion(
    $dataVersion: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryHistorysByDataVersion(
      dataVersion: $dataVersion
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productQueryHistorysBySourceQuery = /* GraphQL */ `
  query ProductQueryHistorysBySourceQuery(
    $sourceid: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelproductQueryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productQueryHistorysBySourceQuery(
      sourceid: $sourceid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduledQuery = /* GraphQL */ `
  query GetScheduledQuery($id: ID!) {
    getScheduledQuery(id: $id) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const listScheduledQueries = /* GraphQL */ `
  query ListScheduledQueries(
    $id: ID
    $filter: ModelScheduledQueryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScheduledQueries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        adminProductQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        scheduledDeliveryDates
        deliveredResults {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        metadata
        status
        contractid
        contract {
          id
          files
          metadata
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        orgContractScheduledQueriesId
      }
      nextToken
    }
  }
`;
export const scheduleQueryByStatus = /* GraphQL */ `
  query ScheduleQueryByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelscheduledQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleQueryByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminProductQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        scheduledDeliveryDates
        deliveredResults {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        metadata
        status
        contractid
        contract {
          id
          files
          metadata
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        orgContractScheduledQueriesId
      }
      nextToken
    }
  }
`;
export const scheduledQueryByContract = /* GraphQL */ `
  query ScheduledQueryByContract(
    $contractid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelscheduledQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduledQueryByContract(
      contractid: $contractid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminProductQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        scheduledDeliveryDates
        deliveredResults {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        metadata
        status
        contractid
        contract {
          id
          files
          metadata
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        orgContractScheduledQueriesId
      }
      nextToken
    }
  }
`;
export const getOrgContract = /* GraphQL */ `
  query GetOrgContract($id: ID!) {
    getOrgContract(id: $id) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const listOrgContracts = /* GraphQL */ `
  query ListOrgContracts(
    $id: ID
    $filter: ModelOrgContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrgContracts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contractByStatus = /* GraphQL */ `
  query ContractByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelorgContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeceasedReport = /* GraphQL */ `
  query GetDeceasedReport($id: ID!) {
    getDeceasedReport(id: $id) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const listDeceasedReports = /* GraphQL */ `
  query ListDeceasedReports(
    $filter: ModelDeceasedReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeceasedReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pid
        fname
        lname
        org
        user
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDownload = /* GraphQL */ `
  query GetDownload($id: ID!) {
    getDownload(id: $id) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
export const listDownloads = /* GraphQL */ `
  query ListDownloads(
    $filter: ModelDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productQueryid
        productQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        productQueryHistoryid
        productQueryHistory {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        userid
        user {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        createdAt
        statusCode
        status
        size
        updatedAt
      }
      nextToken
    }
  }
`;
export const downloadsByproductQuery = /* GraphQL */ `
  query DownloadsByproductQuery(
    $productQueryid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    downloadsByproductQuery(
      productQueryid: $productQueryid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        productQueryHistoryid
        productQueryHistory {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        userid
        user {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        createdAt
        statusCode
        status
        size
        updatedAt
      }
      nextToken
    }
  }
`;
export const downloadsByproductQueryHistory = /* GraphQL */ `
  query DownloadsByproductQueryHistory(
    $productQueryHistoryid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    downloadsByproductQueryHistory(
      productQueryHistoryid: $productQueryHistoryid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        productQueryHistoryid
        productQueryHistory {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        userid
        user {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        createdAt
        statusCode
        status
        size
        updatedAt
      }
      nextToken
    }
  }
`;
export const downloadByStatus = /* GraphQL */ `
  query DownloadByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModeldownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    downloadByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productQueryid
        productQuery {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        productQueryHistoryid
        productQueryHistory {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        userid
        user {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        createdAt
        statusCode
        status
        size
        updatedAt
      }
      nextToken
    }
  }
`;
