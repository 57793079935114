import React from 'react'
// import ReactDOM from 'react-dom';
import { listDataVersions } from '../graphql/queries.js'
import { createDataVersion } from '../graphql/mutations.js'
import { API, graphqlOperation } from 'aws-amplify'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'

import { Button } from "reactstrap";


  



class NewDataVersionBtn extends React.Component{
    onButtonClick = () => {
        // this.createNewDataVersion()
        this.updateDataVersion()
        // this.updateQueryState()

    }

    // this works perfectly do not replace
    // createNewDataVersion = async(newVersion) => {
    //     const values = {
    //         version: String(newVersion),
    //         status: 'New',
    //     }
        
    //     console.log('Submitted Values', values)
    //     const newDVResponse = await API.graphql(graphqlOperation(createDataVersion, {input: values}))
    //     console.log(newDVResponse)
    // }

    // deactivateDataVersion = async(currentDataVersionID) => {
    //     const values = {
    //         id: currentDataVersionID,
    //         status: 'Archived'
    //     }
    //     const updatedReq = await API.graphql({ query: updateDataVersion, variables: {input: values}});
    // }

    updateDataVersion = async() => {
        let DataVer = await API.graphql(graphqlOperation(listDataVersions))
        console.log({DataVer})
        let values = {}
        if(DataVer.data.listDataVersions.items.length > 0){
            let dataVers = DataVer.data.listDataVersions.items
            console.log(dataVers)
            let DataVersion = [...dataVers.filter( dataVers => dataVers.status === 'Active' )]
        console.log(DataVersion)
            const newVersion = String(parseInt(DataVersion[0].version)  + 1)
            // this.deactivateDataVersion(DataVersion[0].id) // unsure if needed
            values = {
                version: newVersion,
                status: 'New',
            }
        } else {
            values = {
                version: '0',
                status: 'Active',
            }
        }
        
        console.log('Submitted Values', values)
        const newDVResponse = await API.graphql(graphqlOperation(createDataVersion, {input: values}))
        console.log(newDVResponse)
    }

    // getDataVersion = async() => {
    //     let DataVer = await API.graphql(graphqlOperation(listDataVersions))
    //     let dataVers = DataVer.data.listDataVersions.items
    //     let DataVersion = [...dataVers.filter( dataVers => dataVers.status === 'Active' )]
    //     console.log(DataVersion[0].version)
    //     return(DataVersion[0].version)
    // }
    
    render() {
        return(
            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>NewDataVersion</Button>
        )
    }
    

}

export default (NewDataVersionBtn);