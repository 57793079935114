/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRegion = /* GraphQL */ `
  subscription OnCreateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onCreateRegion(filter: $filter) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRegion = /* GraphQL */ `
  subscription OnUpdateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onUpdateRegion(filter: $filter) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRegion = /* GraphQL */ `
  subscription OnDeleteRegion($filter: ModelSubscriptionRegionFilterInput) {
    onDeleteRegion(filter: $filter) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
    onCreateProduct(filter: $filter) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
    onUpdateProduct(filter: $filter) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
    onDeleteProduct(filter: $filter) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDataVersion = /* GraphQL */ `
  subscription OnCreateDataVersion(
    $filter: ModelSubscriptionDataVersionFilterInput
  ) {
    onCreateDataVersion(filter: $filter) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDataVersion = /* GraphQL */ `
  subscription OnUpdateDataVersion(
    $filter: ModelSubscriptionDataVersionFilterInput
  ) {
    onUpdateDataVersion(filter: $filter) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDataVersion = /* GraphQL */ `
  subscription OnDeleteDataVersion(
    $filter: ModelSubscriptionDataVersionFilterInput
  ) {
    onDeleteDataVersion(filter: $filter) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRegindex = /* GraphQL */ `
  subscription OnCreateRegindex($filter: ModelSubscriptionRegindexFilterInput) {
    onCreateRegindex(filter: $filter) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRegindex = /* GraphQL */ `
  subscription OnUpdateRegindex($filter: ModelSubscriptionRegindexFilterInput) {
    onUpdateRegindex(filter: $filter) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRegindex = /* GraphQL */ `
  subscription OnDeleteRegindex($filter: ModelSubscriptionRegindexFilterInput) {
    onDeleteRegindex(filter: $filter) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile($filter: ModelSubscriptionFileFilterInput) {
    onCreateFile(filter: $filter) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile($filter: ModelSubscriptionFileFilterInput) {
    onUpdateFile(filter: $filter) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile($filter: ModelSubscriptionFileFilterInput) {
    onDeleteFile(filter: $filter) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductQuery = /* GraphQL */ `
  subscription OnCreateProductQuery(
    $filter: ModelSubscriptionProductQueryFilterInput
  ) {
    onCreateProductQuery(filter: $filter) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductQuery = /* GraphQL */ `
  subscription OnUpdateProductQuery(
    $filter: ModelSubscriptionProductQueryFilterInput
  ) {
    onUpdateProductQuery(filter: $filter) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductQuery = /* GraphQL */ `
  subscription OnDeleteProductQuery(
    $filter: ModelSubscriptionProductQueryFilterInput
  ) {
    onDeleteProductQuery(filter: $filter) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductQueryHistory = /* GraphQL */ `
  subscription OnCreateProductQueryHistory(
    $filter: ModelSubscriptionProductQueryHistoryFilterInput
  ) {
    onCreateProductQueryHistory(filter: $filter) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductQueryHistory = /* GraphQL */ `
  subscription OnUpdateProductQueryHistory(
    $filter: ModelSubscriptionProductQueryHistoryFilterInput
  ) {
    onUpdateProductQueryHistory(filter: $filter) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductQueryHistory = /* GraphQL */ `
  subscription OnDeleteProductQueryHistory(
    $filter: ModelSubscriptionProductQueryHistoryFilterInput
  ) {
    onDeleteProductQueryHistory(filter: $filter) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScheduledQuery = /* GraphQL */ `
  subscription OnCreateScheduledQuery(
    $filter: ModelSubscriptionScheduledQueryFilterInput
  ) {
    onCreateScheduledQuery(filter: $filter) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const onUpdateScheduledQuery = /* GraphQL */ `
  subscription OnUpdateScheduledQuery(
    $filter: ModelSubscriptionScheduledQueryFilterInput
  ) {
    onUpdateScheduledQuery(filter: $filter) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const onDeleteScheduledQuery = /* GraphQL */ `
  subscription OnDeleteScheduledQuery(
    $filter: ModelSubscriptionScheduledQueryFilterInput
  ) {
    onDeleteScheduledQuery(filter: $filter) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const onCreateOrgContract = /* GraphQL */ `
  subscription OnCreateOrgContract(
    $filter: ModelSubscriptionOrgContractFilterInput
  ) {
    onCreateOrgContract(filter: $filter) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrgContract = /* GraphQL */ `
  subscription OnUpdateOrgContract(
    $filter: ModelSubscriptionOrgContractFilterInput
  ) {
    onUpdateOrgContract(filter: $filter) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrgContract = /* GraphQL */ `
  subscription OnDeleteOrgContract(
    $filter: ModelSubscriptionOrgContractFilterInput
  ) {
    onDeleteOrgContract(filter: $filter) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeceasedReport = /* GraphQL */ `
  subscription OnCreateDeceasedReport(
    $filter: ModelSubscriptionDeceasedReportFilterInput
  ) {
    onCreateDeceasedReport(filter: $filter) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeceasedReport = /* GraphQL */ `
  subscription OnUpdateDeceasedReport(
    $filter: ModelSubscriptionDeceasedReportFilterInput
  ) {
    onUpdateDeceasedReport(filter: $filter) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeceasedReport = /* GraphQL */ `
  subscription OnDeleteDeceasedReport(
    $filter: ModelSubscriptionDeceasedReportFilterInput
  ) {
    onDeleteDeceasedReport(filter: $filter) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDownload = /* GraphQL */ `
  subscription OnCreateDownload($filter: ModelSubscriptionDownloadFilterInput) {
    onCreateDownload(filter: $filter) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
export const onUpdateDownload = /* GraphQL */ `
  subscription OnUpdateDownload($filter: ModelSubscriptionDownloadFilterInput) {
    onUpdateDownload(filter: $filter) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
export const onDeleteDownload = /* GraphQL */ `
  subscription OnDeleteDownload($filter: ModelSubscriptionDownloadFilterInput) {
    onDeleteDownload(filter: $filter) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
