import React from 'react'
import { Auth } from 'aws-amplify'
import logo from '../assets/img/emigrait_logoTransparent-min.png'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Nav, 
    Navbar,
    NavItem,
    NavLink, 
    NavbarBrand,
    NavbarToggler,
    Container,
  } from "reactstrap";

  import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


class AdminNav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""]
        }
        this.toggle = this.toggle.bind(this);
        this.displayNavs()
    }

    setInput(key, value) {
        this.setState([key], value)
    }

    
    toggle = () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }


    displayNavs = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        if( groups.includes('Admins')){
          this.setState({ navs: (
            <>
              <Link className="nav-link nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" to="/">
                <NavItem>
                  <i className="fas fa-home" />
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" to="/Products">
                <NavItem>
                <i className="fas fa-table"></i>
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" to="/Orgs">
                <NavItem>
                  <i className="fas fa-users" />
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" to="/Settings">
                <NavItem>
                  <i className="fas fa-wrench" />
                </NavItem>
              </Link>
              {/* <a className="nav-link nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" target="_blank" href="https://emigrait.atlassian.net/servicedesk/customer/portals">
                <NavItem>
                  <i className="fas fa-concierge-bell" />
                </NavItem>
              </a> */}
              <NavLink className="nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block" onClick={e => this.logOut(e)}>
                <NavItem>
                    <i className="fas fa-sign-out-alt" />
                </NavItem>
              </NavLink>
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none" to="/" onClick={e => this.toggle()}>
                <NavItem>
                  <i className="fas fa-home" /> Home
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none" to="/Products">
                <NavItem>
                  <i className="fas fa-table" /> Products
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none" to="/Orgs">
                <NavItem>
                <i className="fas fa-users" /> Orgs
                </NavItem>
              </Link>
              
              {/* <a className="nav-link nav-link-icon d-lg-none d-xl-none" target="_blank" href="https://emigrait.atlassian.net/servicedesk/customer/portals">
                <NavItem>
                <i className="fas fa-concierge-bell" /> Support
                </NavItem>
              </a> */}
              {/* <Link className="nav-link nav-link-icon d-lg-none d-xl-none" to="/Settings">
                <NavItem>
                <i className="fas fa-wrench" /> Settings
                </NavItem>
              </Link> */}
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none" onClick={e => this.logOut(e)}>
                <NavItem>
                    <i className="fas fa-sign-out-alt" /> Logout
                </NavItem>
              </Link>
            </>
          )  })
        }
        else{
          // this.setState(queryBuilder, "")
          this.setState({ navs: (
            <>
              <NavItem className='d-none d-sm-block'>
                <Link className="nav-link nav-link-icon" to="/"><i className="fas fa-home" /></Link>
              </NavItem>
              <NavItem className='d-none d-sm-block'>
                <NavLink
                  className="nav-link-icon"
                  onClick={e => this.logOut(e)}>
                  <i className="fas fa-sign-out-alt"></i>
                </NavLink>
              </NavItem>
              <NavItem className='d-block d-sm-none'>
                <Link className="nav-link nav-link-icon" to="/"><i className="fas fa-home" /> Home</Link>
              </NavItem>
              {/* <NavItem className='d-block d-sm-none'>
                <a className="nav-link nav-link-icon" target="_blank" href="https://emigrait.atlassian.net/servicedesk/customer/portals"> <i class="fas fa-concierge-bell" />Support</a>
              </NavItem> */}
              <NavItem className='d-block d-sm-none'>
                <NavLink
                  className="nav-link nav-link-icon"
                  onClick={e => this.logOut(e)}>
                  <i className="fas fa-sign-out-alt"></i>
                </NavLink>
              </NavItem>
            </>
          )})
        }
        
      }





    render  () {


        return( 
        <Navbar className="navbar-horizontal navbar-dark bg-default navbarnav" expand="lg">
          <Container className='p-0 g-0 d-flex '>
              <Link className="nav-link nav-link-icon p-0 g-0 mr-auto" to="/">
                  <NavbarBrand>
                    <img src={logo} className='p-0 g-0' />
                  </NavbarBrand>
              </Link>
              <NavbarToggler onClick={this.toggle}  className="ml-auto" />
              <Collapse isOpen={this.state.isOpen} navbar toggler="#navbar-default">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      
                    </Col>
                    <Col className="collapse-close" xs="6">
                    <button  onClick={this.toggle} className="navbar-toggler">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-md-auto" navbar>
                  {this.state.navs}
                </Nav>
              </Collapse>
          </Container>
        </Navbar>
        )
    }
};


// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default AdminNav;