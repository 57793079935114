import React from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
// import Icon from './assets/img/emigrait_logoTransparentIcon.png'



import { Orgs, Utilities, Products, Home, Maintenance, Support, ReportCreator, DeceasedReporting } from './pages'

import 'aws-amplify'


import awsmobile from "./aws-exports";

import './assets/vendor/nucleo/css/nucleo.css';
import "./assets/css/custom.css";




import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Collapse,
  NavbarToggler
} from "reactstrap";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { UserVNav } from './navs'

Amplify.configure(awsmobile);






class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      current: false,
      currentLoggedInUserGroups: [],
      selectedQuery: [],
      queryBuilder: '',
      admin: '',
      CustomRegionCreator: '',
      navs: '',
      isOpen: false

    }
    // this.displayNavs()
    this.toggle = this.toggle.bind(this);

  }

  logOut(e) {
    e.preventDefault()
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
      window.location.reload(false);
  }



  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }



  render(){
  return (<>
    <Router>
      <UserVNav />
        <Switch>
          <Route path="/Products">
            <Products />
          </Route>
          <Route path="/Orgs">
            <Orgs />
          </Route>         
          <Route path="/Settings">
            <Utilities />
          </Route>
          <Route path = "/DeceasedReporting">
            <DeceasedReporting />
          </Route>
          <Route path="/Support">
            <Support />
          </Route>
          <Route path="/ReportCreator">
            <ReportCreator />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
}

export default withAuthenticator(App);
