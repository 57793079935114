import React from 'react'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { connect } from 'react-redux'

// import ReactDOM from 'react-dom';
// import { gql } from '@apollo/client'
// import { v4 as uuid } from 'uuid';

import ContractQueryMaker from './contractQueryMaker'
// import AWSAppSyncClient from 'aws-appsync'
import { listOrganizations, listUsers, usersByOrg, listProductQueries } from '../graphql/queries'
// import awsmobile from "../aws-exports";
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    ListGroup,
    ListGroupItem,
    Nav,
    NavItem, 
    NavLink,
    TabContent,
    TabPane,
    ButtonGroup
  } from "reactstrap";





class ListContracts extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            allUsers: [],
            contractName: "",
            selectedUsers: [],
            openedCollapses: [],
            products: [],
            modals: [],
            primarySelectedOrg: {},
            selectedOrgs: [],
            ListSelectedOrgs: <></>,
            ListOrgsToSelect: <></>,
            organizations: [],
            orgSelectOptions: [<><option>Select Org</option></>],
            selectedOrgUsers: [],
            selectedContractUsers: [],
            displaySelectedContractUsers: [],
            selectedContractFiles: [],
            monthyear: {},
            displaySubmitButton: <><Button color='success' onClick={e => this.onSubmitContract(this.state)} >Submit</Button></>,
            rawYearOptions: [],
            scheduleFormSelectedYear: '',
            scheduleFormSelectedMonth: '',
            selectedSchedules: [],
            displayYearSelectOptions: [],
            displayMonthSelectOptions: [],
            displayContractOwnerQuerySelectOptions: [],
            displayContractConsumerQuerySelectOptions: [],
            productQueries: [],
            currentlySelectedSourceQuery: {},
            formSelectedContractDestinationOrg: {},
            currentlySelectedDestinationOrgQuery: {},
            displayListOfSelectedScheduledQuerys: <></>,
            activeQueryMakerTab: "",
            scheduleCustomerDestinationQueries: [],
            displaySelectedDestQueries: <></>,
            contractScheduledJobs: [],
            displayScheduledDeliveriesList: [],
            nonPrimarySelectedContractOrgs: [],
            activeDestQueryTab: '1',
            displayPrimaryOrg: <></>,
            displayNonPrimaryOrg: <></>,
            openOrgCollapse: '',

        }
        this.getOrganizations()
        this.getUsers()
        this.displayQuerySelectionTabs()

    }

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState); // Before update
        // console.log('New state', this.state); // After update 

        if(this.props.selectedOrg.selectedOrg !== this.state.selectedOrg){
            console.log("Updated props: selectedOrg")
            console.log(this.props.selectedOrg.selectedOrg.name)
            this.setState({selectedOrg: this.props.selectedOrg.selectedOrg})
        } 

        if(prevState.selectedOrg !== this.state.selectedOrg){
            console.log("Updated state: selectedOrg")
            console.log(this.state.selectedOrg)
            this.displayPrimaryOrgQueries(this.state.selectedOrg, this.state.productQueries)
        } 

        if(prevState.selectedOrgs !== this.state.selectedOrgs){
            // console.log("Updated state: selectedOrgs")
            // console.log(this.state.selectedOrgs)
            this.displayAllOrgsForAddition(this.state.selectedOrgs)
            this.displaySelectedOrgs(this.state.selectedOrgs)
            this.displayDestinationOrgSelectOptions()
        } 

        if(prevState.selectedContractUsers !== this.state.selectedContractUsers){
            // console.log("Updated state: selectedContractUsers")
            this.displayOrgSelectUsersForContract(this.state.selectedContractUsers)
            this.displayContractUsers(this.state.selectedContractUsers)
            // this.displayContractUserQuerySelect()
            this.activateSubmitButton()
        }  

        if(prevState.selectedOrgUsers !== this.state.selectedOrgUsers){
            // console.log("Updated state: selectedOrgUsers")
            this.displayOrgSelectUsersForContract(this.state.selectedContractUsers)
            // this.displayContractUserQuerySelect()
            this.getProductQuerys()
            // this.displayContractUsers(this.state.selectedContractUsers)
        } 

        if(prevState.selectedContractFiles !== this.state.selectedContractFiles){
            // console.log(this.state.selectedContractFiles)
            // console.log("update selectedContractFiles")
            this.activateSubmitButton()
        }

        // if(prevState.monthyear !== this.state.monthyear){
        //     console.log(this.state.monthyear)
        //     console.log("update monthyear")

        // }

        // if(prevState.rawYearOptions !== this.state.rawYearOptions){
            // console.log("update rawYearOptions")
            // console.log(this.state.yearOptions)
        
        // }
        if(prevState.scheduleFormSelectedYear !== this.state.scheduleFormSelectedYear){
            // console.log("update scheduleFormSelectedYear")
            // console.log(this.state.rawYearOptions)
            this.displayScheduleFormMonthSelect()
        }

        if(prevState.productQueries !== this.state.productQueries){
            // console.log("update state productQueries")
            // console.log(this.state.productQueries)
            this.displayPrimaryOrgQueries(this.state.selectedOrg, this.state.productQueries)
        }

        // if(prevState.scheduleFormSelectedMonth !== this.state.scheduleFormSelectedMonth){
        //     console.log("update scheduleFormSelectedMonth")
        //     console.log(this.state.scheduleFormSelectedMonth)
        // }

        if(prevState.formSelectedContractDestinationOrg !== this.state.formSelectedContractDestinationOrg){
            console.log("update formSelectedContractDestinationOrg")
            console.log(this.state.formSelectedContractDestinationOrg)

            this.displayNonPrimaryOrgQueries( this.state.formSelectedContractDestinationOrg, this.state.productQueries )
        }


        if(prevState.activeQueryMakerTab !== this.state.activeQueryMakerTab){
            console.log("update activeQueryMakerTab")
            console.log(this.state.activeQueryMakerTab)
            this.displayQuerySelectionTabs()
            
        }
        


        if(prevState.selectedSchedules !== this.state.selectedSchedules){
            console.log("update selectedSchedules")
            console.log(this.state.selectedSchedules)

            this.displayListOfSelectedScheduledQuerys(this.state.selectedSchedules)
        }


        


        if(prevState.scheduleCustomerDestinationQueries !== this.state.scheduleCustomerDestinationQueries){
            console.log("update scheduleCustomerDestinationQueries")
            console.log(this.state.scheduleCustomerDestinationQueries)
        }
        

        if(prevState.contractScheduledJobs !== this.state.contractScheduledJobs){
            console.log("update contractScheduledJobs")
            console.log(this.state.contractScheduledJobs)
            this.displayScheduledDeliveriesList()
        }

        if(prevState.selectedUsers !== this.state.selectedUsers){
            console.log(this.state.selectedUsers)
            this.displayPrimaryOrgUsers()
            this.displayNonPrimaryOrgs()
        }

        if(prevState.openOrgCollapse !== this.state.openOrgCollapse){
            this.displayPrimaryOrgUsers()
            this.displayNonPrimaryOrgs()
        }

        
    }

    setOpenCollapse = (collapseName) => {
        // console.log({collapseName})
        if(collapseName == this.state.openOrgCollapse){
            this.setState({openOrgCollapse: ''})
        } else {
            this.setState({openOrgCollapse: collapseName})
        }

        // console.log(this.state.selectedUsers)

    }



    getProductQuerys = async() => {
        
        const filterstr = {
            or: [{status: { ne: 'Deleted'} },
                {status: { ne: 'Archived'} }
            ]
        }
        let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr  }});
        let productItems = productData.data.listProductQueries.items;
        let pdata = productData.data.listProductQueries
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = productData.data.listProductQueries
            productItems = productItems.concat(pdata.items)
        }
        // console.log({productItems})

        // let filteredpq = productItems.filter((pq, index) => {
        //     if ('org' in pq && pq.org != null){
        //         if(pq.org = this.props.selectedOrg){
        //             // console.log(pq.org)
        //             return(pq)
        //         }
        //     }
        // })

        // console.log({filteredpq})
        this.setState({productQueries: productItems})
    }

    getOrganizations = async() => {
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100,  }});
        if(orgData.data.listOrganizations != null) {
        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }
        this.setState({organizations: orgItems})
        this.getProductQuerys()
        this.getCurrentDate()
        this.setState({activeQueryMakerTab: "2"})

        } 
    }

    getOrgUsers = async(orgname) => {
        // console.log({orgname})
        let usersRawData = await API.graphql({ query: usersByOrg, variables: { org: orgname, limit: 100,  }});
        let userItems = usersRawData.data.usersByOrg.items;
        let Userdata = usersRawData.data.usersByOrg
        while(Userdata.nextToken != null ){
            let nextToken = Userdata.nextToken;
            usersRawData = await API.graphql({ query: usersByOrg, variables: { org: orgname, limit: 100, nextToken }});
            Userdata = {}
            Userdata = usersRawData.data.usersByOrg
            userItems = userItems.concat(Userdata.items)
        }
        this.setState({selectedOrgUsers: userItems})
    }

    displayOrgSelectUsersForContract = (selectedContractUsers) => {
        // console.log("Executed Function: displayOrgSelectUsersForContract")
        // const users = this.state.selectedOrgUsers
        this.setState({ displayContractUserSelect: this.state.selectedOrgUsers.map((user, indes) => {
            if(selectedContractUsers.includes(user)){
                return(<>
                    <ListGroupItem active onClick={e => this.toggleOrgUserOnContract(user)}>
                        <Row size="sm">
                            <Col>
                                {user.org}
                            </Col>
                            <Col>
                                {user.username}
                            </Col>
                        </Row>
                        
                    </ListGroupItem>
                </>)
            }
            else{
                return(<>
                    <ListGroupItem onClick={e => this.toggleOrgUserOnContract(user)}>
                        <Row size="sm">
                            <Col>
                                {user.org}
                            </Col>
                            <Col>
                                {user.username}
                            </Col>
                        </Row>
                    </ListGroupItem>
                </>)
            }
        })})
    }

    displaySelectedOrgs = (selectedOrgs) => {
        let orgOptions = selectedOrgs.map((org, index) => {
            return(<>
                <option>
                    {org.name}
                </option>
            </>)
        })
        orgOptions.splice(0, 0, <><option>Select Org</option></>);
        this.setState({orgSelectOptions: orgOptions})
        let displayOrgs = selectedOrgs.map((org, index) => {
            return(<>
                <ListGroupItem>
                    {org.name}
                </ListGroupItem>
            </>)
        })
        this.setState({ListSelectedOrgs: displayOrgs})
    }

    displayAllOrgsForAddition = (selectedOrgs) => {



        let nonPrimaryOrgs = []
        let selectedOrgNames = selectedOrgs.map((org, index) => {
            if(org.name !== this.state.primarySelectedOrg.name){
                nonPrimaryOrgs.push(org)
            }
            return(org.name)
        })
        this.setState({nonPrimarySelectedContractOrgs: nonPrimaryOrgs})
        // console.log({nonPrimaryOrgs})
        let displayOrgs = this.state.organizations.map((org, index) => {
            let ouput = <></>
            if( selectedOrgNames.includes(org.name) ){
                // console.log("Active Org")
                if(org.name == this.state.primarySelectedOrg.name){
                    // console.log("Primary Org Found")
                    ouput = <>
                        {/* <ListGroupItem disabled>
                            {org.name}
                        </ListGroupItem> */}

                        <ListGroupItem disabled>
                            <Row className='p-0 g-0'>
                                <Col className='col-10'>            
                                    {org.name}
                                </Col>
                                <Col className='col-1 p-0 g-0'>
                                    <Input class="form-check-input mt-0" type="checkbox" checked={false}/>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </>
                } else {
                    ouput = <>
                        <ListGroupItem onClick={e => this.toggleOrgToContract(org)}>
                            <Row className='p-0 g-0'>
                                <Col className='col-10'>            
                                    {org.name}
                                </Col>
                                <Col className='col-1 p-0 g-0'>
                                    <Input class="form-check-input mt-0" type="checkbox" checked={true}/>
                                </Col>
                            </Row>                        </ListGroupItem>
                    </>
                }
            } else {
                // console.log("Inactive Org")
                ouput = <>
                    <ListGroupItem onClick={e => this.toggleOrgToContract(org)}>
                        <Row className='p-0 g-0'>
                            <Col className='col-10'>            
                                {org.name}
                            </Col>
                            <Col className='col-1 p-0 g-0'>
                                <Input class="form-check-input mt-0" type="checkbox" checked={false}/>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </>
            }
            return ouput
        })
        this.setState({ListOrgsToSelect: displayOrgs})
    }

    displayContractUsers = (selectedContractUsers) => {
        this.setState({displaySelectedContractUsers: selectedContractUsers.map((user, index) => {
                return(<>
                    <ListGroupItem>
                        <Row size="sm">
                            <Col>
                                {user.org}
                            </Col>
                            <Col>
                                {user.username}
                            </Col>
                        </Row>
                    </ListGroupItem>
                </>)
            })
        })
    }

    toggleOrgUserOnContract = (user) => {
        let selectedUsers = this.state.selectedContractUsers
        console.log({selectedUsers})
        if(selectedUsers.includes(user) ){
            selectedUsers.splice(selectedUsers.indexOf(user), 1)
        } else {
            selectedUsers.push(user)
        }
        console.log({selectedUsers})
        this.setState({selectedContractUsers: selectedUsers})
        this.displayOrgSelectUsersForContract(selectedUsers)
        this.displayContractUsers(selectedUsers)


    }

    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname), 1)
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
    }

    toggleOrgToContract = (org) => {
        let selectedOrgs = this.state.selectedOrgs
        if(selectedOrgs.includes(org) ){
            selectedOrgs.splice(selectedOrgs.indexOf(org), 1)
        } else {
            selectedOrgs.push(org)
        }
        console.log({selectedOrgs})
        this.setState({selectedOrgs: selectedOrgs})
        this.updateSelectedOrgs(selectedOrgs)
        this.displayDestinationOrgSelectOptions()

    }

    updateSelectedOrgs = (selectedOrgs) => {
        this.displayAllOrgsForAddition(selectedOrgs)
        this.displaySelectedOrgs(selectedOrgs)
    }
    openAddContract = () => {
        this.setState({primarySelectedOrg: this.props.selectedOrg.selectedOrg})
        this.setState({selectedOrgs: [this.props.selectedOrg.selectedOrg]})
        this.toggleModals('AddContractModal')
    }

    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    SelectUsersFromOrg = (event) => {
        console.log(event.target.value)
        this.getOrgUsers(event.target.value)
    }

    onSelectUploadFiles = (e) => {
        console.log(e.target.files)
        this.setState({selectedContractFiles: e.target.files})
    }

    uploadSelectedFilesToS3 = async() => {
        console.log(this.state.selectedContractFiles)
        let count = 0
        while( count < this.state.selectedContractFiles.length){
            console.log(this.state.selectedContractFiles[count])
            let file = this.state.selectedContractFiles[count]
            let uploadResponse
            try {
                uploadResponse = await Storage.put("contractFiles/" + file.name, file, {
                    level: "protected",
                    completeCallback: (event) => {
                        console.log(`Successfully uploaded ${event.key}`);
                    },
                    progressCallback: (progress) => {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    },
                    errorCallback: (err) => {
                        console.error('Unexpected error while uploading', err);
                    }
                });
                // console.log({uploadResponse})

            } catch (error) {
                console.log("Error uploading file: ", error);
            } finally {
                console.log({uploadResponse})
                file['uploadResponse'] = uploadResponse

                // create new file in db with key and setup ownership

                // insert db file id into file return
                
                
            }

            count = count + 1
        }
    }
        




    onContractNameChange = (event) => {
        console.log(event.target.value)
        this.setState({contractName: event.target.value})
    }

    activateSubmitButton = () => {

        
        if(this.state.contractName.replace(" ", '') != "" && this.state.selectedContractUsers.length > 0 && this.state.selectedContractFiles.length > 0){
            this.setState({displaySubmitButton: <><Button color='success' onClick={e => this.onSubmitContract(this.state)} >Submit</Button></>})
        } else {
            this.setState({displaySubmitButton: <><Button color='success' onClick={e => this.onSubmitContract(this.state)} >Submit</Button></>})            // this.setState({displaySubmitButton: <><Button color='success' disabled>Submit</Button></>})
        }
    }



    updateContractSchedules = (contract) => {
        console.log({contract})
    }


    getCurrentDate = () => {
        let monthyear = {}
        monthyear['today'] = new Date();
        monthyear['date'] = monthyear['today'].getFullYear()+'-'+(monthyear['today'].getMonth()+1)
        monthyear['year'] = monthyear['today'].getFullYear()
        monthyear['month'] = (monthyear['today'].getMonth()+1)

        let processYear = monthyear['year']
        let count = 0
        let yearOptions = []
        while( count < 10){
            yearOptions.push(processYear)
            processYear = processYear + 1
            count = count + 1
        }
        let displayYearSelectOptions = yearOptions.map((year,index) => <>
                <option>
                    {year}
                </option>
            </>    
        )
        this.setState({ monthyear:  monthyear})
        this.setState({rawYearOptions: yearOptions })
        this.setState({ scheduleFormSelectedYear:  monthyear['year']})
        this.setState({ scheduleFormSelectedMonth:  monthyear['month']})
        this.setState({ displayYearSelectOptions:  displayYearSelectOptions})
    }

    displayScheduleFormMonthSelect = () => {
        let months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ,11, 12]
        if( this.state.scheduleFormSelectedYear == this.state.monthyear['year']){
            // console.log("Current Year Selected")
            this.setState({displayMonthSelectOptions: 
                months.map((month, index) => {
                    if(month >= this.state.monthyear['month']){
                        return(<>
                            <option>
                                {month}
                            </option>
                        </>)
                    } 
                })
            })

            if( this.state.scheduleFormSelectedMonth < this.state.monthyear['month']){
                // console.log("Reset month to current and future values")
                this.setState({ scheduleFormSelectedMonth:  this.state.monthyear['month']})
            }
        } else {
            this.setState({displayMonthSelectOptions: 
                months.map((month, index) => {

                    return(<>
                        <option>
                            {month}
                        </option>
                    </>)
                })
            })
        }
    }

    displayDestinationOrgSelectOptions = () => {
        // console.log("execute displayContractUserQuerySelect")
        // console.log(this.state.productQueries)
        // console.log(this.state.selectedOrgs)

        const nonPrimaryOrgs = this.state.selectedOrgs.filter((org, index) => {
            if(org.name != this.props.selectedOrg.selectedOrg.name){
                return(org)
            }
        })

        if(nonPrimaryOrgs.length == 1){
            this.setState({ formSelectedContractDestinationOrg: nonPrimaryOrgs[0] })
        }

        this.setState({displayDestinationOrgSelectOptions: nonPrimaryOrgs.map((org, index) => {
            return(<><option value={JSON.stringify(org)}>{org.name}</option></>)
        })})
    }

    onSelectyear = (event) => {
        // console.log(event.target.value)
        this.setState({scheduleFormSelectedYear: event.target.value })
    }

    onSelectMonth = (event) => {
        // console.log(event.target.value)
        this.setState({scheduleFormSelectedMonth: event.target.value })
    }

    onSelectSourceQuery = (event) => {
        console.log(JSON.parse(event.target.value))
        this.setState({currentlySelectedSourceQuery: JSON.parse(event.target.value)})

    }

    onSelectDestinationQueries = (event) => {
        // console.log(JSON.parse(event.target.value))
        this.setState({currentlySelectedDestinationOrgQuery: JSON.parse(event.target.value)})
    }

    onSelectDestinationOrg = (event) => {
        // console.log(JSON.parse(event.target.value))
        this.setState({formSelectedContractDestinationOrg: JSON.parse(event.target.value)})
    }

    addScheduleToList = (state) => {
        let date = state.scheduleFormSelectedYear + "-" + state.scheduleFormSelectedMonth
        console.log({date})
        let selectedSchedulearr = state.selectedSchedules
        if(selectedSchedulearr.includes(date) ){
            console.log("Date Included Already")
        } else {
            selectedSchedulearr.push(date)
        }
        this.setState({selectedSchedules: selectedSchedulearr})
        this.displayListOfSelectedScheduledQuerys(selectedSchedulearr)
    }

    displayPrimaryOrgQueries = (org, productQueries) => {
        this.setState({displayContractOwnerQuerySelectOptions: productQueries.map((pq, index) => {
            if(pq.org == org.name){
                return(<>
                    <option  key={pq.name} value={JSON.stringify(pq)}>
                        {pq.name}
                    </option>
                </>)
            }
            }) 
        })
    }

    displayNonPrimaryOrgQueries = (org, productQueries) => {
        console.log(org)
        console.log({productQueries})

        let orgPQ = productQueries.filter((pq, index) => {
            if(pq.org == org.name){
                return(<>
                <option key={pq.name} value={JSON.stringify(pq)}>
                    pq.name
                </option>
                </>)
            }
        })

        this.setState({displayContractConsumerQuerySelectOptions: orgPQ.map((pq, index) => <><option value={JSON.stringify(pq)}>{pq.name}</option></>)})
        console.log({orgPQ})

    }

    toggleQuerySelectionTabs = (tab) => {
        this.setState({activeQueryMakerTab: tab })
    }

    displayQuerySelectionTabs = () => {
        const tabs = ["1", "2"]
        const tabNames = ["Existing Query", "New Query"]

        this.setState({DisplayQuerySelectionsNavTabs: tabs.map((tab, index) => {
            if(tab == this.state.activeQueryMakerTab){
                return(<><NavItem>
                    <NavLink className="active" onClick={ e => this.toggleQuerySelectionTabs(tab)} >
                        {tabNames[index]}
                    </NavLink>
                </NavItem>
                </>)
            } else {
                return(<><NavItem>
                    <NavLink onClick={ e => this.toggleQuerySelectionTabs(tab)} >
                        {tabNames[index]}
                    </NavLink>
                </NavItem>
                </>)
            }
        })})
    }

    toggleScheduledDeliveryDate = (date) => {
        // let date = year + "-" + month

        let selectedSchedules = this.state.selectedSchedules
        if(selectedSchedules.includes(date) ){
            selectedSchedules.splice(selectedSchedules.indexOf(date), 1)
        } else {
            selectedSchedules.push(date)
        }
        console.log({selectedSchedules})
        this.setState({selectedSchedules: selectedSchedules})
        
        this.displayListOfSelectedScheduledQuerys(selectedSchedules)
    }

    displayListOfSelectedScheduledQuerys = (selectedSchedules) => {
        // console.log("Execute: displayListOfSelectedScheduledQuerys")
        // console.log(selectedSchedules)

        this.setState({displayListOfSelectedScheduledQuerys: selectedSchedules.map((schedule, index) => {
            return(<>
                <ListGroupItem>
                    <Row>
                        <Col classname="col-sm">
                            {schedule}
                        </Col>
                        <Col classname="col-sm align-self-end">
                            <Button size='sm' color='danger' onClick={e => this.toggleScheduledDeliveryDate(schedule)}>X</Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            </>)
        })})
    }

    addDestinationQuery = (state) => {
        let destQueryarr = state.scheduleCustomerDestinationQueries
        let destQuery = {}
        destQuery['org'] = state.formSelectedContractDestinationOrg
        destQuery['query'] = state.currentlySelectedDestinationOrgQuery
        const destQuerystr = JSON.stringify(destQuery)
        if(destQueryarr.includes(destQuerystr) ){
            console.log("Query Included Already")
        } else {
            destQueryarr.push(destQuerystr)
        }
        this.setState({scheduleCustomerDestinationQueries:  destQueryarr})
        this.displayDestinationQuery(destQueryarr)
    }

    removeDestinationQuery = (querydata) => {
        let destQueryarr = this.state.scheduleCustomerDestinationQueries
        if(destQueryarr.includes(querydata) ){
            destQueryarr.splice(destQueryarr.indexOf(querydata), 1)
        }
        this.setState({scheduleCustomerDestinationQueries: destQueryarr})
        this.displayDestinationQuery(destQueryarr)

    }

    displayDestinationQuery = (destQueries) => {
        this.setState({displaySelectedDestQueries: destQueries.map((query, index) => {
            let querydata = JSON.parse(query)
            return(<>
                <ListGroupItem>
                    <Row>
                        <Col classname="col-sm">
                            {querydata.org.name}
                        </Col>
                        <Col classname="col-sm">
                            {querydata.query.name}
                        </Col>
                        <Col classname="col-sm align-self-end">
                            <Button size='sm' color='danger' onClick={e => this.removeDestinationQuery(query)} >X</Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            </>)
        })
    })
    }


    displayScheduledDeliveriesList = (jobs) => {
        console.log({jobs})
        console.log("displayScheduledDeliveriesList")

        this.setState({ displayScheduledDeliveriesList: jobs.map((job, index) => {
            let jobobj =  JSON.parse(job)
            return(<>
                <ListGroupItem>
                    <Row>
                        <Col className='col-9'>
                            {jobobj.name}
                        </Col>
                        <Col className='col-2'>
                            <ButtonGroup>
                                <Button className='btn-sm' color='info' onClick={e => this.scheduledDeliveryFromListDetails(job)}>
                                    <i className="fa-solid fa-info"></i>
                                </Button>
                                <Button className='btn-sm' color='warning' onClick={e => this.editScheduledDeliveryFromList(job)}>
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </Button>
                                <Button className='btn-sm' color='danger' onClick={e => this.removeScheduledDeliveryFromList(job)}>
                                    <i className="fa-solid fa-xmark"></i>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </ListGroupItem>
            </>)
        })})
    }


    removeScheduledDeliveryFromList = (job) => {
        console.log(JSON.parse(job))
    }

    editScheduledDeliveryFromList = (job) => {
        console.log(JSON.parse(job))
    }

    scheduledDeliveryFromListDetails = (job) => {
        console.log(JSON.parse(job))
    }

    openScheduledDeliveriesModal = async() => {
        let modalname = 'AddScheduledQueryToContract'
        let modals = this.state.modals
        if(!modals.includes(modalname) ){
            // modals.splice(modals.indexOf(modalname), 1)
            modals.push(modalname)

        } 
        console.log(this.state.primarySelectedOrg)
        console.log(this.state.nonPrimarySelectedContractOrgs)
        this.setState({modals: modals})

        let primarySelectedOrgWithUsers = this.filterUsersByOrg(this.state.primarySelectedOrg)
        let nonPrimarySelectedOrgWithUsers = this.addUserstoOrgArray(this.state.nonPrimarySelectedContractOrgs)
        // console.log({nonPrimarySelectedOrgWithUsers})
        this.setState({primarySelectedOrgWithUsers: primarySelectedOrgWithUsers})

        this.setState({nonPrimarySelectedOrgWithUsers: nonPrimarySelectedOrgWithUsers})

        let eventinput = {
            primarySelectedOrg: primarySelectedOrgWithUsers,
            otherOrgs: nonPrimarySelectedOrgWithUsers
        }
        // this.loadUsers(primarySelectedOrgWithUsers)




        console.log({eventinput})
        this.props.updateSelectedOrganizationData(eventinput)
        // this.loadAllOrgUsers(eventinput)

        let allOrgs = [...nonPrimarySelectedOrgWithUsers, primarySelectedOrgWithUsers]
        this.setState({selectedUsers:  this.loadUsersFromOrgs(allOrgs).flat(1) })

    }

    filterUsersByOrg = (org) => {
        org['users'] = this.state.allUsers.filter((user) => {
            if(user.org == org['name']){
                return(user)
            }
        })
        return(org)
    }

    addUserstoOrgArray = (orgs) => {
        let orgsWithUsers = orgs.map((org) => {
            let tmpOrg = this.filterUsersByOrg(org)

            return(tmpOrg)
        })

        return(orgsWithUsers)
    }



    getUsers = async() => {
        let usersRawData = await API.graphql({ query: listUsers, variables: { limit: 100,  }});
        let userItems = usersRawData.data.listUsers.items;
        let Userdata = usersRawData.data.listUsers
        while(Userdata.nextToken != null ){
            let nextToken = Userdata.nextToken;
            usersRawData = await API.graphql({ query: listUsers, variables: { limit: 100, nextToken }});
            Userdata = {}
            Userdata = usersRawData.data.listUsers
            userItems = userItems.concat(Userdata.items)
        }
        this.setState({allUsers: userItems})
    }

    activeDestQueryTab = (tab) => {
        this.setState({activeDestQueryTab: tab})
    }

    loadUsers = (org) => {
        let users = org.users.map((user) => {
            return(user.username)
        })

        // console.log(users)
        users = this.state.selectedUsers.concat(users)
        console.log({users})

        // this.setState({selectedUsers: users })
        return(users)
    }

    loadAllOrgUsers = (inputs) => {
        let pusers = this.loadUsers(inputs.primarySelectedOrgWithUsers)
        let npusers = this.loadUsersFromOrgs(inputs.nonPrimarySelectedOrgWithUsers)


        let selectedUsers = pusers.concat(npusers).flat(1)
        console.log(selectedUsers)
        // this.setState({selectedUsers: selectedUsers })

    }

    loadUsersFromOrgs = (orgs) => {
        console.log({orgs})
        let users = orgs.map((org) => {
            let usersarrs = org.users.map((user) => {
                return(user.username)
            })
            return(usersarrs.flat(1))
        })

        users = this.state.selectedUsers.concat(users).flat(1)
        console.log(users)

        // this.setState({selectedUsers: users.flat(1) })
        return(users)

    }

    toggleUsernameSelect = (username) => {
        // console.log({username})
        let users = this.state.selectedUsers
        // console.log(this.state.selectedUsers)
        if (users.includes(username)) {
            const index = users.indexOf(username)
            users.splice(index, 1);
            this.setState({
                selectedUsers: users
            });
        } else {
            this.setState({
                selectedUsers: [ ...this.state.selectedUsers, username]
            })
        }

        this.displayPrimaryOrgUsers()
        this.displayNonPrimaryOrgs()

    }

    isUsernameCheckboxChecked = (username) => {
        if(this.state.selectedUsers.includes(username)){
            return(true)
        } else {
            return(false)
        }
    }


    displayNonPrimaryOrgs = () => {
        console.log("displayNonPrimaryOrgs")
        this.setState({displayNonPrimaryOrg: this.state.nonPrimarySelectedOrgWithUsers.map((org) => {
            let users = org.users.map((user) => <>
            <ListGroupItem  onClick={ e => this.toggleUsernameSelect(user.username)}>
                <Row className='p-0 g-0'>
                    <Col className='col-10'>            
                        {user.username}
                    </Col>
                    <Col className='col-1 p-0 g-0'>
                        <Input class="form-check-input mt-0" type="checkbox" checked={ this.isUsernameCheckboxChecked(user.username) } />
                    </Col>
                </Row>
            </ListGroupItem>
            </>)
            return(<>
                <ListGroupItem>
                    <Row className='p-0 g-0'>
                        <Col className='col-10'>
                            <h3>{org.name}</h3>
                        </Col>
                        <Col className='col-2 p-0 g-0'>
                            <Button className='btn-sm contractUsersEditButton' onClick={e => this.setOpenCollapse(org.name)}>
                                {/* <i className="fa-solid fa-pen-to-square" /> */}
                                <i className="fa-solid fa-user-pen"></i>
                            </Button>
                        </Col>
                    </Row>

                    <Collapse isOpen={ org.name == this.state.openOrgCollapse}>
                        <hr />
                        {users}
                    </Collapse>
                </ListGroupItem>
            </>)}) 
        })
    }

    displayPrimaryOrgUsers = async() => {
        console.log("displayPrimaryOrgUsers")
        let users = this.state.primarySelectedOrgWithUsers.users.map((user) => <>
        <ListGroupItem onClick={ e => this.toggleUsernameSelect(user.username)}>
            <Row className='p-0 g-0'>
                <Col className='col-10'>            
                    {user.username}
                </Col>
                <Col className='col-1 p-0 g-0'>
                    <Input class="form-check-input mt-0" type="checkbox" checked={ this.isUsernameCheckboxChecked(user.username) }  />
                </Col>
            </Row>
        </ListGroupItem>
        </>)

        this.setState({displayPrimaryOrg: <>
            <ListGroupItem>
                <Row className='p-0 g-0'>
                    <Col className='col-10'>
                        <h3>{this.state.primarySelectedOrg.name}</h3>
                    </Col>
                    <Col className='col-2 p-0 g-0'>
                        <Button className='btn-sm contractUsersEditButton' color='info' onClick={e => this.setOpenCollapse(this.state.primarySelectedOrg.name)}>
                            {/* <i className="fa-solid fa-pen-to-square" /> */}
                            <i className="fa-solid fa-user-pen"></i>
                        </Button>
                    </Col>
                </Row>

                <Collapse isOpen={ this.state.primarySelectedOrg.name == this.state.openOrgCollapse}>
                    <hr />
                    {users}
                </Collapse>
            </ListGroupItem>
        </>})

    }

    
    onSubmitScheduledJob = (state) => {
        console.log({state})
        let scheduleJob = {}
        scheduleJob['PrimaryQueryOrg'] = state.primarySelectedOrg
        scheduleJob['nonPrimaryQueryOrgs'] = state.nonPrimarySelectedOrgWithUsers

        if(state.activeQueryMakerTab == 1){

            // 1 = Existing Query Select
            console.log("Existing Query Tab Selected")




            // console.log("activeQueryMakerTab: Existing Query")    
            scheduleJob['type'] = 'Existing'
            // console.log('primarySelectedOrg: ', state.primarySelectedOrg)
            // console.log('currentlySelectedSourceQuery: ', state.currentlySelectedSourceQuery)
            scheduleJob['name'] = state.currentlySelectedSourceQuery.name
            scheduleJob['sourceQuery'] = state.currentlySelectedSourceQuery
            this.setState({currentlySelectedSourceQuery: {}})
            // console.log('scheduleCustomerDestinationQueries: ', state.scheduleCustomerDestinationQueries)


            // Existing Query has modes from openOrgCollapse
            console.log('Destination Queries')
            if(this.state.openOrgCollapse == '1'){
                console.log('Select Users for Customer Queries')
                scheduleJob['destinationQueriesType'] = 'DeployNewToOrgs'
                scheduleJob['destinationQueriesUsers'] = this.state.selectedUsers
                scheduleJob['destinationQueriesOrgs'] = this.state.selectedUsers

                

            } else if(this.state.openOrgCollapse =='2'){
                console.log('Select Existing Customer Queries')
                scheduleJob['destinationQueriesType'] = 'Existing'
                scheduleJob['destinationQueries'] = state.scheduleCustomerDestinationQueries
                this.setState({scheduleCustomerDestinationQueries: []})
                // selected queries from existing
                // this.state.scheduleCustomerDestinationQueries

            }



        } else if(this.state.openOrgCollapse =='2'){
            // console.log("activeQueryMakerTab: New Query")    
            console.log('Select Existing Customer Queries')

            scheduleJob = JSON.parse(this.props.contractQueryInputs)
            scheduleJob['type'] = 'New'


            console.log({scheduleJob})






        }
        // console.log('selectedSchedules: ', state.selectedSchedules)
        // scheduleJob['deliveries'] = state.selectedSchedules
        this.setState({selectedSchedules: []})
        this.displayListOfSelectedScheduledQuerys([])

        this.displayDestinationQuery([])


        const scheduleJobstr = JSON.stringify(scheduleJob)


        let contractScheduledJobsarr = state.contractScheduledJobs

        if(contractScheduledJobsarr.includes(scheduleJobstr)){
            console.log("Identical Job Exists")
        } else {
            contractScheduledJobsarr.push(scheduleJobstr)
        }
        console.log({contractScheduledJobsarr})







        this.setState({contractScheduledJobs: contractScheduledJobsarr})
        this.displayScheduledDeliveriesList(contractScheduledJobsarr)
        this.toggleModals('AddScheduledQueryToContract')
    }

    onSubmitContract = async(state) => {
        // console.log({state})


        // Create contract record without files info

        let input = {}
        input['users'] = state.selectedContractUsers
        input['orgs'] = state.selectedOrgs
        input['name'] = state.contractName

        console.log({input})



        //////// inside contract Query modal
        if(this.state.activeQueryMakerTab == '1'){
            // 1 = Existing Query Select
            console.log("Existing Query Tab Selected")

            // Existing Query has modes from openOrgCollapse
            console.log('Destination Queries')
            if(this.state.openOrgCollapse == '1'){
                console.log('Select Users for Customer Queries')
            } else if(this.state.openOrgCollapse =='2'){
                console.log('Select Existing Customer Queries')

                // selected queries from existing
                // this.state.scheduleCustomerDestinationQueries

            }


        } else if(this.state.activeQueryMakerTab == '2'){
            // 2 = New Query Form
            console.log("New Query Tab Selected")
        }

        //////// inside contract Query modal



        let schedulejobs = this.state.contractScheduledJobs

        // create contract record




        // take contract record and run each file with contractID as input

        // contact.id 
        // let responses = this.uploadSelectedFilesToS3()

        // update contract with the file key info


        //
        // create scheduledQuery records and update contract with ids

        // this.updateContractSchedules(contract.id)

    }

    render  () {


        return( <>
                <Modal backdrop="static" isOpen={this.state.modals.includes('AddUsersToContract')}  className="modal-md modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.toggleModals('AddUsersToContract')}>×</button>}>Add Org Users To Contract</ModalHeader>
                    <ModalBody>
                        <Input
                        id="SelectOrg"
                        name="selectOrg"
                        type="select"
                        onChange={e => this.SelectUsersFromOrg(e)}
                        >
                            {this.state.orgSelectOptions}
                        </Input> 
                        <br />
                        <ListGroup classname="list-group-flush">
                            {this.state.displayContractUserSelect}    
                        </ListGroup>               
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ e => this.toggleModals('AddUsersToContract') }>Close</Button>
                    </ModalFooter>
                </Modal>
                <Modal backdrop="static" isOpen={this.state.modals.includes('AddScheduledQueryToContract')}  className="modal-xl modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.toggleModals('AddScheduledQueryToContract')}>×</button>}>Add Scheduled Delivery To Contract</ModalHeader>
                    <ModalBody className='moreInfoModalBody moreInfoModal'>
                        <Row>
                            <Col classname="col-6">
                                <Nav tabs>
                                    { this.state.DisplayQuerySelectionsNavTabs }
                                </Nav>
                                <TabContent activeTab={this.state.activeQueryMakerTab} >
                                    <TabPane tabId="1">
                                        <br />
                                        <Row>

                                            {/* Use Existing Queries Form */}
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <h3>Admin Test Query </h3>
                                                        <br />
                                                        <Row>
                                                            <Col classname="col-4">
                                                                <ListGroup>
                                                                    {this.state.displayPrimaryOrg}
                                                                </ListGroup>
                                                            </Col>
                                                            <Col classname="col-4">
                                                                <Input
                                                                    id="Select Source Query"
                                                                    name="selectSourceQuery"
                                                                    type="select"
                                                                    onChange={e => this.onSelectSourceQuery(e)}
                                                                    // value={this.state.scheduleFormSelectedMonth} // acting weird
                                                                    placeholder="Select Source Query"
                                                                    >
                                                                        
                                                                    <option value={"{}"}>
                                                                        Select Source Query
                                                                    </option>
                                                                    {this.state.displayContractOwnerQuerySelectOptions}
                                                                </Input>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <h3>Customer Destination Query </h3>
                                                        <br />
                                                        <Nav tabs>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeDestQueryTab === '1' })} onClick={() => { this.activeDestQueryTab('1'); }}>
                                                                    New
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeDestQueryTab === '2' })} onClick={() => { this.activeDestQueryTab('2'); }}>
                                                                    Existing
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>

                                                        <TabContent activeTab={this.state.activeDestQueryTab}>
                                                            <TabPane tabId="1">
                                                                <br />
                                                                <Row>
                                                                    <Col className='col-4'>
                                                                        <ListGroup>
                                                                            {this.state.displayNonPrimaryOrg}
                                                                        </ListGroup>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId="2">
                                                                <br />
                                                                <Row>
                                                                    <Col classname="col-6">
                                                                        <Input
                                                                            id="Select Destination Org"
                                                                            name="selectDestinationSourceOrg"
                                                                            type="select"
                                                                            onChange={e => this.onSelectDestinationOrg(e)}
                                                                            // value={this.state.scheduleFormSelectedMonth} // acting weird
                                                                            >
                                                                                <option value={"{}"}>
                                                                                    Select Customer
                                                                                </option>
                                                                                {this.state.displayDestinationOrgSelectOptions}
                                                                        </Input>
                                                                    </Col>
                                                                    <Col classname="col-6">
                                                                        <Input
                                                                            id="Select Destination Query"
                                                                            name="selectDestinationQuery"
                                                                            type="select"
                                                                            onChange={e => this.onSelectDestinationQueries(e)}
                                                                            // value={this.state.scheduleFormSelectedMonth} // acting weird
                                                                            >
                                                                                <option value={"{}"}>
                                                                                    Select Customer Query
                                                                                </option>
                                                                                {this.state.displayContractConsumerQuerySelectOptions}
                                                                        </Input>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button onClick={e => this.addDestinationQuery(this.state)}>
                                                                            +
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <Row>
                                                                    <Col classname='col-10'>
                                                                        <ListGroup>
                                                                            {this.state.displaySelectedDestQueries}
                                                                        </ListGroup>
                                                                    </Col>
                                                                    <Col classname="col-2">                                        
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <br />
                                            {/* Create New Query Form */}
                                            <ContractQueryMaker />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <h3> Scheduled Delivery Dates </h3>
                                <br />
                                <Row>
                                    <Col>
                                        <Input
                                        id="SelectYear"
                                        name="select"
                                        type="select"
                                        onChange={e => this.onSelectyear(e)}
                                        // value={this.state.scheduleFormSelectedYear} // acting weird
                                        >
                                            {this.state.displayYearSelectOptions}
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input
                                            id="Select Month"
                                            name="selectMonth"
                                            type="select"
                                            onChange={e => this.onSelectMonth(e)}
                                            // value={this.state.scheduleFormSelectedMonth} // acting weird
                                            >
                                            {this.state.displayMonthSelectOptions}
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Button onClick={e => this.addScheduleToList(this.state)}>+</Button>
                                    </Col>
                                </Row>
                                <br />

                                
                                <Row>
                                    <Col classname="col-2">
                                        <ListGroup classname="list-group-flush">
                                            {this.state.displayListOfSelectedScheduledQuerys}
                                        </ListGroup>
                                    </Col>
                                    
                                    <Col classname="col-10">                                        
                                    </Col>

                                </Row>
                                
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={e => this.onSubmitScheduledJob(this.state)} >Submit</Button>
                        <Button color="secondary" onClick={ e => this.toggleModals('AddScheduledQueryToContract') }>Close</Button>
                    </ModalFooter>
                </Modal>
                <Modal backdrop="static" isOpen={this.state.modals.includes('AddOrgToContract')}  className="modal-md modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.toggleModals('AddOrgToContract')}>×</button>}>Add Another Org To Contract</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            { this.state.ListOrgsToSelect }
                        </ListGroup>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ e => this.toggleModals('AddOrgToContract') }>Close</Button>
                    </ModalFooter>
                </Modal>
                <Modal backdrop="static" isOpen={this.state.modals.includes('AddContractModal')}  className="modal-xl modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.toggleModals('AddContractModal')}>×</button>}>Add Contract To Org</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Input id="ContractName" placeholder='Contract Name' onChange={ e => this.onContractNameChange(e)} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Row>
                                    <Col sm>
                                        <h3>Orgs</h3>
                                    </Col>    
                                    <Col sm>
                                        <Button size="sm" color="info" onClick={ e => this.toggleModals('AddOrgToContract') }>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <ListGroup>
                                    {this.state.ListSelectedOrgs}
                                </ListGroup>
                                <br />
                                <Row>
                                    <Col>
                                        <Input id="addContractFile" name="addContractFile" type="file" multiple onChange={e => this.onSelectUploadFiles(e)} />
                                    </Col>
                                    
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col sm>
                                        <h3>Scheduled Deliveries</h3>    
                                    </Col>
                                    <Col sm>
                                        <Button size="sm" color="info" onClick={ e => this.openScheduledDeliveriesModal() }>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {this.state.displayScheduledDeliveriesList}
                                        </ListGroup>
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                        <br />
                        
                    </ModalBody>
                    <ModalFooter>
                        {this.state.displaySubmitButton}
                        <Button color="secondary" onClick={ e => this.toggleModals('AddContractModal') }>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col>
                        <Button color="info" onClick={e => this.openAddContract()}>
                            Add Contract
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}



const mapStateToProps = state => {
    console.log(state.selectedOrg)
    return {
        selectedOrg: state.selectedOrg,
        contractQueryInputs: state.contractQueryInputs  
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedOrganizationData: (event) => {
            console.log('UPDATESELECTEDCONTRACTORGANIZATIONS to props event: ', event)
            dispatch({type: 'UPDATESELECTEDCONTRACTORGANIZATIONS', 
            primarySelectedContractOrg: event.primarySelectedOrg,
            nonPrimarySelectedContractOrgs: event.otherOrgs
            })
        }
    }
}



export default connect( mapStateToProps, mapDispatchToProps )(ListContracts) 
