import React from 'react'

import { Auth } from 'aws-amplify'
import logo from '../assets/img/emigrait_logoTransparent-min.png'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Nav, 
    NavItem,
    Navbar,
    NavLink, 
    NavbarBrand,
    NavbarToggler,
    Container,
  } from "reactstrap";

  import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";




class UserVNav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""]
        }
        this.displayNavs()
    }

    setInput(key, value) {
        this.setState([key], value)
    }

    logOut(e) {
      e.preventDefault()
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
        window.location.reload(false);
    }
  

    displayNavs = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        if( groups.includes('Admins')){
          this.setState({ navs: (
            <>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/">
                <NavItem >
                  <i className="fas fa-file-csv iconPadding"></i> Reports
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/ReportCreator">
                <NavItem>
                  <i className="fa-solid fa-database iconPadding"></i> Report Creator
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/Products">
                <NavItem>
                  <i className="fa-solid fa-box iconPadding"></i> Products
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/Orgs">
                <NavItem>
                  <i className="fas fa-users iconPadding" /> Organizations
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/DeceasedReporting">
                <NavItem>
                  <i className="fas fa-skull iconPadding" /> Deceased Reporting
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/Settings">
                <NavItem>
                  <i className="fa-solid fa-sliders iconPadding" /> Settings
                </NavItem>
              </Link>

              <hr className='sidebar-h-Line' />

              <Link className="nav-link nav-link-icon sidebarLinkText" to="/Support">
                <NavItem>
                  <i className="fas fa-life-ring iconPadding" /> Support
                </NavItem>
              </Link>

              <Link className="nav-link nav-link-icon sidebarLinkText d-lg-none d-xl-none d-md-none" onClick={e => this.logOut(e)}>
                <NavItem>
                    <i className="fas fa-sign-out-alt iconPadding" /> Logout
                </NavItem>
              </Link>


              {/* <Link className="nav-link nav-link-icon d-lg-none d-xl-none sidebarLinkText" to="/" onClick={e => this.toggle()}>
                <NavItem>
                  <i className="fas fa-home" /> Home
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none sidebarLinkText" to="/Products">
                <NavItem>
                  <i className="fas fa-table" /> Products
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon d-lg-none d-xl-none sidebarLinkText" to="/Orgs">
                <NavItem>
                <i className="fas fa-users" /> Orgs
                </NavItem>
              </Link>
               */}

            </>
          )  })
        }
        else{
          // this.setState(queryBuilder, "")
          this.setState({ navs: (
            <>
              <Link className="nav-link nav-link-icon sidebarLinkText" to="/">
                <NavItem>
                  <i className="fas fa-file-csv iconPadding"></i> Reports
                </NavItem>
              </Link>
              <Link className="nav-link nav-link-icon sidebarLinkText d-lg-none d-xl-none d-md-none" onClick={e => this.logOut(e)}>
                <NavItem>
                    <i className="fas fa-sign-out-alt iconPadding" /> Logout
                </NavItem>
              </Link>
            </>
          )})
        }
        
      }

    render  () {
        return( <>
        <div className='p-0 g-0 d-none d-xs-none d-sm-none d-md-block d-lg-block'>
          <Navbar className="sidebar navbar-vertical userVNavColor p-0 g-0" expand="md">
            {/* <div className='p-0 g-0'> */}
              <div className='p-0 g-0'>
                <Link className="nav-link nav-link-icon " to="/">
                    <img src={logo} className='p-0 g-0 sidebarBrandImg' />
                </Link>
                <div className='sidebarWidth'>
                  <Nav className="ml-md-auto p-0 g-0 " navbar>
                    {this.state.navs}
                  </Nav>
                </div>
              </div>
            {/* </div> */}
          </Navbar>
          </div>
          <div className='p-0 g-0 d-lg-none d-xl-none d-md-none'>
          <Navbar className="vnavMobile userVNavColor p-0 g-0 " expand="md">
              <Container>
                <Link className="nav-link nav-link-icon  className='p-3'" to="/">
                    <img src={logo} className='p-0 g-0 sidebarBrandImg' />
                </Link>
                <div className='sidebarWidth'>
                  <Nav vertical className="ml-md-auto p-3 g-0 " navbar>
                    {this.state.navs}
                  </Nav>
                </div>
              </Container>
          </Navbar>
          </div>
</>




        )
    }
};


// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default UserVNav;