/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deceasedBatchProcessor = /* GraphQL */ `
  mutation DeceasedBatchProcessor($input: String!) {
    deceasedBatchProcessor(input: $input)
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      regionName
      type
      parentRegion
      parentType
      lat
      lng
      population
      status
      regionCol
      subRegions
      owners
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      type
      rank
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createDataVersion = /* GraphQL */ `
  mutation CreateDataVersion(
    $input: CreateDataVersionInput!
    $condition: ModelDataVersionConditionInput
  ) {
    createDataVersion(input: $input, condition: $condition) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateDataVersion = /* GraphQL */ `
  mutation UpdateDataVersion(
    $input: UpdateDataVersionInput!
    $condition: ModelDataVersionConditionInput
  ) {
    updateDataVersion(input: $input, condition: $condition) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteDataVersion = /* GraphQL */ `
  mutation DeleteDataVersion(
    $input: DeleteDataVersionInput!
    $condition: ModelDataVersionConditionInput
  ) {
    deleteDataVersion(input: $input, condition: $condition) {
      version
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createRegindex = /* GraphQL */ `
  mutation CreateRegindex(
    $input: CreateRegindexInput!
    $condition: ModelRegindexConditionInput
  ) {
    createRegindex(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateRegindex = /* GraphQL */ `
  mutation UpdateRegindex(
    $input: UpdateRegindexInput!
    $condition: ModelRegindexConditionInput
  ) {
    updateRegindex(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegindex = /* GraphQL */ `
  mutation DeleteRegindex(
    $input: DeleteRegindexInput!
    $condition: ModelRegindexConditionInput
  ) {
    deleteRegindex(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      key
      owners
      status
      metadata
      name
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      name
      status
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      username
      metadata
      role
      email
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export const createProductQuery = /* GraphQL */ `
  mutation CreateProductQuery(
    $input: CreateProductQueryInput!
    $condition: ModelProductQueryConditionInput
  ) {
    createProductQuery(input: $input, condition: $condition) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const updateProductQuery = /* GraphQL */ `
  mutation UpdateProductQuery(
    $input: UpdateProductQueryInput!
    $condition: ModelProductQueryConditionInput
  ) {
    updateProductQuery(input: $input, condition: $condition) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductQuery = /* GraphQL */ `
  mutation DeleteProductQuery(
    $input: DeleteProductQueryInput!
    $condition: ModelProductQueryConditionInput
  ) {
    deleteProductQuery(input: $input, condition: $condition) {
      id
      name
      current
      status
      deleted
      lastRun
      lastCompletedRun {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      description
      products
      metadata
      org
      organization {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      owners
      contract
      createdAt
      updatedAt
    }
  }
`;
export const createProductQueryHistory = /* GraphQL */ `
  mutation CreateProductQueryHistory(
    $input: CreateProductQueryHistoryInput!
    $condition: ModelProductQueryHistoryConditionInput
  ) {
    createProductQueryHistory(input: $input, condition: $condition) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const updateProductQueryHistory = /* GraphQL */ `
  mutation UpdateProductQueryHistory(
    $input: UpdateProductQueryHistoryInput!
    $condition: ModelProductQueryHistoryConditionInput
  ) {
    updateProductQueryHistory(input: $input, condition: $condition) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductQueryHistory = /* GraphQL */ `
  mutation DeleteProductQueryHistory(
    $input: DeleteProductQueryHistoryInput!
    $condition: ModelProductQueryHistoryConditionInput
  ) {
    deleteProductQueryHistory(input: $input, condition: $condition) {
      id
      productQueryid
      productQueryidx {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      status
      dataVersion
      dataVersionidx {
        version
        status
        metadata
        createdAt
        updatedAt
      }
      totalCount
      resultsData
      moved
      sourceid
      downloaded
      notificationCompleted
      metadata
      owners
      createdAt
      updatedAt
    }
  }
`;
export const createScheduledQuery = /* GraphQL */ `
  mutation CreateScheduledQuery(
    $input: CreateScheduledQueryInput!
    $condition: ModelScheduledQueryConditionInput
  ) {
    createScheduledQuery(input: $input, condition: $condition) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const updateScheduledQuery = /* GraphQL */ `
  mutation UpdateScheduledQuery(
    $input: UpdateScheduledQueryInput!
    $condition: ModelScheduledQueryConditionInput
  ) {
    updateScheduledQuery(input: $input, condition: $condition) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const deleteScheduledQuery = /* GraphQL */ `
  mutation DeleteScheduledQuery(
    $input: DeleteScheduledQueryInput!
    $condition: ModelScheduledQueryConditionInput
  ) {
    deleteScheduledQuery(input: $input, condition: $condition) {
      id
      adminProductQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      scheduledDeliveryDates
      deliveredResults {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      metadata
      status
      contractid
      contract {
        id
        org {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        users {
          username
          metadata
          role
          email
          org
          status
          createdAt
          updatedAt
        }
        scheduledQueries {
          nextToken
        }
        files
        metadata
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      orgContractScheduledQueriesId
    }
  }
`;
export const createOrgContract = /* GraphQL */ `
  mutation CreateOrgContract(
    $input: CreateOrgContractInput!
    $condition: ModelOrgContractConditionInput
  ) {
    createOrgContract(input: $input, condition: $condition) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateOrgContract = /* GraphQL */ `
  mutation UpdateOrgContract(
    $input: UpdateOrgContractInput!
    $condition: ModelOrgContractConditionInput
  ) {
    updateOrgContract(input: $input, condition: $condition) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrgContract = /* GraphQL */ `
  mutation DeleteOrgContract(
    $input: DeleteOrgContractInput!
    $condition: ModelOrgContractConditionInput
  ) {
    deleteOrgContract(input: $input, condition: $condition) {
      id
      org {
        name
        status
        metadata
        createdAt
        updatedAt
      }
      users {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      scheduledQueries {
        items {
          id
          scheduledDeliveryDates
          metadata
          status
          contractid
          createdAt
          updatedAt
          orgContractScheduledQueriesId
        }
        nextToken
      }
      files
      metadata
      status
      createdAt
      updatedAt
    }
  }
`;
export const createDeceasedReport = /* GraphQL */ `
  mutation CreateDeceasedReport(
    $input: CreateDeceasedReportInput!
    $condition: ModelDeceasedReportConditionInput
  ) {
    createDeceasedReport(input: $input, condition: $condition) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateDeceasedReport = /* GraphQL */ `
  mutation UpdateDeceasedReport(
    $input: UpdateDeceasedReportInput!
    $condition: ModelDeceasedReportConditionInput
  ) {
    updateDeceasedReport(input: $input, condition: $condition) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeceasedReport = /* GraphQL */ `
  mutation DeleteDeceasedReport(
    $input: DeleteDeceasedReportInput!
    $condition: ModelDeceasedReportConditionInput
  ) {
    deleteDeceasedReport(input: $input, condition: $condition) {
      pid
      fname
      lname
      org
      user
      id
      createdAt
      updatedAt
    }
  }
`;
export const createDownload = /* GraphQL */ `
  mutation CreateDownload(
    $input: CreateDownloadInput!
    $condition: ModelDownloadConditionInput
  ) {
    createDownload(input: $input, condition: $condition) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
export const updateDownload = /* GraphQL */ `
  mutation UpdateDownload(
    $input: UpdateDownloadInput!
    $condition: ModelDownloadConditionInput
  ) {
    updateDownload(input: $input, condition: $condition) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
export const deleteDownload = /* GraphQL */ `
  mutation DeleteDownload(
    $input: DeleteDownloadInput!
    $condition: ModelDownloadConditionInput
  ) {
    deleteDownload(input: $input, condition: $condition) {
      id
      productQueryid
      productQuery {
        id
        name
        current
        status
        deleted
        lastRun
        lastCompletedRun {
          id
          productQueryid
          status
          dataVersion
          totalCount
          resultsData
          moved
          sourceid
          downloaded
          notificationCompleted
          metadata
          owners
          createdAt
          updatedAt
        }
        description
        products
        metadata
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        owners
        contract
        createdAt
        updatedAt
      }
      productQueryHistoryid
      productQueryHistory {
        id
        productQueryid
        productQueryidx {
          id
          name
          current
          status
          deleted
          lastRun
          description
          products
          metadata
          org
          owners
          contract
          createdAt
          updatedAt
        }
        status
        dataVersion
        dataVersionidx {
          version
          status
          metadata
          createdAt
          updatedAt
        }
        totalCount
        resultsData
        moved
        sourceid
        downloaded
        notificationCompleted
        metadata
        owners
        createdAt
        updatedAt
      }
      userid
      user {
        username
        metadata
        role
        email
        org
        organization {
          name
          status
          metadata
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      createdAt
      statusCode
      status
      size
      updatedAt
    }
  }
`;
