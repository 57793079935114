import React from 'react'
import { Auth } from 'aws-amplify'
import { BasicHNav, UserVNav } from '../navs'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import classnames from "classnames";
import { 
    Container,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button
  } from "reactstrap";


class Support extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            page: '',

        }

    }
    componentDidMount = () => {
        this.getUserGroups()
    }

    getUserGroups = async() => {
        const token = await Auth.currentAuthenticatedUser()
        if(token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Admins')){
                            this.setState({ page:  <>
                    <div className='g-0 d-none d-xs-none d-sm-none d-md-block '>
                    {/* <UserVNav /> */}
                    <div className='vbox'>
                        <BasicHNav />

                        <Container fluid className='ReportArea'>
                        </Container>
                    </div>
                    </div>
                    <div className=' d-lg-none d-xl-none d-lg-none d-md-none'>
                        {/* <UserVNav /> */}
                    </div>

                </>})
        } else {
            this.setState({ page:  <>
                <h3>Not Authorized</h3>
            </>})
        }
    }
    
    render  () {
        return(<>
            {this.state.page}
        </>)
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default Support