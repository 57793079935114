import React from 'react'
import { connect } from 'react-redux'
import { ProductQueryEditor } from '../productQuery'
import { onCreateProductQueryHistory, onUpdateProductQueryHistory, onUpdateProductQuery, onCreateProductQuery } from '../graphql/subscriptions'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Table, 
    Form,
    FormGroup,
    Label,
    Input,
    ButtonGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner
  } from "reactstrap";
import { UserHNav } from '../navs'

import { createProductQueryHistory, createDownload, updateProductQuery, updateProductQueryHistory } from '../graphql/mutations'
// import {  } from '../graphql/subscriptions'
// import { getRegindex, listRegindices, getProductQueryHistoryRuns } from '../graphql/queries'
import classnames from "classnames";

import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { listProductQueries, productQueryHistorysByproductQuery, listUsers, listOrganizations, dataVersionByStatus, getProductQuery, downloadsByproductQueryHistory } from '../graphql/queries'


class Registry extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            listkeytabledata: <></>,
            adminSelectOrgInput: <></>,
            adminSelectedOrg: '',
            adminSelectOrgUserInput: <></>,
            adminSelectedOrgUser: '',
            adminSelectedOrgFilteredUsers: [],
            activeDataVersion: {},
            authUsername: '',
            authUserData: {},
            authUserOrgData: {},
            authUserGroups: [],
            selectedvalue: '',
            selectedQuery: {},
            modals: [],
            selectedRegItem: {},
            selectedExecution: {},
            userQueries: [],
            allusers: [],
            allOrgs: [],
            displayFilteredQueries: <></>,
            lastRunDetails: <></>,
            selectedQueryDetailsRunHistory: <></>,
            AlertNotification: <></>,
            DownloadNotification: <></>,
            selectedExecutionDetails: <></>,
            downloadslist: <></>,
            adminRunQueryButton: <></>,
            restartExecutionBtn: <></>
        }
        this.GetUserInfo()
        this.getActiveDataVersion()
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        if(prevState.userQueries !== this.state.userQueries){
            this.displayQueryBlocks()
            this.updateUserQueries()
        } 

        if(prevState.allusers !== this.state.allusers){
            this.getOrgs()
        } 

        if(prevState.allOrgs !== this.state.allOrgs){
            this.displayOrgSelect()
        } 

        if( prevState.adminSelectedOrg !== this.state.adminSelectedOrg){
            console.log(this.state.adminSelectedOrg)
            this.displayUserSelect()
        }

        // if(prevState.adminQueryOrgOptions !== this.state.adminQueryOrgOptions){

        // } 
        
        // if(prevState.adminQueryOrgOptions !== this.state.adminQueryOrgOptions){

        // } 
        

        if(prevState.adminSelectedOrgUser !== this.state.adminSelectedOrgUser){
            this.displayQueryBlocks()

        }
        if(prevState.adminSelectOrgUserInput !== this.state.adminSelectOrgUserInput){
            this.displayQueryBlocks()
        }


        
        if(prevState.selectedQuery !== this.state.selectedQuery){
            this.renderQueryDetailsModal()
        }


        if(prevState.selectedExecution !== this.state.selectedExecution){
            this.renderSelectedExecutionDetails()
        }




        
        // props updates here

        if(prevProps.reportsearchinput !== this.props.reportsearchinput){
            console.log(this.props.reportsearchinput)
            // this.filterUserPermittedReports()
            this.displayQueryBlocks()

        }
    }


    submitRunToGQL = async() => {
        console.log(this.state.selectedQuery)
        this.toggleModals('ConfirmRun')
        let inputs = {}
        inputs['productQueryid'] = this.state.selectedQuery['id']
        inputs['dataVersion'] = this.state.activeDataVersion['version']
        inputs['status'] = 'Pending'
        inputs['moved'] = false
        inputs['notificationCompleted'] = false
        inputs['owners'] = this.state.selectedQuery['owners']
        inputs['metadata'] = {}
        inputs['metadata']['deployedToTarget'] = false
        inputs['metadata'] = JSON.stringify(inputs['metadata'] )

        console.log({inputs})
        const token = await Auth.currentAuthenticatedUser()
        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        if( groups.includes('Admins')){
            const newProductQueryRun = await API.graphql(graphqlOperation(createProductQueryHistory, {input: inputs}));
            console.log({newProductQueryRun})
        }
        this.renderSelectedExecutionDetails()
    }

    getActiveDataVersion = async() => {
        let dvData = await API.graphql({ query: dataVersionByStatus, variables: { status: 'Active', limit: 100,  }});
        // console.log({dvData})
        let dvItems = dvData.data.dataVersionByStatus.items;
        let pdata = dvData.data.dataVersionByStatus
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let dvData = await API.graphql({ query: dataVersionByStatus, variables: { status: 'Active', limit: 100, nextToken }});
            pdata = {}
            pdata = dvData.data.dataVersionByStatus
            dvItems = dvItems.concat(pdata.items)
        }
        this.setState({activeDataVersion: dvItems[0]})
    }

    uniq(a) {
        return Array.from(new Set(a));
     }

    updateAdminSelectedOrgFilter = (event) => {
        // console.log(JSON.parse(event.target.value))
        this.setState({adminSelectedOrg: JSON.parse(event.target.value)})
    }

    updateAdminSelectedOrgUserFilter = (event) => {
        console.log(event.target.value)
        this.setState({adminSelectedOrgUser: JSON.parse(event.target.value)})
    }

    displayQueryBlocks = () => {
        // console.log(this.state.userQueries)
        if( this.state.authUserGroups.includes('Admins')){
            // console.log("User Is Admin")
            this.setState({adminQueryOrgOptions: <>
                <Row>
                    <Col className='col-4 p-3'>
                        <Label for="orgSelect" className='adminOrgLabel'>
                            Organization
                        </Label>
                        <Input
                            id="orgSelect"
                            name="select"
                            type="select"
                            className='adminReportOrgSelectFilters'
                            onChange={e => this.updateAdminSelectedOrgFilter(e) }
                            >
                            {this.state.adminSelectOrgInput}
                        </Input>

                    </Col>
                    <Col className='col-4 p-3'>
                        <Label for="orgUserSelect" className='adminOrgLabel'>
                            User
                        </Label>
                        <Input
                            id="orgUserSelect"
                            name="select"
                            type="select"
                            className='adminReportOrgSelectFilters'
                            onChange={e => this.updateAdminSelectedOrgUserFilter(e) }
                            >
                            {this.state.adminSelectOrgUserInput}
                        </Input>
                    </Col>
                </Row>
            </>})
        } else {
            // console.log("User Is NOT Admin")
            this.setState({adminQueryOrgOptions: <></>})
        }

        this.filterUserPermittedReports()
    }

    componentDidMount() {
        this.getProductQuerys()
    }

    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        // this.getActiveRegions()
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
                
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            
            
        }
    };


    
    getUsers = async() => {
        // console.log('GetUsers')
        const filterstr = {
            or: [{status: { ne: 'Deleted'} },
                {status: { ne: 'Archived'} }
            ]
        }
        let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr,  }});
        let userItems = usersData.data.listUsers.items;
        let pdata = usersData.data.listUsers
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = usersData.data.listUsers
            userItems = userItems.concat(pdata.items)
        }

        let loggedInUserData = []
        let nonCurrentUsers = userItems.filter((user, index) => {
            if(user.username != this.state.authUsername){
                return(user)
            } else {
                console.log({user})
                loggedInUserData.push(user)
                this.setState({adminSelectedOrgUser: user})
                this.setState({authUserData: user})

            }
        })
        nonCurrentUsers.sort((a, b) => (a.username < b.username) ? 1 : -1)
        nonCurrentUsers.map((user) => {
            loggedInUserData.push(user)
        })
        this.setState({allusers: loggedInUserData})
    }

    getOrgs = async() => {
        // console.log('GetOrgs')
        const filterstr = {status: { ne: 'Archived'} }
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr,  }});
        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }
        let allOrgs = []
        const otherOrgs = orgItems.filter((org, index) => {
            if(org['name'] == this.state.authUserData['org']){
                allOrgs.push(org)
                this.setState({adminSelectedOrg: org})
                this.setState({authUserOrgData: org})
            } else{
                return(org)
            }
        })
        otherOrgs.sort((a, b) => (a.name < b.name) ? 1 : -1)
        otherOrgs.map((org) => {
            allOrgs.push(org)
        })
        // console.log({allOrgs})
        this.setState({allOrgs: allOrgs})
    }

    displayUserSelect = () => {
        // console.log('displayUserSelect')
        let filteredUsers = []
        filteredUsers = this.state.allusers.filter((user, index) => {
            if(this.state.adminSelectedOrg['name'] == user['org'] ){
                return(user)
            }
        })

        this.setState({adminSelectedOrgUser: filteredUsers[0] })

        // console.log({filteredUsers})
        this.setState({adminSelectOrgUserInput: filteredUsers.map((user, index) => <>
            <option key={user.id } value={JSON.stringify(user)}>
                {user.username}
            </option>
        </>)})
    }



    displayOrgSelect = () => {
        // console.log('displayOrgSelect')
        this.setState({adminSelectOrgInput: this.state.allOrgs.map((org, index) => <>
            <option key={org.name} value={JSON.stringify(org)}>
                {org.name}
            </option>
        </>)})
        this.displayUserSelect()

    }


    GetUserInfo = async() => {
        const token = await Auth.currentAuthenticatedUser()
        this.setState({authUsername: token['username'] })
        this.setState({authUserGroups: token['signInUserSession']['accessToken']['payload']['cognito:groups']}) 

        if( token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Admins')){
            this.getUsers()
        }

    }


    getProductQuerys = async() => {
        const filterstr = {status: { ne: 'Archived'} }
        let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr  }});
        let productItems = productData.data.listProductQueries.items;
        let pdata = productData.data.listProductQueries
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = productData.data.listProductQueries
            productItems = productItems.concat(pdata.items)
        }


        this.setState({userQueries: productItems})
        if( this.state.authUserGroups.includes('Admins')){
            API.graphql(
                graphqlOperation(onCreateProductQuery)
            ).subscribe({
                next: ({ provider, value }) => {
                    this.displayQueryBlocks()
                    console.log('onCreateProductQuery')
                    console.log({ provider, value })},
                error: error => console.warn(error)
            });

            API.graphql(
                graphqlOperation(onUpdateProductQuery)
            ).subscribe({
                next: ({ provider, value }) => {
                    this.displayQueryBlocks()
                    console.log('onUpdateProductQuery')
                    console.log({ provider, value })},
                error: error => console.warn(error)
            });

            // Subscribe to QueryHistory updates filter to current selected query

            const subToOnUpdateProductQueryHistory = API.graphql(
                graphqlOperation(onUpdateProductQueryHistory)
            ).subscribe({
                next: ({ provider, value }) => {
                    this.renderQueryDetailsModal()
                    console.log('onUpdateProductQueryHistory')
                    console.log({ provider, value })},
                error: error => console.warn(error)
            });

            const subToOnCreateProductQueryHistory = API.graphql(
                graphqlOperation(onCreateProductQueryHistory)
            ).subscribe({
                next: ({ provider, value }) => {
                    this.renderQueryDetailsModal()
                    console.log('onCreateProductQueryHistory')
                    console.log({ provider, value })},
                error: error => console.warn(error)
            });
        }

    }

    updateUserQueries = async() => {
        let lastRuns = await this.state.userQueries.map(async(query) => {
            //     let newQuery = query
            const filterstr = {
                status: { eq: 'Completed'} 
            }
            
            let pqhData = await API.graphql({ query: productQueryHistorysByproductQuery, variables: { productQueryid: query.id, filter: filterstr, limit: 1, sortDirection: 'DESC'   }});
            let pqhItems = pqhData.data.productQueryHistorysByproductQuery.items;
            if(pqhItems.length > 0){
                // console.log(pqhItems[0])
                // query['lastRun'] = pqhItems[0]
                return(pqhItems[0])

            } 
            else {
                // query['lastRun'] = {}
                return({})
            }
        })
        

        
        // console.log({lastRuns})

    }



    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
    }

    


    downloadBlob(results, filename) {
        // console.log('getResults: ', results)
        console.log('getResultsMetadataHTTPStatusCode: ', results['$metadata']['httpStatusCode'])
        
        let dlstatus = 'downloading'
        if( results['$metadata']['httpStatusCode'] == 200){
            dlstatus = 'Success'
            const blob = results.Body
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename || 'download';
            const clickHandler = () => {
                setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
                }, 150);
            };
            a.addEventListener('click', clickHandler, false);
            a.click();
            // return a;

        } else {
            dlstatus = 'Failed-' + results['$metadata']['httpStatusCode'].toString()
        }
        let output = {}
        output['status'] = dlstatus
        output['statusCode'] = results['$metadata']['httpStatusCode']
        return(output)
    }

    getSignedURL = async( run ) => {
        // console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']
        let filename = this.generateOutputFilename(run)
        let inputs = {}
        inputs['productQueryid'] = run['productQueryid']
        inputs['productQueryHistoryid'] =  run['id']
        inputs['userid'] = this.state.currentLoggedInUser
        const newDownload = await API.graphql(graphqlOperation(createDownload, {input: inputs}));
        try{
            await Storage.get(resultsData['s3DataFile'], { download: true }).then(res => this.downloadBlob(res, filename))
        }
        catch (error) {
            console.log({error})
            console.log("error throng getSignedURL")
        }
        this.getProductQueryHistoryDownloads(this.state.selectedPQHRunData['id'] )
    }


    generateOutputFilename = ( run ) => {
        console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']

        console.log(run['dataVersionidx']['createdAt'])
        const dvDate = run['dataVersionidx']['createdAt']
        const date = dvDate.split('-')[0] + dvDate.split('-')[1] 
        console.log(date)

        let filename = 'Emigrait-' + run['productQueryidx']['name'] + '-' + date

        if(csvsByRegion){
            filename = filename + '.zip'
        } else{
            filename = filename + '.csv'
        }
        return(filename)

    }

    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    getSignedModalURLWithProgress = async( run ) => {
        console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']
        let filename = this.generateOutputFilename(run)

        let inputs = {}
        inputs['productQueryid'] = run['productQueryid']
        inputs['productQueryHistoryid'] =  run['id']
        inputs['userid'] = this.state.authUsername

        let dlSize = 0
        let dlStatusCode = 0
        let dlStatus = 'waiting'

        this.setState({DownloadNotification: <>
            <div className="alert alert-info" role="alert">
                {/* <div clas */}
                
                <Spinner color="success">
                    Downloading...
                </Spinner>
                Downloading...
            </div>
        </>})

        try{
            const getresults = await Storage.get(resultsData['s3DataFile'], { download: true,
                progressCallback(progress) {
                    console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
                } }).then(res => this.downloadBlob(res, filename)).then( data => {
                if(data['status'] == 'Success'){
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    dlSize = data['ContentLength']
                    // this.setState({DownloadNotification: <>
                    //     <div className="alert alert-success" role="alert">
                    //         Completed
                    //     </div>
                    // </>})
                    // this.sleep(3000);
                    // this.setState({DownloadNotification: <></>})
                    // this.setState({productQueryHistoryDownloadButton: <>
                    //     <Button className="btn btn-outline-success" onClick = { e => this.downloadFromModal() } >Download</Button>
                    // </>})
                } else {
                    const errormsg = 'Error ' + data['statusCode']
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    dlSize = 0

                    // this.setState({productQueryHistoryDownloadButton: <>
                    //     <Button className="btn btn-outline-danger disabled"  > {errormsg} </Button>
                    // </>})
                    
                    this.setState({DownloadNotification: <></>})
                    this.setState({AlertNotification: <>
                        <div className="alert alert-danger" role="alert">
                        Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                            
                            <button onClick={ e => this.resetAlert() } type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </>})
                    }
                })
        } catch (error) {
            console.log({error})
            dlStatusCode = error['response']['status']
            dlStatus = error['response']['statusText']
            dlSize = 0

            // this.setState({productQueryHistoryDownloadButton: <>
            //     <Button className="btn btn-outline-danger disabled"  > Error </Button>
            // </>})
            
            this.setState({DownloadNotification: <></>})
            this.setState({AlertNotification: <>
                <div className="alert alert-danger" role="alert">
                Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                    
                    <button onClick={ e => this.resetAlert() } type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>})
        } finally {
            inputs['status'] = dlStatus
            inputs['statusCode'] =dlStatusCode.toString()
            // inputs['size'] = dlSize.toString()
            console.log({inputs})
            
            this.setState({DownloadNotification: <></>})

            const newDownload = await API.graphql(graphqlOperation(createDownload, {input: inputs}));
            console.log(newDownload)
        }
    }


    resetAlert = () => {
        this.setState({AlertNotification: <></>})
    }


    filterUserPermittedReports = async() => {
        console.log(this.state.adminSelectedOrg)
        console.log(this.state.userQueries)
        console.log(this.state.adminSelectedOrgUser)
        console.log(this.props.reportsearchinput)
        let userFilteredQueries = []
        if( this.state.authUserGroups.includes('Admins')){
            // let userFilteredQueries = 
            this.state.userQueries.map((query, index) => {
                if ( query.org == this.state.adminSelectedOrg.name && query.owners.includes(this.state.adminSelectedOrgUser.username)){
                        // console.log(this.props.reportsearchinput !== '' )
                        if(this.props.reportsearchinput !== '' ){
                            // console.log(this.props.reportsearchinput)
                            if(query.name.toLowerCase().includes(String(this.props.reportsearchinput).toLowerCase())){
                                userFilteredQueries.push(query)
                            }
                        } else {
                            userFilteredQueries.push(query)
                        }
                    }
                // }
            })
            // console.log({userFilteredQueries})
        } 
        else {
            console.log('Not Admin')
            // let userFilteredQueries = []
            this.state.userQueries.filter((query, index) => {
                console.log({query})

                if(this.props.reportsearchinput !== ''){
                    if(query.name.toLowerCase().includes(String(this.props.reportsearchinput).toLowerCase())){
                        // console.log({query})
                        userFilteredQueries.push(query)
                        // return(query)
                    }
                } else {
                    //     query['lastRun'] = await this.getLastProductQueryRun(query.id)
                    //     // console.log({query})
                    userFilteredQueries.push(query)
                }

            })
            // this.renderFilteredQueries(userFilteredQueries)
        }
        // console.log({userFilteredQueries})


        this.renderFilteredQueries(userFilteredQueries)


    }


    getRunRowcount = (run) => {
        console.log({run})

        if(run !== null){
            let metadata = JSON.parse(run['metadata'])
            if('rowcount' in metadata){
                return(metadata['rowcount'].toLocaleString())
            } else {
                return('0')
            }
        }
    }

    openQueryDetailsModal = (query) => {
        let modals = this.state.modals
        // console.log({query})
        const modalname = 'QueryDetails'
        if(modals.includes(modalname) ){
            // modals.splice(modals.indexOf(modalname))
            console.log('Modal is already open')
        } else {
            modals.push(modalname)
        }

        if( this.state.authUserGroups.includes('Admins')){
            this.setState({adminRunQueryButton: <>
                <ButtonGroup>
                    <Button className='btn-sm queryEditBtn' onDoubleClick={e => this.submitRunToGQL()}><i className="fas fa-cloud-bolt" /></Button>
                    { this.renderEditBtn(query)}
                </ButtonGroup>
            </> })
        } else {
            this.setState({adminRunQueryButton: <></>})
        }

        this.setState({modals: modals})
        this.setState({selectedQuery: query})




    }






    closeQueryDetailsModal = () => {
        let modals = this.state.modals
        const modalname = 'QueryDetails'
        // console.log('Close QueryDetails')
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            // modals.push(modalname)
            console.log('Modal is already closed')
        }
        this.setState({modals: modals})
        this.setState({selectedQuery: {}})


        // unsubscribe to QueryHistory updates to clean up

        // let subscription = this.state.subToOnUpdateProductQueryHistory
        // subscription.unsubscribe()
        // subscription = this.state.subToOnUpdateProductQueryHistory
        // subscription.unsubscribe()
        // this.state.subscriptionToOnCreateProductQueryHistory.unsubscribe()
        // this.state.subToOnUpdateProductQueryHistory.unsubscribe()


    }

    getLastProductQueryRun = async(queryid) => {
        let pqhItems = []
        const filterstr = {
            status: { eq: 'Completed'} 
        }
        let pqhData = await API.graphql({ query: productQueryHistorysByproductQuery, variables: { productQueryid: queryid, filter: filterstr, limit: 1, sortDirection: 'DESC'   }});
        pqhItems = pqhData.data.productQueryHistorysByproductQuery.items;
        if(pqhItems.length > 0){
            return(pqhItems[0])
        } 
        else {
            return({})
        }
    }

    
    downloadLastRun = async(queryid) => {
        let runData = await this.getLastProductQueryRun(queryid)
        // console.log({runData})
        this.getSignedModalURLWithProgress(runData)
    }

    renderEditBtn = (query) => {
        if( this.state.authUserGroups.includes('Admins')){
            return(<>
                <Button className='btn-sm queryEditBtn' onClick={ e => this.openEditQueryModal(query)} ><i className="fa-solid fa-pen-to-square"></i></Button>
            </>)
        } else {
            return(<></>)
        }
    }

    getDateFromQueryRun = (query) => {
        // console.log({query})
        let output = ''
        if( 'lastCompletedRun' in query){
            if(query.lastCompletedRun !== null){
                if('updatedAt' in query['lastCompletedRun']){
                    output = query.lastCompletedRun.updatedAt.split('T')[0]     
                }
            }
        }
        return(output)
    }

    getDataVersion = (query) => {
        console.log({query})
        let output = ''
        if( 'lastCompletedRun' in query){
            if(query.lastCompletedRun !== null){
                if('dataVersion' in query['lastCompletedRun']){
                    output = query.lastCompletedRun.dataVersion     
                }
            }
        }
        return(output)
    }



    renderFilteredQueries = (fq) => {
        // console.log({fq})
        this.setState({ displayFilteredQueries: fq.map((query) => {
            return(<>
                <Col className=''>
                    <Card className='queryInfoBox'>
                        {/* <CardHeader>
                        </CardHeader> */}
                        <CardBody>
                            
                            <CardTitle className='queryInfoTitle'>
                                {query.name}
                            </CardTitle>
                            <Row>
                                <Col className='col-sm'>
                                    <p className=' queryReportCardSecondaryInfo'>
                                        Last Updated: { this.getDateFromQueryRun(query) }
                                        {/* Last Updated: { query.updatedAt.split('T')[0] } */}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-sm'>
                                    <p className=' queryReportCardSecondaryInfo'>
                                        Size: { this.getRunRowcount(query.lastCompletedRun) }
                                    </p>
                                </Col>
                            </Row>

                            <Button className='btn-sm queryInfoButton'  onClick={ e => this.openQueryDetailsModal(query) } >More Info</Button>
                            <Button className='btn-sm queryDownloadButton'  onClick={e => this.downloadLastRun(query.id)} >Download</Button>
                            {this.renderEditBtn(query)}
                        </CardBody>
                    </Card>
                </Col>
            </>)

            })
        })
    }

    returnProductDetails = (query, detail) => {
        let productData = JSON.parse(query.products)
        // console.log(detail)

        let value = ''
        if(detail == 'grouping'){
            if('queryOptions' in productData){
                const options = productData.queryOptions
                if( 'GroupByRegion' in options){
                    if(options.GroupByRegion == true){
                        value = 'True'
                    } else {
                        value = 'False'
                    }
                } else {
                    value = 'False'
                }

            } else {
                value = 'False'
            }
        } else if( detail == 'regions'){
            console.log(detail)
            if( 'RegionalScore' in productData){
                if( 'Consumers' in productData.RegionalScore){
                    // console.log(productData.RegionalScore.Consumers)
                    let regionsString = ''
                    productData.RegionalScore.Consumers.map((region) => {
                        // console.log({region})
                        if(regionsString == ''){
                            regionsString = regionsString + region.regionName
                        } else {
                            regionsString = regionsString + ', ' + region.regionName
                        }

                    })
                    value = regionsString

                } else {
                    value = ''
                }
            } else {
                value = ''
            }

        }

        return( value )
    }

    renderQueryDetailsModal = () => {
        // this.state.selectedQuery
        if('id' in this.state.selectedQuery){
            console.log(this.state.selectedQuery)
            this.setState({lastRunDetails:  <>
                <div className='QueryDetailsModalTextArea'>
                    {/* <p className=' queryDetailsTextFormat'> */}
                        {/* Last Updated: {this.state.selectedQuery.lastCompletedRun.updatedAt.split('T')[0] } */}
                        {/* Last Updated: { this.getDateFromQueryRun(this.state.selectedQuery) } */}
                    {/* </p> */}
                    <p className=' queryDetailsTextFormat'>
                        Size: { this.getRunRowcount(this.state.selectedQuery.lastCompletedRun) }
                    </p>
                    <p className=' queryDetailsTextFormat'>
                        Most Current Data Version: { this.getDataVersion(this.state.selectedQuery) }
                    </p>
                    {/* <p className=' queryDetailsTextFormat'>
                        Stay Current: { this.isCurrent(this.state.selectedQuery.current) }
                    </p> */}
                    {/* <p className=' queryDetailsTextFormat'>
                        Split Regions On Download: { this.returnProductDetails(this.state.selectedQuery, 'grouping') }
                    </p>
                    <p className=' queryDetailsTextFormat'>
                        Regions: { this.returnProductDetails(this.state.selectedQuery, 'regions') }
                    </p> */}
                </div>
                
            </>})
            this.getQueryDownloads(this.state.selectedQuery.id)
        } else {
            this.setState({lastRunDetails: <></>})
            this.setState({selectedQueryDetailsRunHistory: <></>})
        }
    }

    getQueryDownloads = async(queryid) => {
        console.log({queryid})
        
        let pqhItems = []
        const filterstr = {}
        let limitedRows = 12
        if( this.state.authUserGroups.includes('Admins')){
            limitedRows = 20
        } else {
            filterstr['status'] = { eq: 'Completed'} 
            
        }
        console.log({filterstr})
        let pqhData = await API.graphql({ query: productQueryHistorysByproductQuery, variables: { productQueryid: queryid, filter: filterstr, limit: limitedRows, sortDirection: 'DESC'   }});
        pqhItems = pqhData.data.productQueryHistorysByproductQuery.items;
        console.log({pqhItems})
        if(pqhItems.length > 0){
            this.setState({selectedQueryDetailsRunHistory: pqhItems.map((item) => <>
                <ListGroupItem className='queryRunListItem'>
                    <Row className='p-0 g-0'>
                        <Col className='p-0 g-0 col-8'>
                            <p className='p-0 g-0 queryRunListTextFormat'>Status: {item.status}</p>
                            <p className='p-0 g-0 queryRunListTextFormat'>DataVersion: {item.dataVersion}</p>
                            <p className='p-0 g-0 queryRunListTextFormat'>Updated: {item.updatedAt.split('T')[0] }</p>
                        </Col>
                        <Col className='p-0 g-0 col-4 queryRunButtonGroupArea d-flex align-content-center'>
                            <ButtonGroup>
                                <Button className='queryRunDownloadButton' onClick={e => this.getSignedModalURLWithProgress(item)}>
                                    <i className="fas fa-download"></i>
                                </Button>
                                <Button className='queryRunInfoButton' onClick={ e => this.openExecutionDetailsModal(item)}>
                                    <i className="fas fa-info"></i>                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </ListGroupItem>
            </>)})

        } 
        else {
                this.setState({selectedQueryDetailsRunHistory: <></>})
            }
    }

    openExecutionDetailsModal = (execution) => {
        let modals = this.state.modals
        console.log({execution})
        const modalname = 'ExecutionDetails'
        if(modals.includes(modalname) ){
            // modals.splice(modals.indexOf(modalname))
            console.log('Modal is already open')
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
        this.setState({selectedExecution: execution})
        if( this.state.authUserGroups.includes('Admins')){
            this.setState({restartExecutionBtn: <><Button className='btn-danger' onClick={ e => this.restartExecution(execution) }>Restart</Button></>})
        } else {
            this.setState({restartExecutionBtn: <></>})
        }
    }

    restartExecution = async(execution) => {
        console.log({execution})

        // delete execution['createdAt']
        // delete execution['updatedAt']
        // delete execution['productQueryidx']
        // delete execution['dataVersionidx']
        // delete execution['productQueryid']
        // delete execution['dataVersion']
        // delete execution['totalCount']
        // delete execution['resultsData']
        // delete execution['moved']
        // delete execution['sourceid']
        // delete execution['downloaded']
        // delete execution['notificationCompleted']


        

        let inputs = {}
        inputs['id'] = execution.id
        inputs['status'] = 'Restart'
        console.log({execution})
        // try {
        const updatedProductQuery = await API.graphql(graphqlOperation(updateProductQueryHistory, {input: inputs}))
        console.log({updatedProductQuery})
        // } catch (error) {
        //     console.log({error})
        // }
        

    }


    closeExecutionDetailsModal = () => {
        let modals = this.state.modals
        const modalname = 'ExecutionDetails'
        console.log('Close ExecutionDetails')
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            // modals.push(modalname)
            console.log('Modal is already closed')
        }
        this.setState({modals: modals})
        this.setState({selectedExecution: {}})
        this.setState({restartExecutionBtn: <></>})

    }


    openEditQueryModal = (selectedQuery) => {
        let modals = this.state.modals
        const modalname = 'EditProductQuery'
        if(modals.includes(modalname) ){
            console.log('Modal is already open')
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})

        this.props.updateSelectedProductQueryData(selectedQuery)

    }


    closeEditQueryModal = () => {
        let modals = this.state.modals
        const modalname = 'EditProductQuery'
        console.log('Close EditProductQuery')
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            console.log('Modal is already closed')
        }
        this.setState({modals: modals})
        this.props.updateSelectedProductQueryData({})

    }


    renderSelectedExecutionDetails = () => {
        if( 'id' in this.state.selectedExecution) {            
            this.setState({selectedExecutionDetails: <>
                <p className=' queryDetailsTextFormat'>
                    Updated: {this.state.selectedExecution.updatedAt.split('T')[0] }
                </p>
                <p className=' queryDetailsTextFormat'>
                    Size: { this.getRunRowcount(this.state.selectedExecution) }
                </p>
                <p className=' queryDetailsTextFormat'>
                    Data Version: {this.state.selectedExecution.dataVersion }
                </p>
            </>})
            this.getProductQueryHistoryDownloads(this.state.selectedExecution.id)
        } else {
            this.setState({selectedExecutionDetails: <></>})
            this.setState({downloadslist: <></>})

        }

    }

    
    getProductQueryHistoryDownloads = async(pqhid) => {
        console.log({pqhid})

        let dlData = await API.graphql({ query: downloadsByproductQueryHistory, variables: { productQueryHistoryid: pqhid, limit: 10, sortDirection: 'DESC'  }});
        // console.log({dvData})
        let dlItems = dlData.data.downloadsByproductQueryHistory.items;
        // console.log({dlItems})
        console.log({dlItems})

        if(dlItems.length > 0){
            const downloadslist = dlItems.map((download, index) => <>
                <tr>
                    <td>
                        {download.userid}
                    </td>
                    <td>
                        {download.createdAt}
                    </td>
                </tr>
            </>)

            this.setState({downloadslist: <>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                User
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {downloadslist}
                    </tbody>
                </Table>
            </>})
        } else{
            this.setState({downloadslist: <>
                <h3>Downloads</h3>
                <hr />
                <Table>
                    <tbody>
                        <tr>
                            <h4 className='queryDetailsTextFormat'>Not Downloaded</h4>
                        </tr>
                    </tbody>
                </Table>
            </>})
        }

    }

    render  () {
        return( <>
            <Modal backdrop="static" isOpen={this.state.modals.includes('EditProductQuery')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('EditProductQuery')}>×</button>}>(ADMINS ONLY)  - Edit Query</ModalHeader>
                <ModalBody>
                    <ProductQueryEditor />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('EditProductQuery') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('ExecutionDetails')} className="modal-lg modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.closeExecutionDetailsModal()}>×</button>}>
                    File Details
                    <p className='p-0 g-0 queryDetailsTextFormat'>{this.state.selectedQuery.name}</p>
                </ModalHeader>
                <ModalBody className=''>
                    <Row>
                        <Col>
                            { this.state.selectedExecutionDetails }
                        </Col>
                        <Col>
                            { this.state.downloadslist}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {/* { this.state.restartExecutionBtn } */}
                    <Button color="secondary" onClick={ e => this.closeExecutionDetailsModal() }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('QueryDetails')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.closeQueryDetailsModal()}>×</button>}>
                    More Info
                    <p className='p-0 g-0 queryDetailsTextFormat'>{this.state.selectedQuery.name}</p>
                </ModalHeader>
                <ModalBody className='moreInfoModalBody moreInfoModal'>
                    <div className='p-0 g-0 modalBoxLeft float-left'>
                        {this.state.lastRunDetails}
                    </div>
                    <div className='modalSidebarRight float-right '>
                        <Row>
                            <Col>
                                <h3>History</h3>
                            </Col>
                            <Col>
                                { this.state.adminRunQueryButton }
                            </Col>
                        </Row>
                        <ListGroup flush >
                            { this.state.selectedQueryDetailsRunHistory }
                        </ListGroup>
                    </div>
                    
                    {/* <ProductQueryEditor /> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.closeQueryDetailsModal() }>Close</Button>
                </ModalFooter>
            </Modal>
        <UserHNav />
        {this.state.AlertNotification}
        {this.state.DownloadNotification}
        <Container fluid className='ReportArea'>
            { this.state.adminQueryOrgOptions }
            <Row>
                { this.state.displayFilteredQueries }
            </Row>
        </Container>
        </>)
    }
};


const mapStateToProps = state => {
    console.log({state})
    return {
        reportsearchinput: state.reportsearchinput,
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
        updateSelectedProductQueryData: (event) => {
            console.log('UPDATESSELECTEDPRODUCTQUERYDATA to props event: ', event)
            dispatch({type: 'UPDATESSELECTEDPRODUCTQUERYDATA', 
                selectedProductQuery: event
            })
        }
    }
}


export default connect( mapStateToProps, mapDispatchToProps)(Registry);