import React from 'react'
import { connect } from 'react-redux'

import { Auth } from 'aws-amplify'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Nav, 
    Navbar,
    NavItem,
    NavLink, 
    NavbarBrand,
    NavbarToggler,
    Container,
    Input,
    Button,
    ButtonGroup,
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    InputGroup
  } from "reactstrap";

  import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  

class UserHNav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""],
            currentUserName: "",
            currentUserGroups: [],
            userDropdown: false,
            searchInput: '',
            UserNavIcon: <></>
        }
        this.GetUserInfo()
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    GetUserInfo = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        this.setState({ currentUserName: token['username']})
        this.setState({currentUserGroups: groups})

        if(groups.includes('Admins')){
          this.setState({UserNavIcon: <>
            <span className='userNameDisplay'>{this.state.currentUserName}  (Admin) </span>
            <i className="fa-solid fa-user-tie" />
          </> })
        } else {
          this.setState({UserNavIcon: <>
            <span className='userNameDisplay'>{this.state.currentUserName}</span>
            <i className="fas fa-user" />
          </> })

        }
      }

      logOut(e) {
        e.preventDefault()
        Auth.signOut()
          .then(data => console.log(data))
          .catch(err => console.log(err));
          window.location.reload(false);
      }

      toggleUserDropdown = () => {
        this.setState({userDropdown: !this.state.userDropdown})
      }

      updateSearchInput = (event) => {
        this.setState({searchInput: event.target.value})
        this.props.updateReportSearchInput(event.target.value)
      }

      logOut(e) {
        e.preventDefault()
        Auth.signOut()
          .then(data => console.log(data))
          .catch(err => console.log(err));
          window.location.reload(false);
      }

    

    render  () {


        return( 
          <div className='g-0 p-0 d-none d-xs-none d-sm-none d-md-block d-lg-block'>
            <Navbar className="navbar-horizontal userHNav" expand="md">
              <Input type='search' className='userNavSearchbox  d-none d-xs-none d-sm-none d-md-block' placeholder='Search Reports' onChange={e => this.updateSearchInput(e)}>Search</Input>    
              <Container className='g-0 p-0 justify-content-end'>
                <ButtonGroup>
                  <Button className="userHnavColor">
                    <i className="fas fa-bell"></i> 
                  </Button>
                  <ButtonDropdown toggle={e => this.toggleUserDropdown() } className="userHnavColor" isOpen={this.state.userDropdown}>
                    <DropdownToggle className="userHnavColor">
                      {this.state.UserNavIcon}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>
                        <DropdownItem onClick={e => this.logOut(e)}><i className="fas fa-sign-out-alt"></i> Log Out</DropdownItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonGroup>
              </Container>
            </Navbar>
          </div>
        )
    }
};

const mapStateToProps = state => {
  // console.log({state})
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updateReportSearchInput: (event) => {
          // console.log('UPDATEREPORTSEARCHINPUT to props event: ', event)
          dispatch({type: 'UPDATEREPORTSEARCHINPUT', 
              reportsearchinput: event
          })
      }
  }
}

export default connect( mapStateToProps, mapDispatchToProps)(UserHNav);