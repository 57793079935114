import React from 'react'
import { API, graphqlOperation } from 'aws-amplify'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { updateProduct } from '../graphql/mutations'
import { listProducts } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button, 
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";
  import NewProductForm from './productsForm'


class RankProducts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            products: [],
            modals: [],
            selectedProductData: {},
            displaySelectedProductData: <></>,
            displayNameInput: <></>,
            displayRankInput: <></>,
            displayTypeInput: <></>,
            displaySQLInput: <></>,
            displayDatabaseNameInput: <></>,
            displayDatabaseNameInput: <></>,
            displayProductColsInput: <></>,
            displaySubmitBtn: <></>,
            productEditInputs: [],
            SelectedProductName: '',
            SelectedProductRank: '',
            SelectedProductType: '',
            SelectedProductDatabase: '',
            SelectedProductTable: '',
            SelectedProductTableCols: '',
            SelectedProductProductCols: '',
            SelectedProductSQL: '',
            prevState: '',
        }
        this.getProducts()

    }

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        // console.log('New state', this.state.SecondaryTypeOpen); // After update 
        console.log("componentDidUpdate")

        console.log(this.state.productEditInputs)
        console.log( prevState.productEditInputs )

        if(prevState.selectedProductData !== this.state.selectedProductData){
            this.displayProductData()

        } 
        if(prevState.SelectedProductName !== this.state.SelectedProductName){
            this.displayNameInput()
        } 
        if(prevState.SelectedProductRank !== this.state.SelectedProductRank){
            this.displayRankInput()
        } 
        if(prevState.productEditInputs !== this.state.productEditInputs){
            // this.displayProductData()
        } 
        if(prevState.SelectedProductType !== this.state.SelectedProductType){
            this.displayTypeInput()
        } 
        if(prevState.SelectedProductSQL !== this.state.SelectedProductSQL){
            this.displaySQLInput()
        } 
        if(prevState.SelectedProductDatabase !== this.state.SelectedProductDatabase){
            this.displayDatabaseNameInput()
        } 
        if(prevState.SelectedProductTable !== this.state.SelectedProductTable){
            this.displayTableNameInput()
        } 
        if(prevState.SelectedProductProductCols !== this.state.SelectedProductProductCols){
            this.displayProductColsInput()
        } 


        
    }

    // componentWillUpdate = (nextProps, nextState) => {
    //     console.log("ComponentWillUpdate")
    //     console.log(nextState.productEditInputs)
    //     console.log(this.state.productEditInputs)
    //     if(nextState.productEditInputs !== this.state.productEditInputs){
    //         this.displayProductData()
    //     } 
    // }

    


    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
        }
    };

    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
        this.setState({productEditInputs: []})

    }

    getProducts = async() => {
        let productData = await API.graphql({ query: listProducts, variables: { limit: 100,  }});
        let productItems = productData.data.listProducts.items;
        let pdata = productData.data.listProducts
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProducts, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = productData.data.listProducts
            productItems = productItems.concat(pdata.items)
        }
        console.log({productItems})
        let byRank = productItems.sort((a, b) => (parseInt(a.rank) > parseInt(b.rank)) ? 1 : -1)
        console.log({byRank})
        this.displayProducts(productItems)
    }

    loadProductModal = (data) => {
        this.toggleModals("ProductDetails")
        data['metadata'] = JSON.parse(data['metadata'])
        this.setState({selectedProductData: data})
    }

    EditInputToggle = ( input ) => {

        let inputs = this.state.productEditInputs
        if(inputs.includes(input) ){
            inputs.splice(inputs.indexOf(input))
        } else {
            inputs.push(input)
        }
        console.log({inputs})
        this.setState({productEditInputs: inputs})

        if(input == "productName"){
            this.displayNameInput()
        } else if( input == "productRank"){
            this.displayRankInput()
        } else if( input == "productType"){
            this.displayTypeInput()
        } else if( input == "productSQL"){
            this.displaySQLInput()
        } else if( input == "productDatabaseName"){
            this.displayDatabaseNameInput()
        } else if( input == "productTable"){
            this.displayTableNameInput()
        } else if( input == "productColNames"){
            this.displayProductColsInput()
        } else if(inputs = []) {
            this.displayNameInput()
            this.displayRankInput()
            this.displayTypeInput()
            this.displaySQLInput()
            this.displayDatabaseNameInput()
            this.displayTableNameInput()
            this.displayProductColsInput()
        }

        if(inputs.length > 0){
            this.setState({displaySubmitBtn: <>
                <Button onClick={e => this.handleSumbit(this.state)} className="btn">
                    Submit
                </Button>
            </>})
        } else {
            this.setState({displaySubmitBtn: <></>})
        }
    }

    updateFormNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductName: event.target.value})
    }

    updateFormRankInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductRank: event.target.value})
    }

    updateFormTypeInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductType: event.target.value})
    }

    updateFormSQLInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductSQL: event.target.value})
    }

    
    updateFormDatabaseNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductDatabase: event.target.value})
    }

    updateFormProductColsInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductProductCols: event.target.value})
    }
    updateFormTableNameInput = ( event ) => {
        console.log(event.target.value)
        this.setState({SelectedProductTable: event.target.value})
    }


    displayNameInput = () => {
        if(this.state.productEditInputs.includes("productName") ){
            this.setState({displayNameInput: <>
                <tr>
                    <td> <h3>Name: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductName}
                            onChange={e => this.updateFormNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productName") }><i className="fas fa-close" /> </Button>
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayNameInput: <>
                <tr>
                    <td> <h3>Name: </h3></td>
                    <td>
                        <FormGroup>

                            <Input
                            id='productName'
                            value={this.state.SelectedProductName}
                            disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productName") }><i className="fas fa-edit" /> </Button>
                    </td>
                </tr>
            </>})
        }
    }


    displayRankInput = () => {
        if(this.state.productEditInputs.includes("productRank") ){
            this.setState({displayRankInput: <>
                <tr>
                    <td><h3>Rank: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductRank}
                            onChange={e => this.updateFormRankInput( e )}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        {/* <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productRank") }><i className="fas fa-close" /> </Button> */}
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayRankInput: <>
                <tr>
                    <td> <h3>Rank: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductRank}
                            disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        {/* <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productRank") }><i className="fas fa-edit" /> </Button> */}
                    </td>
                </tr>
            </>})
        }
    }

    
    displayTypeInput = () => {
        if(this.state.productEditInputs.includes("productType") ){
            this.setState({displayTypeInput: <>
                <tr>
                    <td><h3>Type: </h3></td>
                    <td>
                        <FormGroup>
                                <Input
                                id="SelectType"
                                placeholder="Select Type"
                                name="typeSelect"
                                type="select"
                                onChange={ e => this.updateFormTypeInput(e)}
                                value={this.state.SelectedProductType}
                                >
                                    <option>
                                        SingleScore
                                    </option>
                                    <option>
                                        RegionalScore
                                    </option>
                                    <option>
                                        ColumnGroup
                                    </option>
                                    <option>
                                        ColumnSelect
                                    </option>
                                    <option>
                                        CustomQuery
                                    </option>
                                    <option>
                                        SQLWrapper
                                    </option>
                                    <option>
                                        DataFrameOperation
                                    </option>
                                </Input>
                        </FormGroup>
                    </td>
                    <td>
                        {/* <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productType") }><i className="fas fa-close" /> </Button> */}
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayTypeInput: <>
                <tr>
                    <td> <h3>Type: </h3></td>
                    <td>
                    <FormGroup>
                                <Input
                                id="SelectType"
                                placeholder="Select Type"
                                name="typeSelect"
                                type="select"
                                value={this.state.SelectedProductType}
                                disabled
                                >
                                    <option>
                                        SingleScore
                                    </option>
                                    <option>
                                        RegionalScore
                                    </option>
                                    <option>
                                        ColumnGroup
                                    </option>
                                    <option>
                                        ColumnSelect
                                    </option>
                                    <option>
                                        CustomQuery
                                    </option>
                                    <option>
                                        SQLWrapper
                                    </option>
                                    <option>
                                        DataFrameOperation
                                    </option>
                                </Input>
                        </FormGroup>
                    </td>
                    <td>
                        {/* <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productType") }><i className="fas fa-edit" /> </Button> */}
                    </td>
                </tr>
            </>})
        }
    }




    displaySQLInput = () => {
        if(this.state.productEditInputs.includes("productSQL") ){
            this.setState({displaySQLInput: <>
                <tr>
                    <td><h3>SQL: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="sqlString"
                                name="sqlString"
                                type="textarea"
                                value={this.state.SelectedProductSQL} 
                                onChange={this.updateFormSQLInput}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productSQL") }><i className="fas fa-close" /> </Button>
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displaySQLInput: <>
                <tr>
                    <td> <h3>SQL: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="sqlString"
                                name="sqlString"
                                type="textarea"
                                value={this.state.SelectedProductSQL} 
                                disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productSQL") }><i className="fas fa-edit" /> </Button>
                    </td>
                </tr>
            </>})
        }
    }

    displayDatabaseNameInput = () => {
        if(this.state.productEditInputs.includes("productDatabaseName") ){
            this.setState({displayDatabaseNameInput: <>
                <tr>
                    <td> <h3>Database: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductDatabase}
                            onChange={e => this.updateFormDatabaseNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productDatabaseName") }><i className="fas fa-close" /> </Button>
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayDatabaseNameInput: <>
                <tr>
                    <td> <h3>Database: </h3></td>
                    <td>
                        <FormGroup>

                            <Input
                            id='productName'
                            value={this.state.SelectedProductDatabase}
                            disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productDatabaseName") }><i className="fas fa-edit" /> </Button>
                    </td>
                </tr>
            </>})
        }
    }
    displayTableNameInput = () => {
        if(this.state.productEditInputs.includes("productTable") ){
            this.setState({displayTableNameInput: <>
                <tr>
                    <td> <h3>Table: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                            id='productName'
                            value={this.state.SelectedProductTable}
                            onChange={e => this.updateFormTableNameInput( e )}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productTable") }><i className="fas fa-close" /> </Button>
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayTableNameInput: <>
                <tr>
                    <td> <h3>Table: </h3></td>
                    <td>
                        <FormGroup>

                            <Input
                            id='productName'
                            value={this.state.SelectedProductTable}
                            disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productTable") }><i className="fas fa-edit" /> </Button>
                    </td>
                </tr>
            </>})
        }
    }

    displayProductColsInput = () => {
        if(this.state.productEditInputs.includes("productColNames") ){
            this.setState({displayProductColsInput: <>
                <tr>
                    <td><h3>Table Columns: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="productColNamesString"
                                name="productColNames"
                                type="textarea"
                                value={this.state.SelectedProductProductCols} 
                                onChange={this.updateFormProductColsInput}
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline' onClick={e => this.EditInputToggle("productColNames") }><i className="fas fa-close" /> </Button>
                    </td>
                </tr>
            </>})
        } else {
            this.setState({displayProductColsInput: <>
                <tr>
                    <td> <h3>Table Columns: </h3></td>
                    <td>
                        <FormGroup>
                            <Input
                                id="productColNamesString"
                                name="productColNames"
                                type="textarea"
                                value={this.state.SelectedProductProductCols} 
                                disabled
                            />
                        </FormGroup>
                    </td>
                    <td>
                        <Button className='btn btn-sm btn-outline-warning' onClick={e => this.EditInputToggle("productColNames") }><i className="fas fa-edit" /> </Button>
                    </td>
                </tr>
            </>})
        }
    }



    displayProductData = () => {
        let data = this.state.selectedProductData
        console.log({data})
        console.log(data['type'])
        let productEditInputs = []
        this.setState({productEditInputs: productEditInputs })

        console.log({productEditInputs})
        this.setState({SelectedProductName: data['name']  })
        this.setState({SelectedProductRank: data['rank']  })
        this.setState({SelectedProductType: data['type']  })

        


        if(data['type'] == "ColumnGroup"){
            this.setState({displaySQLInput: <></>})
            this.setState({SelectedProductDatabase: data['metadata']['database']  })
            this.setState({SelectedProductTable: data['metadata']['table']  })
            this.setState({SelectedProductTableCols: data['metadata']['tableCols']  })
            this.setState({SelectedProductProductCols: data['metadata']['productCols']  })
        } else if(data['type'] == "SingleScore"){
            this.setState({displaySQLInput: <></>})
            this.setState({SelectedProductDatabase: data['metadata']['database']  })
            this.setState({SelectedProductTable: data['metadata']['table']  })
            this.setState({SelectedProductTableCols: data['metadata']['tableCols']  })
            this.setState({SelectedProductProductCols: data['metadata']['productCols']  })
        } else if(data['type'] == "RegionalScore"){
            this.setState({displaySQLInput: <></>})
            this.setState({SelectedProductDatabase: data['metadata']['database']  })
            this.setState({SelectedProductTable: data['metadata']['table']  })
            this.setState({SelectedProductTableCols: data['metadata']['tableCols']  })
            this.setState({SelectedProductProductCols: data['metadata']['productCols']  })

        } else if(data['type'] == "SQLWrapper"){
            this.setState({displayDatabaseNameInput: <></>})
            this.setState({displayTableNameInput: <></>})
            this.setState({displayProductColsInput: <></>})
            this.setState({SelectedProductSQL: data['metadata']['sql']  })
        } else if(data['type'] == "CustomQuery"){
            this.setState({displayDatabaseNameInput: <></>})
            this.setState({displayTableNameInput: <></>})
            this.setState({displayProductColsInput: <></>})
            this.setState({SelectedProductSQL: data['metadata']['sql']  })
        }


    }
        

    displayProducts = (productsData) => {
        this.setState({products: productsData})
        // console.log({productsData})
        this.setState({productsTable: productsData.map((ct, index) => <>
            <tr key={ct.id ? ct.id : index}
                aria-selected={this.state.reqNavPills === index}
                classnames="table-hover mb-sm-3 mb-md-0"
                role="tab"
                >
                <td>{ct.name}</td>
                <td>{ct.type}</td>
                <td>{String(ct.rank)}</td>
                <td><Button className="btn btn-sm btn-outline-info" onClick={e => this.loadProductModal(ct)}><i className="fas fa-info" /></Button></td>
                <td><Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveUpProductRank( index ) }><i className="fas fa-angle-double-up" /></Button></td>
                <td><Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveDownProductRank( index ) }><i className="fas fa-angle-double-down" /></Button></td>
            </tr>
            </>)
        })
    }

    array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        console.log({arr})
        this.displayProducts(arr)
        
        // return arr; 
    };

    moveUpProductRank = (index) => {
        let tmpDataarray = this.state.products
        // console.log({tmpDataarray})
        if (index > 0) {
            this.array_move(tmpDataarray, index, index - 1)
        }
        // console.log({tmpDataarray})
    }

    moveDownProductRank = (index) => {
        let tmpDataarray = this.state.products
        // console.log({tmpDataarray})
        if (index < tmpDataarray.length - 1) {
            this.array_move(tmpDataarray, index, index + 1)
        }
        // console.log({tmpDataarray})
    }

    assignCurrentRanks = async(rankItems) => {
        console.log({rankItems})
        rankItems.map(async(item, index) => {
            let inputs = {}
            inputs['id'] = item['id']
            inputs['rank'] = index
            console.log({inputs})
            const updatedProduct = await API.graphql(graphqlOperation(updateProduct, {input: inputs}));
            console.log({updatedProduct})
        })
    }

    handleSumbit = async(state) => {

        // console.log(state)
        // console.log(state.selectedProductData.id)
        // console.log(state.productEditInputs)

        let inputs = {}
        inputs['id'] = state.selectedProductData.id

        let metadata = {}

        if(state.productEditInputs.includes("productName")){
            inputs['name'] = state.SelectedProductName

        } 
        // if(productEditInputs.includes("productRank")){
        //     inputs['rank'] = state.SelectedProductRank
        // }
        // if(productEditInputs.includes("productType")){
        //     inputs['type'] = state.SelectedProductType

        // } 
        if(state.productEditInputs.includes("productSQL")){
            metadata['sql'] = state.SelectedProductSQL
        } 
        if(state.productEditInputs.includes("productDatabaseName")){
            metadata['database'] = state.SelectedProductDatabase
        } 
        if(state.productEditInputs.includes("productTable")){
            metadata['table'] = state.SelectedProductTable
        } 
        if(state.productEditInputs.includes("productColNames")){
            metadata['colnames'] = state.SelectedProductProductCols
        }

        console.log({metadata})

        inputs['metadata'] = JSON.stringify(metadata)

        console.log({inputs})
        const updatedProduct = await API.graphql(graphqlOperation(updateProduct, {input: inputs}));
        console.log({updatedProduct})
        // this.setState({productEditInputs: [] })


    }

    render  () {


        return( <>
            <Modal backdrop="static" isOpen={this.state.modals.includes('ProductDetails')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('ProductDetails')}>×</button>}>Product Details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Form>
                                <Table className='table table-sm'>
                                    <tbody>
                                        {this.state.displayNameInput}
                                        {this.state.displayRankInput}
                                        {this.state.displayTypeInput}
                                        {this.state.displaySQLInput}
                                        {this.state.displayDatabaseNameInput}
                                        {this.state.displayTableNameInput}
                                        {this.state.displayProductColsInput}
                                    </tbody>
                                </Table>
                                {this.state.displaySubmitBtn}
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('ProductDetails') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('ProductForm')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('ProductForm')}>×</button>}>Product Details</ModalHeader>
                <ModalBody>
                    <NewProductForm />

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('ProductForm') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("ComputeTemplate")}
                aria-expanded={this.state.openedCollapses.includes("ComputeTemplate")}>
                    
                    <Row>
                        <Col className='col-9'>
                            <CardTitle className="h2 mb-0">Products</CardTitle>
                        </Col>
                        <Col className='col-1' >
                            <Button className='btn btn-md' color="secondary" onClick={ e => this.toggleModals('ProductForm') }>+</Button>
                        </Col>
                        <Col className='col-1' >
                            <Button className='btn btn-md' color='success' onClick={ e => this.assignCurrentRanks(this.state.products)}>Rank</Button>
                        </Col>
                    </Row>
                </CardHeader>
                    <CardBody>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Rank</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.productsTable}
                            </tbody>
                        </Table>
                    </CardBody>
            </Card>
        </>
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default RankProducts