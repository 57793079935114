import React from 'react'
import { Auth } from 'aws-amplify'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button
  } from "reactstrap";
import { AdminNav } from '../navs'

class Maintenance extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            page: '',

        }

    }
    componentDidMount = () => {
        this.getUserGroups()
    }

    getUserGroups = async() => {
        const token = await Auth.currentAuthenticatedUser()
        if(token['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Users')){
            this.setState({ page:  <>
                <div className=''>
                    <h3 className='align-center'> This Website is currenly under maintenance. Please come back soon</h3>                </div>
                
            </>})
        } else {
            this.setState({ page:  <>
                <h3>Not Authorized</h3>
            </>})
        }
    }
    
    render  () {
        return(<>
            {this.state.page}
        </>)
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default Maintenance