import React from 'react'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    InputGroup,
    Input
} from "reactstrap";
import { API, graphqlOperation } from 'aws-amplify'
// import { getRegionsByStatus } from '../../customgql/customqueries'
import {  listRegions, regionsByType } from '../graphql/queries.js'
import { updateRegion } from '../graphql/mutations.js'

class RegionsActivator extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""],
            StateRegions: [],
            allRegions: [],
            opendStateRegionsCollapse: "",
            ActiveStates: "",
            stateRegionsARR: [""]
        }
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.getActiveRegions()
        this.getAllStates()
    }





    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        // this.getActiveRegions()
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
                
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            
            
        }
    };
    


    getAllStates = async() => {



        let regionsResponse = await API.graphql({ query: regionsByType, variables: {type: "State"}})
        // console.log(regionsResponse)
        let notDone = true
        // let regions = regionsResponse.data.listRegions
        // console.log(regionsResponse.data.regionsByParentRegion.items)
        // let nextToken = null
        // nextToken = regionsResponse.data.regionsByParentRegion.nextToken
        let statesArray = []
        let stateitems = regionsResponse.data.regionsByType.items
        statesArray = statesArray.concat(stateitems)
        while(notDone){
            
            if(regionsResponse.data.regionsByType.nextToken == null){
                notDone = false
            } else{
                regionsResponse = await API.graphql({ query: regionsByType, variables: {type: "State", nextToken: regionsResponse.data.listRegions.nextToken}})
                // regions = regionsResponse.data.regionsByParentRegion
                // console.log(regionsResponse.data.regionsByParentRegion.items)
                // nextToken = regionsResponse.data.regionsByParentRegion.nextToken
                stateitems = regionsResponse.data.regionsByType.items
                statesArray = statesArray.concat(stateitems)
                // console.log(countysArray)
            }
        }
        statesArray = statesArray.sort(function ( a, b ) {
            if ( a.regionName < b.regionName ){
                return -1;
            }
            if ( a.regionName > b.regionName ){
                return 1;
            }
            return 0;
        })
        this.setState({StateRegions: statesArray})
        this.displayActiveRegions()
        // console.log(statesArray)
    }




    // getActiveRegions = async() => {
    //     const filterstr = {
    //         status: {
    //             eq: "Active" // filter priority = 1
    //         },
    //         type: {
    //             eq: "State"
    //         }
    //     }
    //     const ActiveRegions = await API.graphql({ query: listRegions, listRegions: {filter: filterstr} })
    //     console.log(ActiveRegions["data"]['listRegions']['items'])
        
    //     let statesArray = [ActiveRegions["data"]['listRegions']['items']]
    //     statesArray = statesArray.sort(function ( a, b ) {
    //         if ( a.regionName < b.regionName ){
    //             return -1;
    //         }
    //         if ( a.regionName > b.regionName ){
    //             return 1;
    //         }
    //         return 0;
    //     })
    //     console.log(statesArray[0])
    //     statesArray= statesArray[0]
    // }

    toggleRegionActivation = async(state) => {
        console.log(state)
        let regionDetails = {}
        regionDetails['id'] = state.id
        if(state.status === 'Active'){
            regionDetails['status'] = 'Inactive'
        } else{
            regionDetails['status'] = 'Active'
        }
        const updatedState = await API.graphql({ query: updateRegion, variables: {input: regionDetails}});
        // console.log(updatedState)
        this.getAllStates()
    }

    displayRegionActivationCheckbox = (status) => {
        let returnval = false
        if(status === 'Active'){
            returnval = true
        } else{
            returnval = false
        }
        return(returnval)
    }


    displayActiveRegions = () => {
        this.setState({  ActiveStates: this.state.StateRegions.map((state,index) => (<>
            <tr key={index}>
                <td>{state.regionName}</td>
                <td><Input key={index} addon onChange={(e) => this.toggleRegionActivation(state)} type="checkbox" aria-label={state.regionCol} checked={ this.displayRegionActivationCheckbox(state.status) }/></td>
            </tr>
            </>
        ))
    })
    }



    render  () {


        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("regionCreator")}
                aria-expanded={this.state.openedCollapses.includes("regionCreator")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Regions Activater</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("regionCreator")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("regionCreator")}>
                    <CardBody>
                        <Row>
                            <Col>
                                <table>
                                    <tr key='header'>
                                        <th>State</th>
                                        <th>Active</th>
                                    </tr>
                                    {this.state.ActiveStates}    
                                </table>
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default RegionsActivator;