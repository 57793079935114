import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/vendor/bootstrap-rtl/bootstrap-rtl.scss"
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/sweetalert2/sweetalert2.min.css";
import "./assets/vendor/select2/css/select2.min.css";
import "./assets/vendor/@fortawesome/css/all.min.css";

import "./assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import './index.css';
import App from './App';

// amplify imports
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import { Col } from 'reactstrap';

// redux imports
import { createStore } from 'redux';
import { Provider } from 'react-redux'

import reducer from './store/reducer'
import classnames from "classnames";



Amplify.configure(awsExports);


const store = createStore(reducer)

ReactDOM.render(
  <AmplifyAuthenticator>
    <AmplifySignIn
      headerText="Data Portal Sign In"
      slot="sign-in"
      hideSignUp='true'
    ></AmplifySignIn>
    <React.StrictMode>
      <Provider store={store}>
      <div classnames="container-fluid p-0">
        <App />
      </div>
      </Provider>
      
    </React.StrictMode>
  </AmplifyAuthenticator>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
