import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { onCreateOrganization, onUpdateOrganization } from '../graphql/subscriptions'

import ListContracts from './contracts'
import { connect } from 'react-redux'

// import ReactDOM from 'react-dom';
import { listOrganizations, usersByOrg } from '../graphql/queries'
import { createOrganization, createUser, updateOrganization } from '../graphql/mutations'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    ListGroup,
    ListGroupItem
  } from "reactstrap";


class ListOrgs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [],
            organizations: [],
            modals: [],
            selectedOrgUsers: [],
            orgInfo: '',
            userInfo: '',
            orgsSelect: [],
            selectedOrg: {},
            promoteNewUser: false,
            Username: '',
            OrgName: '',
        }
        this.getOrganizations()
        const UpdateOrganization = API.graphql(
            graphqlOperation(onUpdateOrganization)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getOrganizations()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const CreateOrganization = API.graphql(
            graphqlOperation(onCreateOrganization)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getOrganizations()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
    }


    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        // console.log('New state', this.state.SecondaryTypeOpen); // After update 
        if(prevState.selectedOrgUsers !== this.state.selectedOrgUsers){
            this.displayOrgUsers()
        } 

        if(prevState.selectedOrg !== this.state.selectedOrg){
            this.props.updateSelectedOrganizationData(this.state.selectedOrg)
        } 

        // console.log({prevProps})
        // console.log({prevState})
    }


    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
        }
        this.getOrganizations()

    };

    handleOrgNameChange = event => {
        this.setState({OrgName: event.target.value})
    }

    handleNewOrgSumbit = async(state) => {
        console.log(state)

        const token = await Auth.currentAuthenticatedUser()
        let inputs = {
            name: state.OrgName,
            status: 'Active'
        }
        this.setState({OrgName: ''})

        const newOrg = await API.graphql(graphqlOperation(createOrganization, {input: inputs}));
        console.log({newOrg})
        window.location.reload();

    }

    getOrganizations = async() => {
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100,  }});

        if(orgData.data.listOrganizations != null) {


        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }

        console.log({orgItems})
        this.setState({organizations: orgItems})

            let orgSelections = [{id: '0', name:'Select Org'}, ...orgItems]
            this.setState({orgsSelect: orgSelections.map((org, index) => (
                    <option key={org.id ? org.id : index}>
                        {org.name}
                    </option>
                ))
            });


            this.setState({orgsTable: orgItems.map((ct, index) => (
                        <tr key={ct.id ? ct.id : index}
                            aria-selected={this.state.reqNavPills === index}
                            className={classnames("table-hover mb-sm-3 mb-md-0", {
                                active: this.state.reqNavPills === index
                            })}
                            role="tab"
                            >
                            <td>{ct.name}</td>
                            <td><Button className='btn-sm' color='info' onClick={ e => this.toggleModals('OrgDetails', ct)} >Details</Button></td>
                        </tr>
                ))
            });
        } else {
            this.setState({orgsTable: <></> })
        }
    }


    toggleModals = ( modalname, data ) => {
        console.log({modalname})
        console.log({data})
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
        } else {
            modals.push(modalname)
            if(modalname == 'OrgDetails'){
                this.setState({selectedOrg: data})
                this.displayModalOrgData(data)
                this.getOrgUsers(data)
            } else if(modalname == 'UserDetails'){
                this.setState({selectedOrg: data})
                this.displayModalUserData(data)
                // this.getOrgUsers(data)
            }
        }
        console.log({modals})
        this.setState({modals: modals})
    }

    displayModalUserData = (user) => {
        this.setState({ userInfo: <>
            <h3>user info</h3>
            
            <li>Name: {user.username}</li>
            <li>Role: {user.role}</li>
        </>})
    }

    displayModalOrgData = (org) => {
        this.setState({ orgInfo: <>
            <h3>org info</h3>
            <li>Name: {org.name}</li>
        </>})
    }

    displayOrgUsers = () => {
        this.setState({orgUsersList: this.state.selectedOrgUsers.map((user, index) => <>
            <ListGroupItem action key={index}  onClick={e => this.toggleModals('UserDetails', user)}>
                {user.username}
            </ListGroupItem>
        </>)})
    }

    getOrgUsers = async(orgInfo) => {
        console.log({orgInfo})

        let usersRawData = await API.graphql({ query: usersByOrg, variables: { org: orgInfo['name'], limit: 100,  }});
        // console.log(CTData)
        let userItems = usersRawData.data.usersByOrg.items;
        let Userdata = usersRawData.data.usersByOrg
        // console.log({Userdata})
        // console.log(userItems.length)
        // console.log({userItems})
        while(Userdata.nextToken != null ){
            let nextToken = Userdata.nextToken;
            usersRawData = await API.graphql({ query: usersByOrg, variables: { org: orgInfo['name'], limit: 100, nextToken }});
            Userdata = {}
            Userdata = usersRawData.data.usersByOrg
            userItems = userItems.concat(Userdata.items)
        }
        console.log({userItems})
        this.setState({selectedOrgUsers: userItems})
    }

    handleOrgSelect = (event) => {
        let orgs = this.state.organizations
        let org = orgs.filter((org, index) => {
            return org.name = event.target.value
        })
        console.log(org[0])
        this.setState({ selectedOrg: org[0] })
    }

    handleEmailChange =(event) => {
        console.log(event.target.value)
        this.setState({email: event.target.value})
    }

    handleRoleChange =(event) => {
        console.log(event.target.value)
        this.setState({role: event.target.value})
    }

    handleUsernameChange = event => {
        this.setState({Username: event.target.value})
        // console.log(event.target.value)
    }

    handleNewUserSumbit = async(state) => {
        let inputs = {}
        inputs['username'] = state.Username
        console.log(state.selectedOrg)
        inputs['org'] = state.selectedOrg['name']
        inputs['email'] = state.email
        inputs['status'] = 'Active'

        if(this.state.promoteNewUser){
            inputs['role'] = 'orgAdmin'
        } else {
            inputs['role'] = 'orgUser'
        }

        const newUser = await API.graphql(graphqlOperation(createUser, {input: inputs}));
        console.log({newUser})
        this.getOrgUsers(state.selectedOrg)
        this.toggleModals("NewUser", {})

        // update org with new username in users
        let orgusers = []
        console.log(state.selectedOrg)
        if('users' in state.selectedOrg){
            console.log('Org has Users col')
            if(state.selectedOrg['users'] !== null){
                orgusers = state.selectedOrg['users']
            }
            
        }
        console.log(orgusers)

        orgusers.push(state.Username)
        let orginputs = {
            name: state.selectedOrg['name'],
            users: orgusers
        }

        console.log({orginputs})
        this.setState({OrgName: ''})

        const newOrg = await API.graphql(graphqlOperation(updateOrganization, {input: orginputs}));
        console.log({newOrg})


    }


    promoteNewUser = (event) => {
        // console.log(event.target.value)
        console.log(!this.state.promoteNewUser)
        this.setState({promoteNewUser: !this.state.promoteNewUser})
    }

    render  () {


        return( <>
            <Modal backdrop="static" isOpen={this.state.modals.includes('AddNewOrg')} className="modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('AddNewOrg')}>×</button>}>Add New Organization</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label for="OrgName" sm={2}>
                            Org Name
                            </Label>
                            <Col sm={10}>
                                <Input
                                id="OrgName"
                                name="OrgName"
                                placeholder="Org Name"
                                value={this.state.OrgName} 
                                onChange={this.handleOrgNameChange}
                                />
                            </Col>
                        </FormGroup>
                        <Button onClick={e => this.handleNewOrgSumbit(this.state)} className="btn">
                            Submit
                        </Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('AddNewOrg') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('OrgDetails')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('OrgDetails', {})}>×</button>}>Org Details</ModalHeader>
                <ModalBody>
                    {/* <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleRunArgsChange} />  */}
                    <Row>
                        <Col>
                            {this.state.orgInfo}
                        </Col>
                        <Col>
                            <Row>
                                <Col><h3>Org Users</h3></Col>
                                <Col><Button className='btn-md' color='success' onClick={e => this.toggleModals('NewUser', {})}><i className="fas fa-plus" /></Button></Col>
                            </Row>
                            <br />
                            <ListGroup classname="list-group-flush">
                                {this.state.orgUsersList}
                            </ListGroup>
                        </Col>
                    </Row>
                    <ListContracts />
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="danger" onDoubleClick={e => this.submitRunToGQL()}>Run</Button>{' '} */}
                    <Button color="secondary" onClick={ e => this.toggleModals('OrgDetails', {}) }>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('NewUser')} >
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('NewUser', {})}>×</button>}>Add User</ModalHeader>
                <ModalBody>
                    
                    <Form>
                        <FormGroup row>
                            <Label for="Username" sm={2}>
                            Username
                            </Label>
                            <Col sm={10}>
                                <Input
                                id="Username"
                                name="Username"
                                placeholder="Username"
                                value={this.state.Username} 
                                onChange={this.handleUsernameChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="email" sm={2}>
                                Email
                            </Label>
                            <Col sm={10}>
                                <Input
                                id="email"
                                name="email"
                                placeholder="email"
                                value={this.state.email} 
                                onChange={this.handleEmailChange}
                                type='email'
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" value={this.state.promoteNewUser} onClick={e => this.promoteNewUser(e) } />
                            <Label check>
                                Promote To Org Admin
                            </Label>
                        </FormGroup>
                        <Button onClick={e => this.handleNewUserSumbit(this.state)} className="btn">
                            Submit
                        </Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="danger" onDoubleClick={e => this.submitRunToGQL()}>Run</Button>{' '} */}
                    <Button color="secondary" onClick={ e => this.toggleModals('NewUser', {}) }>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('UserDetails')} >
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('UserDetails', {})}>×</button>}>User Info</ModalHeader>
                <ModalBody>
                    {/* <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleRunArgsChange} />  */}
                    <Row>
                        {/* <Col><h3>User Info</h3></Col> */}
                        <Col>{this.state.userInfo}</Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="danger" onDoubleClick={e => this.submitRunToGQL()}>Run</Button>{' '} */}
                    <Button color="secondary" onClick={ e => this.toggleModals('UserDetails', {}) }>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("ComputeTemplate")}
                aria-expanded={this.state.openedCollapses.includes("ComputeTemplate")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Organizations</CardTitle>
                        </Col>
                        <Col className='col-2' >
                            <Button color="secondary" onClick={ e => this.toggleModals('AddNewOrg') }>+</Button>
                        </Col>
                    </Row>
                </CardHeader>
                    <CardBody>
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.orgsTable}

                            </tbody>
                        </Table>
                    </CardBody>
            </Card>
        
            </>)
    }
};

const mapStateToProps = state => {
    // console.log({state})
    return {
        selectedOrg: state.selectedOrg,  
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedOrganizationData: (event) => {
            console.log('UPDATESSELECTEDORGANIZATION to props event: ', event)
            dispatch({type: 'UPDATESSELECTEDORGANIZATION', 
            selectedOrg: event
            })
        }
    }
}



// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));
export default connect( mapStateToProps, mapDispatchToProps)(ListOrgs) 
// export default ListOrgs