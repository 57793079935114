import React from 'react'

import NewDataVersionBtn from './newDataVersionBtn'
import ListDataVersionsBtn from './listDataVersions'
import ActivateNewDataVersionBtn from './activateNewDataVersionBtn'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse
  } from "reactstrap";


class DataVersion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""]
        }
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
    }





    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        // this.getActiveRegions()
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            const index = openedCollapses.indexOf(collapse)
            openedCollapses.splice(index, 1);
            this.setState({
                openedCollapses: openedCollapses
                
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
            
            
        }
    };





    render  () {


        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("Admin")}
                aria-expanded={this.state.openedCollapses.includes("Admin")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Data Version Tools</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("Admin")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("Admin")}>
                    <CardBody>
                        <NewDataVersionBtn />
                        <ListDataVersionsBtn />
                        <ActivateNewDataVersionBtn />
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default DataVersion;