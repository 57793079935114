import React from 'react'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { connect } from 'react-redux'
import ProductQueryEditor from './productQueryEditor'

// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
import { createProductQueryHistory, updateProductQueryHistory, createDownload, createProductQuery, updateProductQuery } from '../graphql/mutations'
import { onCreateProductQueryHistory, onUpdateProductQueryHistory, onUpdateProductQuery, onCreateProductQuery } from '../graphql/subscriptions'
import { listProductQueries, productQueryHistorysByproductQuery, listUsers, listOrganizations, dataVersionByStatus, getProductQuery, downloadsByproductQueryHistory } from '../graphql/queries'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    Input,
    Label,
    Form,
    FormGroup,
    Table,
    Button,
    Navbar,
    NavItem,
    Nav,
    NavLink,
    Container,
    ListGroup,
    ListGroupItem,
    Badge,
    NavbarBrand,
    NavbarText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Progress
  } from "reactstrap";




class ListProductQuery extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: ["PrimaryCollapse"],
            productQueries: [],
            modals: [],
            AdminNav: '',
            currentLoggedInUser: '',
            currentLoggedInUserData: {},
            currentLoggedInUserGroups: [],
            lisProductQueries: '',
            currentSelectedUser: '',
            selectedPQ: {},
            pqDisplay: '',
            scoreTable: [],
            adminSelectUserDropdown: '',
            adminSelectOrgFilterUserDropdown: '',
            adminUserFilterByOrg: {},
            allOrgs: [],
            selectedOrgFilteredUsers: [],
            confirmRun: false,
            activeDataVersion: 0,
            selectedPQHRunData: {},
            mostRecentPQHRun: {},
            mostRecentPQHDisplay: <></>,
            currentLoggedInUserOrg: {},
            downloadsProgress: {},
            downloadslist: <></>,
            productQueryHistoryDownloadButton: <></>,
            MostRecentproductQueryHistoryDownloadButton: <></>,
            AlertNotification: <></>
        }
        this.getActiveDataVersion()
        this.getOrgs()
        this.getUser()
        this.getProductQuerys()



        const UpdateProductQueryHistory = API.graphql(
            graphqlOperation(onUpdateProductQueryHistory)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getProductQueryHistoryRuns()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const CreateProductQueryHistory = API.graphql(
            graphqlOperation(onCreateProductQueryHistory)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getProductQueryHistoryRuns()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
    }


    componentDidUpdate = (prevProps, prevState) => {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        if(prevState.currentLoggedInUserGroups !== this.state.currentLoggedInUserGroups){
            this.displayAdminNavbar()
            this.displayProductQueries()
        } 
        if(prevState.AdminGodMode !== this.state.AdminGodMode){
            this.displayAdminNavbar()
        } 
        if(prevState.productQueries !== this.state.productQueries){
            this.displayProductQueries()
        } 
        if(prevState.selectedPQ !== this.state.selectedPQ){
            this.displayAdminNavbar()
            this.displayProductQueries()
            // this.displaySelectedPQ()
            this.getProductQueryHistoryRuns()
        } 
        if(prevState.currentSelectedUser !== this.state.currentSelectedUser){
            this.displayProductQueries()
        } 
        if(prevState.userstable !== this.state.userstable){
            this.displayUserSelect()
        } 
        if(prevState.currentLoggedInUserData !== this.state.currentLoggedInUserData){
            this.getOrgs()
        } 
        if(prevState.allOrgs !== this.state.allOrgs){
            this.displayOrgSelect()
        } 
        if(prevState.adminUserFilterByOrg !== this.state.adminUserFilterByOrg){
            this.displayUserSelect()
            this.onOrgChangeUpdateSelectedUser()
        } 
        if(prevState.adminSelectUserDropdown !== this.state.adminSelectUserDropdown){
            this.displayAdminNavbar()
        } 
        if(prevState.selectedOrgFilteredUsers !== this.state.selectedOrgFilteredUsers){
            this.onOrgChangeUpdateSelectedUser()
        } 
        if(prevState.confirmRun !== this.state.confirmRun){
            this.displayRunButton()
        } 
        if(prevState.modals !== this.state.modals){
            this.displayRunButton()
        }
        if(prevState.selectedPQRunHistory !== this.state.selectedPQRunHistory){
            this.displaySelectedPQRunHistory()
        }
        
        if(prevState.selectedPQHRunData !== this.state.selectedPQHRunData){
            this.displaySelectedPQRunHistory()
            this.displayProductQueryHistoryDetails()
        }
        if(prevState.MostRecentproductQueryHistoryDownloadButton !== this.state.MostRecentproductQueryHistoryDownloadButton){
            this.displayMostRecentPQHRun()
        }
        if(prevState.mostRecentPQHRun !== this.state.mostRecentPQHRun){
            this.displayMostRecentButton()
            // this.displayMostRecentPQHRun()
        }
        
        if(prevState.mostRecentPQHDisplay !== this.state.mostRecentPQHDisplay){
            this.displaySelectedPQ()
        }

        if(prevState.AlertNotification !== this.state.AlertNotification){
            this.displaySelectedPQ()
        }



        
        
    }

    updateSelectedPQ = async() => {
        const pq = await API.graphql(graphqlOperation(getProductQuery, { id: this.state.selectedPQ['id'] }));
        this.onSelectPQ(pq)
    }

    setInput(key, value) {
        this.setState([key], value)
    }

    boolToString(value){
        if(value){
            return('Yes')
        } else {
            return('No')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            openedCollapses.splice(openedCollapses.indexOf(collapse), 1);
            this.setState({
                openedCollapses: openedCollapses
            });
        } else {
            this.setState({
                openedCollapses: [ ...this.state.openedCollapses, collapse]
            });
        }
    };

    getProductQuerys = async() => {
        const filterstr = {
            or: [{status: { ne: 'Deleted'} },
                {status: { ne: 'Archived'} }
            ]
        }
        let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr  }});
        let productItems = productData.data.listProductQueries.items;
        let pdata = productData.data.listProductQueries
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let productData = await API.graphql({ query: listProductQueries, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = productData.data.listProductQueries
            productItems = productItems.concat(pdata.items)
        }
        console.log({productItems})
        this.setState({productQueries: productItems})
    }

    getUser = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const username = token.username
        this.setState({currentLoggedInUser: username})
        this.setState({currentSelectedUser: username})


        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        this.setState({currentLoggedInUserGroups: groups})
        if( groups.includes('Admins')){
            this.setState({AdminGodMode: false})
            this.getUsers()
        }
    }

    
    getUsers = async() => {
        const filterstr = {
            or: [{status: { ne: 'Deleted'} },
                {status: { ne: 'Archived'} }
            ]
        }
        let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr,  }});
        let userItems = usersData.data.listUsers.items;
        let pdata = usersData.data.listUsers
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let usersData = await API.graphql({ query: listUsers, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = usersData.data.listUsers
            userItems = userItems.concat(pdata.items)
        }

        let loggedInUserData = []
        let nonCurrentUsers = userItems.filter((user, index) => {
            if(user.username != this.state.currentLoggedInUser){
                return(user)
            } else {
                loggedInUserData.push(user)
                this.setState({currentLoggedInUserData: user})
            }
        })
        nonCurrentUsers.sort((a, b) => (a.username < b.username) ? 1 : -1)
        nonCurrentUsers.map((user) => {
            loggedInUserData.push(user)
        })
        this.setState({userstable: loggedInUserData})
    }

    getOrgs = async() => {
        const filterstr = {
            or: [{status: { ne: 'Deleted'} },
                {status: { ne: 'Archived'} }
            ]
        }
        let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr,  }});
        let orgItems = orgData.data.listOrganizations.items;
        let pdata = orgData.data.listOrganizations
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let orgData = await API.graphql({ query: listOrganizations, variables: { limit: 100, filter: filterstr, nextToken }});
            pdata = {}
            pdata = orgData.data.listOrganizations
            orgItems = orgItems.concat(pdata.items)
        }

        let allOrgs = []
        const otherOrgs = orgItems.filter((org, index) => {
            // console.log({org})
            // console.log(this.state.currentLoggedInUserData)
            if(org['name'] == this.state.currentLoggedInUserData['org']){
                allOrgs.push(org)
                this.selectOrgUserFilter(org['name'])

            } else{
                return(org)
            }
        })
        otherOrgs.sort((a, b) => (a.name < b.name) ? 1 : -1)
        otherOrgs.map((org) => {
            allOrgs.push(org)
        })
        // console.log({allOrgs})
        this.setState({currentLoggedInUserOrgData: allOrgs[0]})
        this.setState({allOrgs: allOrgs})
    }

    displayUserSelect = () => {
        let filteredUsers = []
        // console.log(this.state.adminUserFilterByOrg)
        let orgid = this.state.adminUserFilterByOrg['name']
        filteredUsers = this.state.userstable.filter((user, index) => {
            if(orgid == user['org'] ){
                return(user)
            }
        })
        this.setState({selectedOrgFilteredUsers: filteredUsers})
        this.setState({adminSelectUserDropdown: filteredUsers.map((user, index) => <>
            <option key={user.id}>
                {user.username}
            </option>
        </>)})
    }


    toggleAdminMode = () => {
        // console.log(!this.state.AdminGodMode)
        if(this.state.AdminGodMode){
            this.setState({currentSelectedUser: this.state.currentLoggedInUser})
            this.selectOrgUserFilter(this.state.currentLoggedInUserOrgData['name'])
            // console.log(this.state.currentLoggedInUserOrgData)
        }

        this.setState({AdminGodMode: !this.state.AdminGodMode})
    }

    toggleEditModal = () => {
        this.toggleModals('EditProductQuery')
        console.log('EditProductQuery')
    }

    displayAdminNavbar = async() => {
        if( this.state.currentLoggedInUserGroups.includes('Admins')){
            let buttonVal = ''
            let userType = '  User'

            if(this.state.AdminGodMode){
                buttonVal = <>
                    <NavLink className='btn adminNavMargin' onClick={() => this.toggleAdminMode("ISADMIN")}>
                        <i className="fas fa-cogs colorAdminModeWarning inline"></i>
                    </NavLink>
                </>
                userType = '  Admin'
            } else {
                buttonVal = <>
                    <NavLink className='btn adminNavMargin' onClick={() => this.toggleAdminMode("ISADMIN")}>
                        <i className="fas fa-user-shield inline" ></i>
                    </NavLink>
                </>
                userType = '  User'
            }

            if(this.state.selectedPQ.hasOwnProperty('id')){
                if(this.state.AdminGodMode){
                    this.setState({AdminNav: <>
                        <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter navheight' color="dark">
                            <Nav className='mr-auto '>
                                {/* <NavLink className='btn'>
                                    By Org
                                </NavLink> */}
                            </Nav>
                            <Nav className='ml-auto '>
                                <NavLink className='btn adminNavMargin' onClick={() => this.toggleEditModal()}>
                                    Edit
                                </NavLink>
                                <NavLink className='btn adminNavMargin' onClick={e=> this.RunSelectedPQ()}>
                                    Run
                                </NavLink>
                                <NavItem className='adminNavMargin'>
                                    <Form inline>
                                        <FormGroup>
                                            <Input
                                            id="selectOrg"
                                            name="selectOrg"
                                            type="select"
                                            className='form-control-sm'
                                            onChange={e => {this.handleSelectOrgUserFilter(e)}}
                                            >
                                                {this.state.adminSelectOrgFilterUserDropdown}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </NavItem>
                                <NavItem className='adminNavMargin'>
                                    <Form inline>
                                        <FormGroup>
                                            <Input
                                            id="selectUser"
                                            name="selectUser"
                                            type="select"
                                            className='form-control-sm'
                                            onChange={e => {this.selectAlternateUser(e)}}
                                            >
                                                {this.state.adminSelectUserDropdown}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </NavItem>
                                {buttonVal}
                            </Nav>
                        </Navbar>
                    </>})
                } else {
                    this.setState({AdminNav: <>
                        <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter navheight' color="dark">
                            <Nav className='mr-auto '>
                                {/* <NavLink className='btn'>
                                    By Org
                                </NavLink> */}
                            </Nav>
                            <Nav className='ml-auto '>
                                <NavLink className='btn adminNavMargin' onClick={() => this.toggleEditModal()}>
                                    Edit
                                </NavLink>
                                <NavLink className='btn adminNavMargin' onClick={e=> this.RunSelectedPQ()}>
                                    Run
                                </NavLink>
                                {buttonVal}
                            </Nav>
                        </Navbar>
                    </>})
                }
            } else {
                if(this.state.AdminGodMode){
                    this.setState({AdminNav: <>
                        <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter navheight' color="dark">
                            <Nav className='mr-auto '>
                                {/* <NavLink className='btn'>
                                    By Org
                                </NavLink> */}
                            </Nav>
                            <Nav className='ml-auto'>
                                <NavItem className='adminNavMargin'>
                                    <Form inline>
                                        <FormGroup>
                                            <Input
                                            id="selectOrg"
                                            name="selectOrg"
                                            type="select"
                                            className='form-control-sm'
                                            onChange={e => {this.handleSelectOrgUserFilter(e)}}
                                            >
                                                {this.state.adminSelectOrgFilterUserDropdown}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </NavItem>
                                <NavItem className='adminNavMargin'>
                                    <Form inline>
                                        <FormGroup>
                                            <Input
                                            id="selectUser"
                                            name="selectUser"
                                            type="select"
                                            className='form-control-sm'
                                            onChange={e => {this.selectAlternateUser(e)}}
                                            >
                                                {this.state.adminSelectUserDropdown}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </NavItem>
                                {buttonVal}
                            </Nav>
                        </Navbar>
                    </>})
                } else {
                    this.setState({AdminNav: <>
                        <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter navheight' color="dark">
                            <Nav className='mr-auto '>
                                {/* <NavLink className='btn'>
                                    By Org
                                </NavLink> */}
                            </Nav>
                            <Nav className='ml-auto'>
                                {buttonVal}
                            </Nav>
                        </Navbar>
                    </>})
                }
            }
        } 
        else if(this.state.currentLoggedInUserGroups.includes('Users')){
            if(this.state.selectedPQ.hasOwnProperty('id') & this.state.currentLoggedInUserGroups.includes('UserExecuteGroup')){
                this.setState({AdminNav: <>
                    <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter navheight' color="dark">
                        <Nav className='ml-auto '>
                            <NavLink className='btn adminNavMargin' onClick={e=> this.RunSelectedPQ()}>
                                Run
                            </NavLink>
                        </Nav>
                    </Navbar>
                </>})
            } else {
                    this.setState({AdminNav: <>
                        {/* <Navbar dark container="sm" className='text-white bg-dark py-0 no-gutter' color="dark">
                            <Nav className='ml-auto'>
                            </Nav>
                        </Navbar> */}
                    </>})
                }
        }
    }

    selectAlternateUser = (event) => {
        this.setState({currentSelectedUser: event.target.value})
        this.setState({pqDisplay: <></>})
        this.setState({displayPQHruns: <></>})
    }

    handleSelectOrgUserFilter = (event) => {
        this.selectOrgUserFilter(event.target.value)
        
    }

    onOrgChangeUpdateSelectedUser = () => {
        if(this.state.selectedOrgFilteredUsers.length > 0){
            this.setState({currentSelectedUser: this.state.selectedOrgFilteredUsers[0]['username']})
        }
    }

    selectOrgUserFilter = (filtername) => {
        // console.log({filtername})
        let orgdata = {}
        this.state.allOrgs.map((org) => {
            if(org['name'] == filtername){
                orgdata = org
            }
        })
        this.setState({pqDisplay: <></>})
        this.setState({displayPQHruns: <></>})
        // console.log({orgdata})
        this.setState({adminUserFilterByOrg: orgdata})
    }

    displayOrgSelect = () => {
        this.setState({adminSelectOrgFilterUserDropdown: this.state.allOrgs.map((org, index) => <>
            <option key={org.id}>
                {org.name}
            </option>
        </>)})
    }

    displayProductQueries = () => {
        let productQueries = this.state.productQueries
        // console.log(this.state.AdminGodMode)
        let filteredpq = productQueries.filter((pq, index) => {
            if(pq.owners.includes(this.state.currentSelectedUser)){
                return(pq)
            }
        })

        let displayArr = filteredpq.map((pq, index) => {
            if(this.state.selectedPQ.hasOwnProperty('id')){
                // console.log('Selected PQ has id')
                if(this.state.selectedPQ['id'] == pq.id){
                    // console.log('Set Active')
                    return(<>
                            <li key={pq.id} className='nav-item navbar-text bg-primary text-light p-2 active' onClick={e => this.onSelectPQ(pq)} >
                                {pq.name}
                            </li>
                    </>)
                }  else{
                    return(<>
                            <li key={pq.id} className='nav-item navbar-text bg-dark text-light p-2' onClick={e => this.onSelectPQ(pq)} >
                                {pq.name}
                            </li>
                    </>)
                }
            } 
            else{
                return(<>
                        <li key={pq.id} className='nav-item navbar-text bg-dark text-light p-2' onClick={e => this.onSelectPQ(pq)} >
                            {pq.name}
                        </li>
                </>)
            }
        })

        const groups = this.state.currentLoggedInUserGroups
            if( groups.includes('Admins')){
                this.setState({listProductQueries: <> 
                    <div className="d-flex flex-row flex-shrink-0 flex-grow-0 p-0 g-0 text-white bg-dark sidebarAdmin">
                        <ul className='nav flex-column mb-auto p-0 sidebarnav'>
                            {displayArr}
                        </ul>
                    </div>
                </> })
            } else {
                this.setState({listProductQueries: <> 
                    <div className="d-flex flex-row flex-shrink-0 flex-grow-0 p-0 g-0 text-white bg-dark sidebar">
                        <ul className='nav flex-column mb-auto p-0 sidebarnav'>
                            {displayArr}
                        </ul>
                    </div>
                </> })
            }



        
    }

    onSelectPQ = (pq) => {
        // console.log({pq})
        if(pq.hasOwnProperty('products')){
            if (this.isString(pq['products'])){
                pq['products'] = JSON.parse(pq['products'])
            }
        }
        this.setState({selectedPQ: pq})
        this.props.updateSelectedProductQueryData(pq)
    }

    displayMostRecentPQHRun = () => {
        if(this.state.mostRecentPQHRun.hasOwnProperty('status')){
            console.log(this.state.mostRecentPQHRun)
            // let downloadbtn = <></>
            let rowcountRow = <></>
            // let mostRecent = this.state.mostRecentPQHRun
            const metadata = JSON.parse(this.state.mostRecentPQHRun['metadata'])
            if(this.state.mostRecentPQHRun['status'] == 'Completed'){
                // downloadbtn = <><Button color="success btn-sm"  onClick = { e => this.getSignedURL( this.state.mostRecentPQHRun ) }>Download</Button></>
                // this.setState({MostRecentproductQueryHistoryDownloadButton: <><Button color="success btn-sm"  onClick = { e => this.getSignedURL( this.state.mostRecentPQHRun ) }>Download</Button></>})

                if(metadata.hasOwnProperty('rowcount')){
                    rowcountRow = <>
                        <tr>
                            <th>
                                Data Rows
                            </th>
                            <td>
                                {metadata['rowcount']}
                            </td>
                        </tr>
                    </>
                }
            }


            this.setState({ mostRecentPQHDisplay: <>
                <Col className='tablemargins PQDataMostRecent'>
                    <Row>
                        <Col className='col-6'>
                            <h4>Most Recent</h4>
                        </Col>
                        <Col className='col-6'>
                            {this.state.MostRecentproductQueryHistoryDownloadButton}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12'>
                            <Table bordered className='table-sm'>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            Status
                                        </th>
                                        <td>
                                            {this.state.mostRecentPQHRun['status']}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Data Version
                                        </th>
                                        <td>
                                            {this.state.mostRecentPQHRun['dataVersion']}
                                        </td>
                                    </tr>
                                    {rowcountRow}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </> })
        } else {
            this.setState({ mostRecentPQHDisplay: <></>})
        }
    }

    displayMostRecentButton = () => {
        if(this.state.mostRecentPQHRun.hasOwnProperty('status')){
            const metadata = JSON.parse(this.state.mostRecentPQHRun['metadata'])
            if(this.state.mostRecentPQHRun['status'] == 'Completed'){
                this.setState({MostRecentproductQueryHistoryDownloadButton: <><Button color="success btn-sm"  onClick = { e => this.downloadMostRecent(  ) }>Download</Button></>})
            } else {
                this.setState({MostRecentproductQueryHistoryDownloadButton: <></>})
            }
        } else {
            this.setState({MostRecentproductQueryHistoryDownloadButton: <></>})
        }
    }

    copyQueryToUsers = async() => {
        console.log(this.state.selectedPQ)
        let metadata = JSON.parse(this.state.selectedPQ['metadata'])
        let inputs = this.state.selectedPQ
        let ogid = inputs['id']
        let targetUsers  = metadata['targetUsers']
        let targetOrg = metadata['targetOrganization']
        delete inputs['createdAt']
        delete inputs['updatedAt']
        delete inputs['organization']
        delete inputs['id']

        console.log(inputs['products'])
        inputs['products'] = JSON.stringify(inputs['products'])
        console.log({metadata})
        inputs['owners'] = metadata['targetUsers']
        inputs['org'] = metadata['targetOrganization']
        delete metadata['deployedToTarget']
        delete metadata['targetOrganization']
        delete metadata['targetUsers']

        inputs['metadata'] = JSON.stringify(metadata)



        const newProductQuery = await API.graphql(graphqlOperation(createProductQuery, {input: inputs}));
        console.log(newProductQuery['data']['createProductQuery'])

        metadata = JSON.parse(this.state.selectedPQ['metadata'])
        metadata['targetID'] = newProductQuery['data']['createProductQuery']['id']
        metadata['deployedToTarget'] = true
        metadata['targetOrganization'] = targetOrg
        metadata['targetUsers'] = targetUsers


        inputs = {}
        inputs['id'] = ogid
        inputs['metadata'] = JSON.stringify(metadata)

        const updatedProductQuery = await API.graphql(graphqlOperation(updateProductQuery, {input: inputs}));
        console.log({updatedProductQuery})
        // this.displaySelectedPQ()
        // Window.location.reload()
    }

    displaySelectedPQ = () => {
        let pq = this.state.selectedPQ
        let columnGroupsDisplay = ''
        let products = pq['products']
        let scoreTable = []
        let ConsumerRegionBadges: []
        let selgroups = []
        let scoreRankFilter: ''
        let selectionGroupsDisplay = ''
        let sqlWrapperDisplay = <></>
        console.log({pq})
        let metadata = JSON.parse(pq['metadata'])
        console.log({metadata})
        let deployQuery = <></>
        if(this.state.currentLoggedInUserGroups.includes('Admins')){
            if(metadata.hasOwnProperty('deployedToTarget')){
                if(metadata['deployedToTarget'] == false){
                    deployQuery = <>
                        <Col className='p-3'>
                            <Button type='submit' className='copyQueryToUsersBtn' color='danger' onClick={ e => this.copyQueryToUsers() }>Copy Query To Users</Button>
                        </Col>
                    </>
                }
            }
        }
        

        for (var key in products) {
            if (products.hasOwnProperty(key)) {
                if(key == 'SingleScore'){
                    products[key].map((score, index) => {
                        scoreTable.push(<>
                        <tr key={score}>
                            <th scope="row">
                                {score}
                            </th>
                        </tr>
                    </>)})
                } 
                else if(key == 'RegionalScore'){
                    for (let pkey in products[key]) {
                        if(pkey != 'Consumers'){
                            if (products[key].hasOwnProperty(pkey)) {
                                products[key][pkey].map((score, index) => {
                                    scoreTable.push(<>
                                    <tr key={score['regionCol']}>
                                        <th scope="row">
                                            {pkey}
                                        </th>
                                        <td>
                                            {score['regionCol']}
                                        </td>
                                    </tr>
                                    </>)
                                })
                            }
                        } else {
                            if (products[key].hasOwnProperty(pkey)) {
                                ConsumerRegionBadges = products[key][pkey].map((region, index) => <>
                                    <Badge key={index} color='info'>
                                        {region['regionName']}
                                    </Badge>                
                                </>)
                            }
                        }
                    }
                }
                else if(key == 'ColumnGroup'){
                    let columnGroupsList = products[key].map((cGroup, index) => <>
                        <tr key={index}>
                            <th>
                                {cGroup}
                            </th>
                        </tr>
                    </> )
                    columnGroupsDisplay = (<>
                        <Col className='tablemargins PQDataDetails'>
                            <h4>Column Groups</h4>
                            <Table bordered className='table-sm'>
                                <tbody>
                                    {columnGroupsList}
                                </tbody>
                            </Table>
                        </Col>
                    </>)
                } 
                else if(key == 'SQLWrapper'){
                    // console.log('SQLWrapper: ', products[key].length)
                    if(products[key].length > 0){
                        let sqlWrappersList = products[key].map((sqgroup, index) => <>
                            <tr key={index}>
                                <th>
                                    {sqgroup}
                                </th>
                            </tr>
                        </> )
                        sqlWrapperDisplay = (<>
                            <Col className='tablemargins PQDataDetails'>
                                <h4>À la carte</h4>
                                <Table bordered className='table-sm'>
                                    <tbody>
                                        {sqlWrappersList}
                                    </tbody>
                                </Table>
                            </Col>
                        </>)
                    } 
                
                } 
                else if(key == 'ColumnSelect'){
                    // console.log('ColumnSelect: ', products[key])
                    for (let pkey in products[key]) {
                        // console.log({pkey})
                        // console.log(products[key][pkey])
                        const selections = products[key][pkey]
                        let selectionsBadges = selections.map((item, index) => <>
                            <Badge key={index} color='info'>
                                {item}
                            </Badge>       
                        </>)
                        if(selectionsBadges.length > 0){
                            selgroups.push(<>
                                <tr>
                                    <th scope="row">
                                        {pkey}
                                    </th>
                                    <td>
                                        {selectionsBadges}
                                    </td>
                                </tr>    
                            </>)
                        }
                    }
                    if(selgroups.length > 0){
                        selectionGroupsDisplay = (<>
                            <Col className='tablemargins PQDataDetails'>
                                <h4>Column Selections</h4>
                                <Table bordered className='table-sm'>
                                    <tbody>
                                        {selgroups}
                                    </tbody>
                                </Table>
                            </Col>
                        </>)
                    } else {
                        selectionGroupsDisplay = (<></>)
                    }
                }
                else if(key == 'FilterScore'){
                    // console.log(products[key])  
                    scoreRankFilter = (<>
                        <Col className='tablemargins PQDataDetails'>
                            <h4>Ranked By Score</h4>
                            <Table className='table-sm'>
                                <tbody>
                                    <tr>
                                        <th>Ranked By</th>
                                        <td> {products['FilterScore']} </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </>)
                }
            }
        }
        let scoresTableComponenet = <></>
        if(scoreTable.length > 0){
            scoresTableComponenet = (<>
                <Col className='tablemargins PQDataDetails'>
                    <h4>Scores</h4>
                    <Table bordered className='table-sm'>
                        <tbody>
                            {scoreTable}
                        </tbody>
                    </Table>
                </Col>
            </>)
        } 

        let adminStayCurrent = <></>
        if(this.state.currentLoggedInUserGroups.includes('Admins')){
            adminStayCurrent = <>
                <tr>
                    <th scope="row">Stay Current</th>
                    <td>{this.boolToString(pq.current)}</td>
                </tr>
            </>
        }

        const pqDisplay = <>
                {/* <Row>
                    <Col className='p-4'>
                        <tr>
                            <td>
                                <h2>Name: </h2>    
                            </td>
                            <td className='p-2'>
                                <h3>{pq.name}</h3>    
                            </td>
                        </tr>
                        
                    </Col>
                </Row> */}
                {/* <Row className='queryRowMargins'> */}
                {this.state.AlertNotification}

                <Row className='d-flex flex-row p-3'>
                    {this.state.mostRecentPQHDisplay}
                    <Col className='tablemargins PQDataDetails'>
                        <h4>General</h4>
                        <Table bordered className='table-sm'>
                            <tbody>
                                {/* <tr>
                                    <th scope="row">Target Users</th>
                                    <td>{pq.targetUsers}</td>
                                </tr> */}
                                {adminStayCurrent}
                                <tr>
                                    <th scope="row">Region Grouping</th>
                                    <td>{this.boolToString(pq.products.queryOptions.GroupByRegion)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    {columnGroupsDisplay}
                    {scoresTableComponenet}
                    {scoreRankFilter}
                    {sqlWrapperDisplay}
                    {selectionGroupsDisplay}
                    {deployQuery}
                {/* </Row>
                <br/>
                <Row> */}
                    <Col className='PQRegionBadges'>
                        <h4>Selected Regions</h4>
                        {ConsumerRegionBadges}
                    </Col>
                </Row>
                <br />
        </>

        const groups = this.state.currentLoggedInUserGroups
        if( groups.includes('Admins')){
            this.setState({ pqDisplay: <>
                <div className='maincontentAdmins flex-row'>
                    {pqDisplay}
                </div>
            </>})
        } else {
            this.setState({ pqDisplay: <>
                <div className='maincontent flex-row'>
                    {pqDisplay}
                </div>
            </>})
        }
    }

    isString = (x) => {
        return Object.prototype.toString.call(x) === "[object String]"
    }

    RunSelectedPQ = () => {
        this.toggleModals('ConfirmRun') 
    }

    toggleModals = ( modalname ) => {
        let modals = this.state.modals
        if(modals.includes(modalname) ){
            modals.splice(modals.indexOf(modalname))
            if(modalname == "ConfirmRun"){
                this.setState({confirmRun: false})
                this.setState({RunButton: <></>})
            }
        } else {
            modals.push(modalname)
        }
        this.setState({modals: modals})
    }

    displayRunButton = () => {
        if(this.state.confirmRun){
            this.setState({RunButton: <>
                <Button color="danger" onDoubleClick={e => this.submitRunToGQL()}>Run</Button>
            </>})
        } else{
            this.setState({RunButton: <>
                <Button color="danger" className='disabled' onDoubleClick={e => this.submitRunToGQL()}>Run</Button>
            </>})
        }
    }

    setConfirmRun = (confirmStatus) => {
        this.setState({confirmRun: !confirmStatus})
    }

    getActiveDataVersion = async() => {
        let dvData = await API.graphql({ query: dataVersionByStatus, variables: { status: 'Active', limit: 100,  }});
        // console.log({dvData})
        let dvItems = dvData.data.dataVersionByStatus.items;
        let pdata = dvData.data.dataVersionByStatus
        while(pdata.nextToken != null ){
            let nextToken = pdata.nextToken;
            let dvData = await API.graphql({ query: dataVersionByStatus, variables: { status: 'Active', limit: 100, nextToken }});
            pdata = {}
            pdata = dvData.data.dataVersionByStatus
            dvItems = dvItems.concat(pdata.items)
        }
        this.setState({activeDataVersion: dvItems[0]})
    }

    submitRunToGQL = async() => {
        console.log(this.state.selectedPQ)
        this.toggleModals('ConfirmRun')
        let inputs = {}
        inputs['productQueryid'] = this.state.selectedPQ['id']
        inputs['dataVersion'] = this.state.activeDataVersion['version']
        inputs['status'] = 'Pending'
        inputs['moved'] = false
        inputs['notificationCompleted'] = false
        inputs['owners'] = this.state.selectedPQ['owners']
        inputs['metadata'] = {}
        inputs['metadata']['deployedToTarget'] = false
        inputs['metadata'] = JSON.stringify(inputs['metadata'] )

        // console.log({inputs})
        const token = await Auth.currentAuthenticatedUser()
        const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
        if( groups.includes('Admins')){
            const newProductQueryRun = await API.graphql(graphqlOperation(createProductQueryHistory, {input: inputs}));
        }
        // console.log({newProductQueryRun})
    }

    getProductQueryHistoryRuns = async() => {
        let pqhItems = []
        if(this.state.selectedPQ.hasOwnProperty('id')){
            const filterstr = {
                or: [{status: { ne: 'Deleted'} },
                    {status: { ne: 'Archived'} }
                ]
            }
            let pqhData = await API.graphql({ query: productQueryHistorysByproductQuery, variables: { productQueryid: this.state.selectedPQ['id'], filter: filterstr, limit: 15, sortDirection: 'DESC'   }});
            pqhItems = pqhData.data.productQueryHistorysByproductQuery.items;
            // let pdata = pqhData.data.productQueryHistorysByproductQuery
            // while(pdata.nextToken != null ){
            //     let nextToken = pdata.nextToken;
            //     let pqhData = await API.graphql({ query: productQueryHistorysByproductQuery, variables: { productQueryid: this.state.selectedPQ['id'], filter: filterstr, limit: 100, nextToken, sortDirection: 'DESC'  }});
            //     pdata = {}
            //     pdata = pqhData.data.productQueryHistorysByproductQuery
            //     pqhItems = pqhItems.concat(pdata.items)
            // }
            // console.log({pqhItems})
        }
        // pqhItems.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)

        this.setState({selectedPQRunHistory: pqhItems})
        // console.log(pqhItems[0])
        if(pqhItems.length > 0){
            this.setState({mostRecentPQHRun: pqhItems[0]})
        } else {
            this.setState({mostRecentPQHRun: {}})
        }
    }


    downloadBlob(results, filename) {
        // console.log('getResults: ', results)
        console.log('getResultsMetadataHTTPStatusCode: ', results['$metadata']['httpStatusCode'])
        
        let dlstatus = 'downloading'
        if( results['$metadata']['httpStatusCode'] == 200){
            dlstatus = 'Success'
            const blob = results.Body
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename || 'download';
            const clickHandler = () => {
                setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
                }, 150);
            };
            a.addEventListener('click', clickHandler, false);
            a.click();
            // return a;

        } else {
            dlstatus = 'Failed-' + results['$metadata']['httpStatusCode'].toString()
        }
        let output = {}
        output['status'] = dlstatus
        output['statusCode'] = results['$metadata']['httpStatusCode']
        return(output)
    }

    getSignedURL = async( run ) => {
        // console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']
        let filename = this.generateOutputFilename(run)
        let inputs = {}
        inputs['productQueryid'] = run['productQueryid']
        inputs['productQueryHistoryid'] =  run['id']
        inputs['userid'] = this.state.currentLoggedInUser
        const newDownload = await API.graphql(graphqlOperation(createDownload, {input: inputs}));
        try{
            await Storage.get(resultsData['s3DataFile'], { download: true }).then(res => this.downloadBlob(res, filename))
        }
        catch (error) {
            console.log({error})
            console.log("error throng getSignedURL")
        }
        this.getProductQueryHistoryDownloads(this.state.selectedPQHRunData['id'] )
    }


    generateOutputFilename = ( run ) => {
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']

        // console.log(run['dataVersionidx']['createdAt'])
        const dvDate = run['dataVersionidx']['createdAt']
        const date = dvDate.split('-')[0] + dvDate.split('-')[1] 
        console.log(date)

        let filename = 'Emigrait-' + run['productQueryidx']['name'] + '-' + date

        if(csvsByRegion){
            filename = filename + '.zip'
        } else{
            filename = filename + '.csv'
        }
        return(filename)

    }

    getSignedModalURLWithProgress = async( run ) => {
        // console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']
        let filename = this.generateOutputFilename(run)

        let inputs = {}
        inputs['productQueryid'] = run['productQueryid']
        inputs['productQueryHistoryid'] =  run['id']
        inputs['userid'] = this.state.currentLoggedInUser

        // let dlSize = 0
        let dlStatusCode = 0
        let dlStatus = 'waiting'

        try{
            const getresults = await Storage.get(resultsData['s3DataFile'], { download: true,
                progressCallback(progress) {
                    console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
                } }).then(res => this.downloadBlob(res, filename)).then( data => {
                if(data['status'] == 'Success'){
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    // dlSize = data['ContentLength']

                    this.setState({productQueryHistoryDownloadButton: <>
                        <Button className="btn btn-outline-success" onClick = { e => this.downloadFromModal() } >Download</Button>
                    </>})
                } else {
                    const errormsg = 'Error ' + data['statusCode']
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    // dlSize = 0

                    this.setState({productQueryHistoryDownloadButton: <>
                        <Button className="btn btn-outline-danger disabled"  > {errormsg} </Button>
                    </>})
                    
                    this.setState({AlertNotification: <>
                        <div className="alert alert-danger" role="alert">
                        Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                            
                            <button onClick={ e => this.resetAlert() } type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </>})
                    }
                })
        } catch (error) {
            console.log({error})
            dlStatusCode = error['response']['status']
            dlStatus = error['response']['statusText']
            // dlSize = 0

            this.setState({productQueryHistoryDownloadButton: <>
                <Button className="btn btn-outline-danger disabled"  > Error </Button>
            </>})
            
            this.setState({AlertNotification: <>
                <div className="alert alert-danger" role="alert">
                Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                    
                    <button onClick={ e => this.resetAlert() } type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>})
        } finally {
            inputs['status'] = dlStatus
            inputs['statusCode'] =dlStatusCode.toString()
            // inputs['size'] = dlSize.toString()

            const newDownload = await API.graphql(graphqlOperation(createDownload, {input: inputs}));
            // console.log(newDownload)
        }
    }

    

    getSignedRecentURLWithProgress = async( run ) => {
        // console.log({run})
        let resultsData = JSON.parse(run['resultsData'])
        let products = JSON.parse(run['productQueryidx']['products'])
        const csvsByRegion = products['queryOptions']['GroupByRegion']
        let filename = this.generateOutputFilename(run)

        let inputs = {}
        inputs['productQueryid'] = run['productQueryid']
        inputs['productQueryHistoryid'] =  run['id']
        inputs['userid'] = this.state.currentLoggedInUser

        // let dlSize = 0
        let dlStatusCode = 0
        let dlStatus = 'waiting'

        try{
            const getresults = await Storage.get(resultsData['s3DataFile'], { download: true,
                progressCallback(progress) {
                    console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
                } }).then(res => this.downloadBlob(res, filename)).then( data => {
                if(data['status'] == 'Success'){
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    // dlSize = data['ContentLength']
                    this.setState({MostRecentproductQueryHistoryDownloadButton: <>
                        <Button className="btn-sm btn-success" onClick = { e => this.downloadMostRecent() } >Download</Button>
                    </>})
                } else {
                    const errormsg = 'Error ' + data['statusCode']
                    dlStatusCode = data['statusCode']
                    dlStatus = data['status']
                    // dlSize = 0
                    
                    this.setState({MostRecentproductQueryHistoryDownloadButton: <>
                        <Button className="btn-sm btn-danger disabled"  > {errormsg} </Button>
                    </>})
                    
                    this.setState({AlertNotification: <>
                        <div className="alert alert-danger" role="alert">
                        Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                            <button onClick={ e => this.resetAlert() } type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </>})
                    }
                })
        } catch (error) {
            console.log({error})
            dlStatusCode = error['response']['status']
            dlStatus = error['response']['statusText']
            // dlSize = 0
            this.setState({MostRecentproductQueryHistoryDownloadButton: <>
                <Button className="btn-sm btn-danger disabled"  > Error </Button>
            </>})
            this.setState({AlertNotification: <>
                <div className="alert alert-danger" role="alert">
                Error: Due to a known issue, we are having trouble accessing your list. We apologize for the inconvenience. Please contact support@emigrait.com to resolve. Thank you!
                    <button onClick={ e => this.resetAlert() } type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </>})
        } finally {
            inputs['status'] = dlStatus
            inputs['statusCode'] = dlStatusCode.toString()
            // inputs['size'] = dlSize.toString()

            const newDownload = await API.graphql(graphqlOperation(createDownload, {input: inputs}));
            // console.log(newDownload)
        }
    }

    resetAlert = () => {
        this.setState({AlertNotification: <></>})
    }

    displaySelectedPQRunHistory = () => {
        let pqhRuns = this.state.selectedPQRunHistory.map((run, index) => {
            if(this.state.selectedPQHRunData.hasOwnProperty('id')){
                if(run.id != this.state.selectedPQHRunData['id']){
                    return(<>
                        <li key={run.id} className='list-group-item list-group-item-action queryExecutionsListItem' onClick={e => this.onSelectPQRunHistory(run)} >
                            <tr>
                                <td>
                                    <tr>
                                        <th scope="row">Status: </th>
                                        <td className='p-3'>{run.status}</td>
                                    </tr>
                                </td>
                                <td>
                                    <tr>
                                        <th scope="row">DataVersion </th>
                                        <td className='p-3'>{run.dataVersion}</td>
                                    </tr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {run.createdAt}
                                </td>
                            </tr>
                        </li>
                    </>)
                } else {
                    return(<>
                        <li key={run.id} className='list-group-item list-group-item-action queryExecutionsListItem active' onClick={e => this.onSelectPQRunHistory(run)} >
                            <tr>
                                <td>
                                    <tr>
                                        <th scope="row">Status: </th>
                                        <td className='p-3'>{run.status}</td>
                                    </tr>
                                </td>
                                <td>
                                    <tr>
                                        <th scope="row">DataVersion </th>
                                        <td className='p-3'>{run.dataVersion}</td>
                                    </tr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {run.createdAt}
                                </td>
                            </tr>

                        </li>
                    </>)
                }
            } else {
                return(<>
                    <li key={run.id} className='list-group-item list-group-item-action queryExecutionsListItem' onClick={e => this.onSelectPQRunHistory(run)} >

                        <tr>
                            <td>
                                <tr>
                                    <th scope="row">Status: </th>
                                    <td className='p-3'>{run.status}</td>
                                </tr>
                            </td>
                            <td>
                                <tr>
                                    <th scope="row">DataVersion </th>
                                    <td className='p-3'>{run.dataVersion}</td>
                                </tr>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {run.createdAt}
                            </td>
                        </tr>
                    </li>
                </>)
            }
            
        })
        if(pqhRuns.length > 0){
            const groups = this.state.currentLoggedInUserGroups
            if( groups.includes('Admins')){
                this.setState({displayPQHruns: <>
                    <div className='queryExecutionsListAdmin verticalLine bg-white p-0'>
                        <div className='g-0 p-3'>
                            <h2>Query Executions</h2>
                        </div>
                        <ListGroup className="list-group list-group-flush">
                            {pqhRuns}
                        </ListGroup>
                    </div>
                </>})

            } else {
                this.setState({displayPQHruns: <>
                    <div className='queryExecutionsList verticalLine bg-white p-0'>
                        <div className='g-0 p-3'>
                            <h2>Query Executions</h2>
                        </div>
                        <ListGroup className="list-group list-group-flush">
                            {pqhRuns}
                        </ListGroup>
                    </div>
                </>})
            }



        }else {
            this.setState({displayPQHruns: <></>})
        }
    }

    onSelectPQRunHistory = (run) => {
        console.log({run})
        this.setState({selectedPQHRunData: run})
        if( this.state.currentLoggedInUserGroups.includes('Admins')){
            this.getProductQueryHistoryDownloads(run['id'])
        }
        this.displayProductQueryHistoryDownloadButton(run['status'])
        this.toggleModals('ExecutionDetails')

        let event = {}
        event['ProductQueryHistory'] = run

    }

    downloadFromModal = () => {
        this.getSignedModalURLWithProgress( this.state.selectedPQHRunData )
        this.setState({productQueryHistoryDownloadButton: <>
            <button className="btn btn-primary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">Downloading...</span>
            </button>
        </>})
    }

    downloadMostRecent = () => {

        this.getSignedRecentURLWithProgress( this.state.mostRecentPQHRun )
        this.setState({MostRecentproductQueryHistoryDownloadButton: <>
            <button className="btn-sm btn-primary" type="button" disabled>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">Downloading...</span>
            </button>
        </>})
    }

    moveSelectedProductQueryHistoryToTarget = async(runData) => {
        console.log(this.state.selectedPQHRunData)
        let productQuerymetadata = JSON.parse(runData['productQueryidx']['metadata'])
        let metadata = JSON.parse(runData['metadata'])
        const runID = runData['id']
        console.log({productQuerymetadata})
        if(productQuerymetadata['deployedToTarget']){
            if(productQuerymetadata.hasOwnProperty('targetUsers')){
                delete runData['createdAt']
                delete runData['updatedAt']
                delete runData['productQueryidx']
                delete runData['dataVersionidx']
                delete runData['id']
                runData['sourceid'] = runID
                runData['productQueryid'] = productQuerymetadata['targetID']
                runData['owners'] = productQuerymetadata['targetUsers']
                runData['notificationCompleted'] = false
                runData['moved'] = true
                console.log({runData})
                const newProductQueryRun = await API.graphql(graphqlOperation(createProductQueryHistory, {input: runData}));
                console.log(newProductQueryRun)
                metadata['deployedToTarget'] = true

                let inputs = {}
                inputs['id'] = runID
                inputs['metadata'] = JSON.stringify(metadata)
                inputs['moved'] = true
                console.log(inputs)
                const updateProductQueryHistoryData = await API.graphql(graphqlOperation(updateProductQueryHistory, {input: inputs}));
                console.log(updateProductQueryHistoryData)
                this.toggleModals('ExecutionDetails')
            }
            
        }
        Window.location.reload()
    }

    displayProductQueryHistoryDetails = () => {
        let metadata = JSON.parse(this.state.selectedPQHRunData['metadata'])
        let rowcount = (<></>)
        if(metadata.hasOwnProperty('rowcount')){
            // console.log('has rowcount')
            rowcount = (<>
                <tr>
                    <td scope='row'>
                        Rowcount
                    </td>
                    <td>
                        { metadata['rowcount']}
                    </td>
                </tr>
            </>)
        }
        let adminflags = <></>
        if(this.state.currentLoggedInUserGroups.includes('Admins')){
            let movebtn = <></>
            if(!this.state.selectedPQHRunData['moved']){
                movebtn = <><Button color="info" onClick={e => this.moveSelectedProductQueryHistoryToTarget(this.state.selectedPQHRunData)} >Move</Button></>
            }
            adminflags = <>
                <tr>
                    <td scope='row'>
                        Notification
                    </td>
                    <td>
                        { this.boolToString(this.state.selectedPQHRunData['notificationCompleted'])}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td scope='row'>
                        Moved
                    </td>
                    <td>
                        { this.boolToString(this.state.selectedPQHRunData['moved'])}
                    </td>
                    <td>
                        {movebtn}
                    </td>
                </tr>
            </>
        }
        this.setState({SelecteProductQueryHistoryDetails: <>
            <Table>
                <thead>
                    <tr>
                        <td>
                            Key
                        </td>
                        <td>
                            Value
                        </td>
                        <td>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td scope='row'>
                            Status
                        </td>
                        <td>
                            {this.state.selectedPQHRunData['status']}
                        </td>
                        <td>
                        </td>
                    </tr>
                    {rowcount}
                    {adminflags}
                </tbody>
            </Table>
        </>})
    }

    getProductQueryHistoryDownloads = async(pqhid) => {
        console.log({pqhid})

        let dlData = await API.graphql({ query: downloadsByproductQueryHistory, variables: { productQueryHistoryid: pqhid, limit: 10, sortDirection: 'DESC'  }});
        // console.log({dvData})
        let dlItems = dlData.data.downloadsByproductQueryHistory.items;
        // console.log({dlItems})
        console.log({dlItems})

        if(dlItems.length > 0){
            const downloadslist = dlItems.map((download, index) => <>
                <tr>
                    <td>
                        {download.userid}
                    </td>
                    <td>
                        {download.createdAt}
                    </td>
                </tr>
            </>)

            this.setState({downloadslist: <>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                User
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {downloadslist}
                    </tbody>
                </Table>
            </>})
        } else{
            this.setState({downloadslist: <>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                User
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h3>Not Downloaded</h3>
                        </tr>
                    </tbody>
                </Table>
            </>})
        }

    }

    displayProductQueryHistoryDownloadButton = (status) => {
        if(status == 'Completed'){
            this.setState({productQueryHistoryDownloadButton: <>
                <Button className="btn btn-outline-success" onClick = { e => this.downloadFromModal() } >Download</Button>
            </>})
        } else {
            this.setState({productQueryHistoryDownloadButton: <>
                <Button className="btn btn-outline-success disabled" >Download</Button>
            </>})
        }

    }

    render  () {
        return( <>
            <Modal backdrop="static" isOpen={this.state.modals.includes('EditProductQuery')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('EditProductQuery')}>×</button>}>Edit Product Query</ModalHeader>
                <ModalBody>
                    <ProductQueryEditor />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={ e => this.toggleModals('EditProductQuery') }>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('ExecutionDetails')} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('ExecutionDetails')}>×</button>}>Execution Details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {this.state.SelecteProductQueryHistoryDetails}
                        </Col>
                        <Col>
                            {this.state.downloadslist}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {/* <Button className="btn btn-outline-success" onClick = { e => this.downloadFromModal() } >Download</Button> */}
                    {this.state.productQueryHistoryDownloadButton}
                    <Button color="secondary" onClick={ e => this.toggleModals('ExecutionDetails') }>Close</Button>
                    {this.state.downloadsProgress[this.state.selectedPQHRunData['id']]}
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.modals.includes('ConfirmRun')} className="modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.toggleModals('ConfirmRun')}>×</button>}>Confirm Run</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col></Col>
                        <Col className='btn btn-success'   onClick={e => this.setConfirmRun(this.state.confirmRun)} >
                            <Input type="checkbox" checked={this.state.confirmRun} />
                            <Label check>
                                Confirm?
                            </Label>
                        </Col>
                        <Col></Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {this.state.RunButton}
                    <Button color="secondary" onClick={ e => this.toggleModals('ConfirmRun') }>Cancel</Button>
                </ModalFooter>
            </Modal>
            {this.state.AdminNav}
            <div className='box g-0 fixed-right'>

                {this.state.listProductQueries}
                {this.state.pqDisplay}
                <div className='flex-row flex-shrink-0 flex-grow-0 nav-link-icon d-none d-xs-none d-sm-none d-md-none d-lg-block'>
                    {this.state.displayPQHruns}
                </div>
            </div>
        </>
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

const mapStateToProps = state => {
    // console.log({state})
    return {
        selectedProductQuery: state.selectedProductQuery,  
        selectedProductQueryHistory: state.selectedProductQueryHistory,  
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedProductQueryData: (event) => {
            // console.log('UPDATESSELECTEDPRODUCTQUERYDATA to props event: ', event)
            dispatch({type: 'UPDATESSELECTEDPRODUCTQUERYDATA', 
                selectedProductQuery: event
            })
        }
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(ListProductQuery) 